import Collapser from "components/atoms/Collapser/Collapser";
import AttributeField from "components/molecules/AttributeField/AttributeField";

export default function AttributeCollapser({ attributeSection }) {
  const attributeSectionName = Object.keys(attributeSection)[0];

  return (
    <Collapser headerName={attributeSectionName} headerStyling="text-lg">
      {attributeSection[attributeSectionName].map((attribute, index) => (
        <AttributeField key={index} attribute={attribute} />
      ))}
    </Collapser>
  );
}
