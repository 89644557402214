import MDEditor from "@uiw/react-md-editor";
import database from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import React, { useState } from "react";

interface DocumentEditorProps {
  content: string;
  validVids: Set<string>;
  setValidVids: Function;
  setContent: (content: string) => void;
  viewOnly?: boolean;
  underComponent?: React.ReactNode | null;
}

const eqSet = (xs: Set<any>, ys: Set<any>) => xs.size === ys.size && [...xs].every((x) => ys.has(x));

export default function DocumentEditor({
  content,
  setContent,
  validVids,
  setValidVids,
  viewOnly = false,
  underComponent = null,
}: DocumentEditorProps) {
  const [visualizations, setVisualizations] = useState<{ [key: string]: string }>({});
  const [render, setRender] = useState(true);

  // FROM https://stackoverflow.com/a/3354277
  function getWordsBetweenCurlies(x: string) {
    var results = [],
      re = /{([^}]+)}/g,
      text;

    while ((text = re.exec(x))) {
      results.push(text[1]);
    }
    return results;
  }

  let modifiedValue = content;
  let currentValidVids = new Set();

  getWordsBetweenCurlies(content).forEach((orig) => {
    const vid = orig.split("vid=").slice(-1)[0];
    if (vid in visualizations) {
      if (visualizations[vid].includes("iframe")) currentValidVids.add(vid);
      modifiedValue = modifiedValue.replace(
        `{${orig}}`,
        visualizations[vid].includes("iframe") ? visualizations[vid] + "\n\n skipped content \n\n" : visualizations[vid]
      );
    } else {
      database.getVisualization(vid).then((v) => {
        if (v?.content?.status === "Visualization rendered" && v.content.data_url) {
          currentValidVids.add(vid);

          setVisualizations((prev) => ({
            ...prev,
            [vid]: `<iframe src="${v.content.data_url}" style={display: inline-block;} width="100%" height="600px" scrolling="no" />
              `,
          }));
        } else {
          setVisualizations((prev) => ({ ...prev, [vid]: "> *Invalid/inaccessible visualization*" }));
        }
      });
    }
  });

  if (!eqSet(validVids, currentValidVids)) setValidVids(currentValidVids);

  return viewOnly ? (
    <div className="w-full h-full flex flex-col divide-y-2" data-color-mode="light">
      <MDEditor.Markdown className="grow w-full p-4 h-full overflow-auto" source={render ? modifiedValue : content} />
      <div className="w-full">{underComponent}</div>
    </div>
  ) : (
    <div className="w-full max-h-full h-full flex" data-color-mode="light">
      <div className="w-1/2 max-h-full h-full mr-2 flex flex-col">
        <MDEditor
          className=" grow "
          height="100%"
          value={content}
          onChange={(x) => {
            setContent(x || "");
            setRender(false);
          }}
          hideToolbar={true}
          preview="edit"
        />
        <Button fullWidth={true} className="mt-3 shrink-0" onClick={() => setRender(true)}>
          Render Visualizations
        </Button>
      </div>

      <div className="w-1/2 ml-2 h-full flex flex-col">
        <MDEditor.Markdown
          className="grow overflow-auto p-4 mb-1 rounded-lg outline outline-neutral outline-2"
          source={render ? modifiedValue : content}
        />
        {underComponent}
      </div>
    </div>
  );
}
