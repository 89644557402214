import {
  BaseDatabase,
  Dataset,
  DatasetDB,
  Model,
  ModelDB,
  Log,
  LogDB,
  Status,
  Visualization,
  VisualizationDB,
} from "api/BaseDatabase";

import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  documentId,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  Query,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteObject, FirebaseStorage, getBlob, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import localforage from "localforage";
import BaseAPI from "./BaseAPI";
import { DatabaseObject } from "./BaseDatabase";

export class FirestoreDatabase extends BaseDatabase<Query<DocumentData>> {
  db: Firestore;
  storage: FirebaseStorage;
  user: any;

  // TOOLS

  async uploadToStorage(path: string, file: Blob): Promise<Status> {
    const storageRef = ref(this.storage, path);
    try {
      await uploadBytes(storageRef, file);
      return { status: "success" };
    } catch (e) {
      return { status: "error", traceback: (e as Error).message };
    }
  }

  async downloadFromStorage(path: string): Promise<Blob> {
    const storageRef = ref(this.storage, path);
    return await getBlob(storageRef);
  }

  async downloadLinkFromStorage(path: string): Promise<string> {
    return await getDownloadURL(ref(this.storage, path));
  }

  async deleteFromStorage(path: string): Promise<Status> {
    try {
      await deleteObject(ref(this.storage, path));
      return { status: "success" };
    } catch (e) {
      return { status: "error", traceback: (e as Error).message };
    }
  }

  // QUERIES

  queryUserDatasets(): Query<DocumentData> {
    return query(collection(this.db, "datasets"), where("uid", "==", this.user.uid));
  }

  queryUserModels(): Query<DocumentData> {
    return query(collection(this.db, "models"), where("uid", "==", this.user.uid));
  }

  queryUserVisualizations(): Query<DocumentData> {
    return query(collection(this.db, "visualizations"), where("uid", "==", this.user.uid));
  }

  queryDatasetModels(did: string): Query<DocumentData> {
    return query(collection(this.db, "models"), where("uid", "==", this.user.uid), where("did", "==", did));
  }

  queryVisualizationList(vids: string[]): Query<DocumentData> {
    return query(collection(this.db, "visualizations"), where(documentId(), "in", vids));
  }

  queryInferences(did: string, mid: string): Query<DocumentData> {
    return query(
      collection(this.db, `datasets/${did}/inferences`),
      where("uid", "==", this.user.uid),
      where("mid", "==", mid)
    );
  }

  queryVisualization(hash: string): Query<DocumentData> {
    return query(collection(this.db, "visualizations"), where("hash", "==", hash));
  }

  // GETTERS

  async get(q: Query<DocumentData>): Promise<DatabaseObject[]> {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        path: doc.ref.path,
        content: doc.data(),
      } as DatabaseObject;
    });
  }

  getLive(q: Query<DocumentData>, callback: Function): () => void {
    return onSnapshot(q, (querySnapshot) => {
      callback(
        querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            path: doc.ref.path,
            content: doc.data(),
          } as DatabaseObject;
        })
      );
    });
  }

  async getModel(mid: string): Promise<ModelDB> {
    const d = await getDoc(doc(this.db, `models/${mid}`));
    return {
      id: d.id,
      path: d.ref.path,
      content: d.data() as Model,
    };
  }

  async getDataset(did: string): Promise<DatasetDB> {
    const d = await getDoc(doc(this.db, `datasets/${did}`));
    return {
      id: d.id,
      path: d.ref.path,
      content: d.data() as Dataset,
    };
  }

  async getVisualization(vid: string): Promise<VisualizationDB> {
    // const d = await getDoc(doc(this.db, `visualizations/${vid}`));
    const docRef = doc(this.db, "visualizations", vid);
    const d = await getDoc(docRef);

    return {
      id: d.id,
      path: d.ref.path,
      content: d.data() as Visualization,
    };
  }

  getDocumentLive(path: string, callback: Function): () => void {
    //console.log("getDocumentLive", path);
    return onSnapshot(doc(this.db, path), (querySnapshot) => {
      callback({ path: path, content: querySnapshot.data(), id: querySnapshot.id } as DatabaseObject);
    });
  }

  async isDocumentPublic(path: string): Promise<boolean> {
    try {
      await getDoc(doc(this.db, path));
      return true;
    } catch (e) {
      return false;
    }
  }

  // SETTERS/UPLOADS

  async createDataset(dataset: Dataset): Promise<DatasetDB> {
    console.log(dataset);
    const docRef = await addDoc(collection(this.db, "datasets"), dataset);
    console.log("createDataset", docRef.id);
    return { id: docRef.id, path: docRef.path, content: dataset };
  }

  /** Fully deletes dataset object from storage and database */
  async deleteDataset(dataset: DatasetDB): Promise<Status> {
    console.log("Deleting dataset: ", dataset);
    const docStatus = await this.deleteDocument(dataset);
    const storageStatus = await this.deleteFromStorage(dataset.content.data.file_path || "");
    if (docStatus.status !== "success") return docStatus;
    if (storageStatus.status !== "success") return storageStatus;
    return docStatus;
  }

  async deleteModel(model: ModelDB): Promise<Status> {
    if ("file_path" in model.content.data && model.content.data.file_path) {
      const file_path = model.content.data.file_path;
      const status = await this.deleteDocument(model);
      if (status.status !== "success") return status;
      return await this.deleteFromStorage(file_path);
    }
    return await this.deleteDocument(model);
  }

  async createModel(model: Model): Promise<ModelDB> {
    const docRef = await addDoc(collection(this.db, "models"), model);
    return { id: docRef.id, path: docRef.path, content: model };
  }
  async createLog(log: Log): Promise<LogDB> {
    const docRef = await addDoc(collection(this.db, "logs"), log);
    return { id: docRef.id, path: docRef.path, content: log };
  }

  async createVisualization(visualization: Visualization): Promise<VisualizationDB> {
    const docRef = await addDoc(collection(this.db, "visualizations"), visualization);
    return { id: docRef.id, path: docRef.path, content: visualization };
  }

  // unclear what to do with storage regarding visualizations
  async deleteVisualization(visualization: VisualizationDB): Promise<Status> {
    return await this.deleteDocument(visualization);
  }

  async updateObj(path: string, data: any): Promise<Status> {
    console.log("updateObj", path, data);
    try {
      await updateDoc(doc(this.db, path), data);
      return { status: "success" };
    } catch (e) {
      return { status: "error", traceback: (e as Error).message };
    }
  }

  // OPERATORS

  async deleteDocument(d: DatabaseObject): Promise<Status> {
    if (d.path === undefined) {
      return {
        status: "error",
        traceback: "Supplied document for deletion has no path attribute.",
      };
    }
    await deleteDoc(doc(this.db, d.path));
    return { status: "success" };
  }

  async updateDocument(d: DatabaseObject, updates: any): Promise<Status> {
    if (d.path === undefined) {
      return {
        status: "error",
        traceback: "Supplied document for deletion has no path attribute.",
      };
    }
    await updateDoc(doc(this.db, d.path), updates);
    return { status: "success" };
  }

  async isUserAdmin(): Promise<boolean> {
    const d = await getDoc(doc(this.db, "users/" + this.user.uid));
    return d.exists() && d.data().admin;
  }

  // SETUP

  constructor() {
    super();
    const firebaseConfig = {
      apiKey: "AIzaSyCSaFE5-qxy5-HAr-oqUd5del4bv5dqJ0Q",
      authDomain: "oloren-ai.firebaseapp.com",
      databaseURL: "https://oloren-ai-default-rtdb.firebaseio.com",
      projectId: "oloren-ai",
      storageBucket: "oloren-ai.appspot.com",
      messagingSenderId: "602366687071",
      appId: "1:602366687071:web:f35531f7142084b86a28ea",
      measurementId: "G-MVNK4DPQ60",
    };
    const app = initializeApp(firebaseConfig);
    this.db = getFirestore(app);
    this.storage = getStorage(app);
  }

  authenticate(user: any) {
    this.user = user;
  }
}

const database = new FirestoreDatabase();
const apiBaseUrl = "https://api.oloren.ai";

export class FirestoreAPI extends BaseAPI {
  async getUniqueToken(): Promise<string> {
    const token: string = database.user
      .getIdToken(true)
      .then(function (idToken: string) {
        return idToken;
      })
      .catch(function (error: any) {
        console.log(error);
      });
    return token;
  }

  async queueAutoML(did: string): Promise<Status> {
    const id_token = await this.getUniqueToken();
    return (await axios.post(`${apiBaseUrl}/firestore/queue_automl/?uid=${id_token}&did=${did}`))[
      "data"
    ] as any as Status;
  }

  async queueCustom(did: string, parameters: string): Promise<Status> {
    const id_token = await this.getUniqueToken();
    return (await axios.post(`${apiBaseUrl}/firestore/queue_automl/?uid=${id_token}&did=${did}&param`))[
      "data"
    ] as any as Status;
  }

  async getAuthToken(): Promise<Status> {
    const id_token = await this.getUniqueToken();
    return (await axios(`${apiBaseUrl}/firestore/get_token/?token=${id_token}`))["data"] as any as Status;
  }

  async validateDataset(
    dataset: DatasetDB,
    deletedMolecules?: string[],
    updateMap?: { [key: string]: string }
  ): Promise<Status> {
    const id_token = await this.getUniqueToken();
    console.log(id_token);
    if (updateMap || deletedMolecules) {
      return (
        await axios(`${apiBaseUrl}/firestore/validate_dataset/?uid=${id_token}&did=${dataset.id}`, {
          params: { updates: JSON.stringify({ deletedMolecules: deletedMolecules, updateMap: updateMap }) },
        })
      )["data"] as any as Status;
    } else {
      return (await axios(`${apiBaseUrl}/firestore/validate_dataset/?uid=${id_token}&did=${dataset.id}`))[
        "data"
      ] as any as Status;
    }
  }

  async transformOceModel(mid: string): Promise<Status> {
    const id_token = await this.getUniqueToken();
    return (await axios(`${apiBaseUrl}/firestore/transform_oce_model/?uid=${id_token}&mid=${mid}`))[
      "data"
    ] as any as Status;
  }

  async inferModel(mid: string, did: string): Promise<Status> {
    console.log(mid, did);
    const id_token = await this.getUniqueToken();
    return (await axios.post(`${apiBaseUrl}/firestore/infer_model/?uid=${id_token}&mid=${mid}&did=${did}`))[
      "data"
    ] as any as Status;
  }

  /**
   * Runs a quick inference on a single smiles input
   *
   * @param mid model ID
   * @param smiles smiles string to infer on
   *
   * @returns a promise of a status containing the results of the inference
   */
  async quickInfer(mid: string, smiles: string): Promise<Status> {
    // TODO: finish work on API side and then call here
    console.log(database.user.uid, mid, smiles);
    const id_token = await this.getUniqueToken();
    return (
      await axios(`${apiBaseUrl}/firestore/infer_model_single?uid=${id_token}&mid=${mid}`, {
        params: { smiles: smiles },
      })
    )["data"] as any as Status;
    // const s: Status = { status: "success", data: "API Work In Progress" };
  }

  async visualizationFromAttributes(name: string, attributes: any): Promise<Status> {
    const id_token = await this.getUniqueToken();
    const cache = await localforage.getItem(JSON.stringify(["vis", name, attributes]));
    if (false) {
      return cache as any as Status;
    } else {
      const ret = (
        await axios(
          `${apiBaseUrl}/firestore/from_attributes?uid=${id_token}` +
            `&attributesStr=${encodeURIComponent(JSON.stringify(attributes))}&visualization_name=${name}`
        )
      )["data"] as any as Status;
      if (ret.status === "success") localforage.setItem(JSON.stringify(["vis", name, attributes]), ret).then(() => {});
      return ret;
    }
  }

  async queueBuildVisualization(type: string, attributes: string, name: any): Promise<Status> {
    // const cache = await localforage.getItem(JSON.stringify(["vis", name, attributes]));
    const id_token = await this.getUniqueToken();
    const ret = (
      await axios(
        `${apiBaseUrl}/firestore/queue_build_visualization?uid=${id_token}` +
          `&visualization_type=${type}&attributesStr=${encodeURIComponent(JSON.stringify(attributes))}&name=${name}`
      )
    )["data"] as any as Status;
    if (ret.status === "success") localforage.setItem(JSON.stringify(["vis", name, attributes]), ret).then(() => {});

    return ret;
  }
}

export const api = new FirestoreAPI();
export default database;
