/** Represents an object within the database. */
export interface DatabaseObject {
  id: string;
  path: string;
  content: any;
}

export interface Log {
  uid: string;
  api_function_called: string;
  traceback: string;
  when: Date;
}

/** Mirrors how Dataset should be implemented in our database(s). */
export interface Dataset {
  uid: string;
  data: {
    name?: string;
    file_path?: string;
    structure_col?: string;
    property_col?: string;
    feature_cols?: string[];
    [key: string]: any;
  };
  inferences?: any;
}

/** Mirrors how Model should be implemented in our database(s). */
export interface Model {
  uid: string;
  did: string;
  data: {
    status: "Proposing" | "Queued" | "Training" | "Trained" | "Runnable" | "Failed";
    name?: "";
    parameters?: string;
    metrics?: {
      [metric_name: string]: any;
    };
    fit_time?: number;
    description?: string;
    file_path?: string;
    path?: string;
    [key: string]: any;
  };
  inferences?: any;
  isPublic?: boolean;
  sharedUsers?: string[];
}

export interface Visualization {
  uid: string;
  // attributes: any[];
  status: string;
  data_url: string | null;
  hash: string;
}

export interface DatasetDB extends Omit<DatabaseObject, "content"> {
  content: Dataset;
}
export interface ModelDB extends Omit<DatabaseObject, "content"> {
  content: Model;
}

export interface LogDB extends Omit<DatabaseObject, "content"> {
  content: Log;
}

export interface VisualizationDB extends Omit<DatabaseObject, "content"> {
  content: Visualization;
}

/** Status of an operation (success/error) and accompanying traceback if error. */
export interface Status {
  status: "success" | "error";
  traceback?: string;
  [key: string]: any;
}

export interface AttributeSection {
  [name: string]: {};
}

export type Attribute = AttributeSection[];

/** BaseDatabase creates an abstract interface for all database objects (firestore, aws, etc.)
 * to extend and implement. */
export abstract class BaseDatabase<Query> {
  // TOOLS

  abstract uploadToStorage(path: string, file: Blob): Promise<Status>;

  // QUERIES

  /** Will get all datasets associated with the base database authenticated user. */
  abstract queryUserDatasets(): Query;

  /** Will get all models associated with a particular dataset, which authenticated user has access to. */
  abstract queryDatasetModels(did: string): Query;

  // SETTERS/UPLOADS

  /** Uploads a dataset to database and returns database object with key.*/
  abstract createDataset(dataset: Dataset): Promise<DatabaseObject>;

  abstract updateObj(path: string, data: any): Promise<Status>;

  // GETTERS

  /** Get the results of a query
   * @param q the relevant query
   */
  abstract get(q: Query): Promise<DatabaseObject[]>;

  /** Create a callback
   * @param q the relevant query
   * @param callback this will be called each time the query results change
   * @returns a method to destroy the listener.
   */
  abstract getLive(q: Query, callback: Function): () => void;
}
