import { getAttributes, getCompatibleVisualizations } from "api/Oloren";
import { useEffect, useState } from "react";

export const useVisualize = (visualizationName?: string) => {
  const [validVisualizations, setValidVisualizations] = useState<string[]>([]);
  const [requestedAttributes, setRequestedAttributes] = useState<Map<string, [string, string, any[]][]>[]>();

  useEffect(() => {
    getCompatibleVisualizations().then((visualizations) => {
      setValidVisualizations(visualizations);
    });
    if (visualizationName) {
      getAttributes(visualizationName).then((attributes) => {
        setRequestedAttributes(attributes);
      });
    }
  }, [visualizationName]);

  return {
    validVisualizations,
    requestedAttributes,
  };
};

/**
 * This interface specifies how OAS will receive attributes from the backend. This will result from
 * a BaseVisualization object calling get_attributes() on the backend.
 */
export interface RequestedAttributes {
  component_map: {
    // TODO: implementation of the components that are values down below
    // "modelSelector": modelSelector,
    // "datasetSelector": datasetSelector,
    // "colorFillSelector": colorFillSelector,
    // "featureSelector": featureSelector,
    // "booleanToggle": booleanToggle,
    // "inputString": inputString,
    // "inputNumber": inputNumber,
    // "test": Components.modelSelector
  };

  // An ordered list of the attribute sections that will be displayed on the attribute sidebar.
  // Each attribute section is represented as a list of attribute label, component type pairs which are
  // mapped to a string that represents the attribute "section"
  request: Map<string, [string, string, any[]][]>[];
}
