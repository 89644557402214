import React from "react";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import database from "api/FirestoreDatabase";

const LINKS = [
  // { name: "Build Models", to: "/build/upload" },
  // { name: "Predict Properties", to: "/predict/select" },
  // { name: "Visualize", to: "/visualize" },
];

export default function Navbar({ appName = "Oloren Analysis Suite" }) {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const curPath = useLocation().pathname;
  return (
    <nav className="w-full flex justify-center items-center h-16 bgGradient">
      <div className="flex items-center align-middle w-11/12 h-full">
        <Link
          onClick={() => {}}
          to="/"
          className="text-xl md:text-2xl xl:text-4xl text-white font-bold mr-4 xl:mr-8 h-fit font-display"
        >
          Oloren Analysis Suite
        </Link>
        {LINKS.map((link, idx) => (
          <Link
            key={idx}
            to={link.to}
            className="text-base mt-1 md:text-base xl:text-2xl text-white font-bold mr-4 xl:mr-8 h-fit border-transparent border-b-2 hover:border-b-2 hover:border-white active:outline hidden sm:flex font-display"
          >
            {link.name}
          </Link>
        ))}
        <div className="ml-auto">
          {database.user !== null ? (
            <>
              {/* <button
                onClick={async () => {
                  navigate("/settings");
                }}
                className="text-base mt-1 md:text-base xl:text-2xl text-white font-bold  h-fit ml-auto border-transparent border-b-2 hover:border-b-2 hover:border-white active:outline font-display"
              >
                Settings
              </button> */}
              <button
                onClick={async () => {
                  try {
                    await logout();
                    navigate("/");
                  } catch (err) {
                    console.log(err);
                  }
                }}
                className="text-base mt-1 md:text-base xl:text-2xl text-white font-bold  h-fit ml-8 border-transparent border-b-2 hover:border-b-2 hover:border-white active:outline font-display"
              >
                Log Out
              </button>
            </>
          ) : (
            <button
              onClick={async () => {
                try {
                  navigate(`/login?redirectTo=${window.location.pathname + window.location.search}`);
                } catch (err) {
                  console.log(err);
                }
              }}
              className="text-base mt-1 md:text-base xl:text-2xl text-white font-bold  h-fit ml-8 border-transparent border-b-2 hover:border-b-2 hover:border-white active:outline font-display"
            >
              Log In
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}
