import { DatabaseObject } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";
import NameCard from "components/atoms/NameCard/NameCard";
import { useContext, useEffect, useState } from "react";

import Panel from "components/atoms/Panel/Panel";
import { VisualizeContext } from "components/pages/Visualize/VisualizeContext";

export default function ObjectChooser() {
  const [objects, setObjects] = useState<DatabaseObject[]>([]);

  const { selecting, setSelecting, setSelectorAttributes, selectorAttributes } = useContext(VisualizeContext);

  useEffect(() => {
    function setObjectsFiltered(objects: DatabaseObject[]) {
      setObjects(
        objects.filter(
          (o) =>
            o.content?.data?.file_path && (selecting[1] !== "modelSelector" || o.content?.data?.status === "Runnable")
        )
      );
    }

    if (selecting[1] === "modelSelector") return database.getLive(database.queryUserModels(), setObjectsFiltered);
    else if (selecting[1] === "datasetSelector")
      return database.getLive(database.queryUserDatasets(), setObjectsFiltered);
    else console.error(`${selecting} is not a supported selector selecting!`);
  }, [selecting]);

  return (
    <Panel
      children={
        <div className="flex flex-col">
          <h1 className="flex-none font-display font-bold text-2xl">
            Select a {selecting[1] === "modelSelector" ? "Model" : "Dataset"}
          </h1>
          <div className="grow w-full flex flex-wrap">
            {objects.map((object: any, idx: number) => (
              <button
                key={idx}
                className="mt-4 mr-4"
                type="button"
                onClick={() => {
                  if (setSelecting && setSelectorAttributes) {
                    setSelectorAttributes({ ...selectorAttributes, [selecting[0]]: object });
                    setSelecting(null);
                  }
                }}
              >
                <NameCard uniqueKey={object.id} name={object?.content?.data?.name || "Untitled"} />
              </button>
            ))}
          </div>
        </div>
      }
    />
  );
}
