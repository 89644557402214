import { Dataset, DatasetDB, Status } from "api/BaseDatabase";
import database, { api } from "api/FirestoreDatabase";
import { useEffect, useState } from "react";
import { csvToTable, Table } from "util/Tables";
import { useNavigate } from "react-router-dom";
import { validateMolecules } from "./validateMolecules";

export const useBuildUpload = ({ userId }: { userId: string }) => {
  let navigate = useNavigate();

  const [nextButtonText, setNextButtonText] = useState("Next");
  const [loading, setLoading] = useState<boolean | null>(null);
  // Get all user datasets
  const [datasets, setDatasets] = useState<DatasetDB[]>([]);
  useEffect(() => {
    const setValidDatasets = (d: DatasetDB[]) => {
      setDatasets(d.filter((dat) => dat?.content?.data?.file_path));
    };
    return database.getLive(database.queryUserDatasets(), setValidDatasets);
  }, [userId]);

  // Object to store newly uploaded/constructed dataset
  const [datasetFields, setDatasetFields] = useState<Dataset>({
    uid: userId,
    data: {
      name: "",
      file_path: "",
      structure_col: "",
      property_col: "",
      feature_cols: [],
    },
  });

  const [selectedDataset, setSelectedDataset] = useState<DatasetDB | null>(null);
  const [file, setFile] = useState<File>();

  /** Upload the current dataset to the DB.  */
  const upload: () => Promise<[Status, DatasetDB | null]> = async () => {
    if (!file) return [{ status: "error", traceback: "No file selected" }, null];
    const dataset = await database.createDataset(datasetFields);
    const file_path = `gs://oloren-ai.appspot.com/${userId}/datasets/${dataset.id}.csv`;
    await database.uploadToStorage(file_path, file);
    setDatasetFields({
      ...datasetFields,
      data: { ...datasetFields.data, file_path: file_path },
    });
    database.updateObj(dataset.path, {
      data: { ...datasetFields.data, file_path: file_path },
    });
    setSelectedDataset(dataset);
    return [{ status: "success" }, dataset];
  };

  /** If we have a selected dataset already in the DB, update the values to match. */
  const update: () => Promise<[Status, DatasetDB | null]> = async () => {
    selectedDataset && database.updateObj(selectedDataset.path, datasetFields);
    return [{ status: "success" }, selectedDataset];
  };

  const [invalidMolecules, setInvalidMolecules] = useState<string[]>([]);
  const next = async (deletedMolecules?: string[], updateMap?: { [key: string]: string }) => {
    if (file && datasetFields.data.structure_col && (!deletedMolecules || !updateMap)) {
      setNextButtonText("Checking SMILES...");
      const invalidMols = (await validateMolecules(file, datasetFields.data.structure_col)) as any as string[];
      if (invalidMols.length > 0) {
        setInvalidMolecules(invalidMols);
        setNextButtonText("Next");
        return;
      }
    }
    setNextButtonText(selectedDataset ? "Validating..." : "Uploading...");
    const updateOp = selectedDataset ? update : upload;
    const [status, dataset] = await updateOp();

    if (status.status === "success" && dataset) {
      // Calls the validate function
      let validateStatus;
      if (deletedMolecules || updateMap)
        validateStatus = await api.validateDataset(dataset, deletedMolecules, updateMap);
      else validateStatus = await api.validateDataset(dataset);

      setNextButtonText("Validating...");

      // Redirects after the validate function finishes
      if (validateStatus.status === "success") {
        navigate(`/build/create/?did=${dataset.id}`);
      } else {
        setNextButtonText("Next");
        console.log("Validation Error");
        console.log(validateStatus);
      }
    } else {
      console.log("Upload Error");
      console.log(status);
    }
  };

  const  handleRemove = () => {
    setInvalidMolecules([]);
    setSelectedDataset(null);
    setTable(null);
    setDatasetFields({
      uid: userId,
      data: {
        name: "",
        file_path: "",
        structure_col: "",
        property_col: "",
        feature_cols: [],
      },
    });
  };

  const selectDataset = (idx: number) => {
    if (datasets[idx].id === selectedDataset?.id) {
      handleRemove();
    }
    setLoading(true);
    console.log(loading);
    database.downloadLinkFromStorage(datasets[idx].content.data.file_path || "").then((file) => {
      csvToTable(file).then((table) => {
        setTable(table);
        setLoading(false);
        console.log(loading);
      });
    });
    setSelectedDataset(datasets[idx]);
    setDatasetFields(datasets[idx].content);
  };

  const clickColumn = (idx: number, columnType?: "structure_col" | "property_col" | "feature_cols") => {
    if (!columnType) {
      // Special cases for auto-selecting column type
      if (table?.columns[idx] === datasetFields.data.structure_col) columnType = "structure_col";
      else if (table?.columns[idx] === datasetFields.data.property_col) columnType = "property_col";
      else if (datasetFields.data.feature_cols?.includes(table?.columns[idx] || "")) columnType = "feature_cols";
      else if (datasetFields.data.structure_col === "") columnType = "structure_col";
      else if (datasetFields.data.property_col === "") columnType = "property_col";
      else columnType = "feature_cols";
    }

    let df = datasetFields; // temporary variable to avoid repeatedly mutating datasetFields

    // Deselect if the column has been selected for a different columnType
    ["structure_col", "property_col", "feature_cols"]
      .filter((col) => col !== columnType)
      .forEach((col) => {
        if (col === "feature_cols" && df.data[col]?.includes(table?.columns[idx] || ""))
          df.data[col] = df.data[col]?.filter((c) => c !== table?.columns[idx] || "");
        else if (table?.columns[idx] === df.data[col]) df.data[col] = "";
      });

    // Actually select/deselect the given column
    if (columnType === "feature_cols") {
      if (df.data.feature_cols?.includes(table?.columns[idx] || ""))
        df.data.feature_cols = df.data.feature_cols.filter((col) => col !== table?.columns[idx]);
      else df.data.feature_cols = [...(df.data.feature_cols || []), table?.columns[idx] || ""];
    } else df.data[columnType] = df.data[columnType] === table?.columns[idx] ? "" : table?.columns[idx];

    setDatasetFields({ ...datasetFields, ...df });
  };

  const [table, setTable] = useState<Table | null>(null);

  const handleUpload = async (file: File) => {
    setFile(file);
    setLoading(false);
    setTable(await csvToTable(file));
    setDatasetFields({
      ...datasetFields,
      data: { ...datasetFields.data, name: file.name },
    });
  };

  return {
    datasets,
    table,
    handleUpload,
    handleRemove,
    clickColumn,
    datasetFields,
    setDatasetFields,
    next,
    selectDataset,
    selectedDataset,
    nextButtonText,
    loading,
    setLoading,
    invalidMolecules,
  };
};
