import { ModelDB } from "api/BaseDatabase";
import Button from "components/atoms/Button/Button";
import { compress } from "compress-json";

interface VisualizeParametersButtonProps {
  model: ModelDB | null;
}

export function VisualizeParametersButton() {
  return (
    <a target="_blank" rel="noreferrer noopener">
      <Button htmlFor="visualize-parameters-modal">Visualize Model Parameters</Button>
    </a>
  );
}

export function VisualizeParametersModal({ model }: VisualizeParametersButtonProps) {
  return (
    <>
      <input type="checkbox" id="visualize-parameters-modal" className="modal-toggle" defaultChecked={false} />
      <div className="modal w-full">
        <div className="modal-box  max-w-none h-full overflow-clip w-11/12 bg-white">
          <label htmlFor="visualize-parameters-modal" className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <iframe
            title="visualize-parameters"
            src={
              model?.content?.data?.parameters
                ? `https://jsoncrack.com/widget?json=${encodeURIComponent(
                    JSON.stringify(compress(JSON.parse(model?.content?.data?.parameters)))
                  )}`
                : ""
            }
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </>
  );
}
