import React, { useEffect, useState } from "react";
// Div can not be a descendent of <p>

interface TextboxProps {
    text: string;
    setText: Function;
    noText?: string;
    ref: any;
    label: string | null;
    altLabel: string | null;
    pad?: string;
    width?: string;
    handlePaste?: any;
    handleKeyPress?: any;
}
export default function Textbox({ text, setText, ref, label, altLabel, noText='', pad='', width='', handlePaste, handleKeyPress}:TextboxProps) {
  const keyBlacklist = [
    'Shift',
    'Alt',
    'Control',
    'Tab',
    'Space',
    'Meta',
    'Down',
    'ArrowDown',
    'Up',
    'ArrowUp',
    'Left',
    'ArrowLeft',
    'Right',
    'ArrowRight',
    'Enter',
    'Esc',
    'Escape',

  ];


  return (
    <div className={"block form-control w-full " + pad}>
      {label || altLabel ? (
        <label className="label">
          {label ? <span className="label-text">{label}</span> : null}
          {altLabel ? <span className="label-text-alt">{altLabel}</span> : null}
        </label>
      ) : null}
      <input
        id='textbox-input'
        type="text"
        value={text}
        onPaste={handlePaste ? handlePaste : null}
        // onKeyDown={(event: any) => {
        //   if (handleKeyPress && keyBlacklist.indexOf(event.key) === -1 && !event.metaKey && !(event.key === "Backspace" && text ==="")) {
        //     handleKeyPress()
        //   }
        // }}
        onChange={(event: any) => {
          if (Math.abs(event.target.value.length - text.length) <= 1 && handleKeyPress) {
            handleKeyPress();
          } 
          setText && setText(event.target.value);
        }}
        placeholder = {noText}
        ref={ref}
        className={"input input-bordered w-full order-first mt-1 " + width}
      />
    </div>
  );
}
