import { ChonkyActions, ChonkyIconName, FileActionHandler, FileAction, FileData, FullFileBrowser, FileActionData } from "chonky";

export default function SkeletonBrowser({numFakeFiles}:{ numFakeFiles: number}) {

    return (
        <>
            <FullFileBrowser 
                folderChain={[]}
                files={(new Array(numFakeFiles)).fill(null)}
            />
        </>
    )
}