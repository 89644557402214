import Button from "components/atoms/Button/Button";
import AttributeCollapser from "components/molecules/AttributeCollapser/AttributeCollapser";
import Collapser from "components/atoms/Collapser/Collapser";
import Textbox from "components/atoms/Textbox/Textbox";

import { Form, Formik } from "formik";
import { useContext } from "react";
import { VisualizeContext } from "components/pages/Visualize/VisualizeContext";
import { objectMap } from "../../../util/General";
import { DatabaseObject } from "api/BaseDatabase";

interface AttributeTabProps {
  attributes: any;
  setAttributes: Function;
  tabNames: string[];
  setTabNames: Function;
  activeTab: number;
  [key: string]: any;
}

export default function AttributeTab({
  attributes,
  setAttributes,
  tabNames,
  setTabNames,
  activeTab,
}: AttributeTabProps) {
  const { selectorAttributes } = useContext(VisualizeContext);
  if (!attributes || !Array.isArray(attributes))
    // Add a "skeleton loader" while attributes are coming in
    return (
      <div className="w-full animate-pulse bg-neutral rounded p-6">
        <div className="h-6 w-1/3  bg-gray-300 rounded col-span-2 mb-4"></div>
        <div className="h-4  bg-gray-300 rounded col-span-2 mb-3"></div>
        <div className="h-4  bg-gray-300 rounded col-span-2"></div>
      </div>
    );

  const collapsers = attributes.map((attributeSection: any, idx: number) => (
    <AttributeCollapser key={idx} attributeSection={attributeSection} />
  ));

  const nameCollapser = (
    <Collapser headerName="Name the Visualization">
      <Textbox
        text={tabNames[activeTab]}
        setText={(name: string) => {
          setTabNames(
            tabNames.map((tabName: string, idx: number) => {
              if (idx === activeTab) {
                if (name === "") {
                  return "New Visualization";
                }
                return name;
              } else {
                return tabName;
              }
            })
          );
        }}
        ref={null}
        label={null}
        altLabel={null}
      />
    </Collapser>
  );

  return (
    <>
      <Formik
        initialValues={{ model: null, dataset: null, threshold: 50, visualName: "" }}
        onSubmit={(values) => {
          let newValues = values;
          for (const key of Object.keys(values)) {
            // @ts-ignore
            const value: any = values[key];
            if (value && value.constructor == Object && "value" in value) {
              // @ts-ignore
              newValues[key] = value.value;
            }
          }
          setAttributes({
            ...values,
            ...objectMap(selectorAttributes, (obj: DatabaseObject) => (obj?.id ? obj.id : null)),
          });
        }}
      >
        <Form>
          {collapsers}
          {nameCollapser}
          <Button type="submit">Generate Visualization</Button>
        </Form>
      </Formik>
    </>
  );
}
