import React from "react";
import { FaTrash } from "react-icons/fa";

export default function DisplayTable({
  header = [],
  rows = [],
  onClick = () => {},
  deleteFn = null,
  selected = -1,
  selectColor = "bg-primary/20",
  dropdownColumn = null,
  dropdownOptions = [],
  dropdownIdx = 0,
  setDropdownIdx={dropdownIdx},
  id,
}) {
  if (header.length === 0 && rows.length === 0) {
    return <div>No data uploaded</div>;
  }
  return (
    <table className="w-full select-none" id={id}>
      <thead>
        <tr className="backgroundUpperTable text-sm cabin text-bold text-center">
        {header.map((item, idx) => {
            
              return dropdownColumn === idx ? (
              
                <th className="border border-[#b9b9b9] dropdown dropdown-end w-full h-full">
                  <label tabIndex="0" className="btn m-1 shadow bg-blue-500 w-90 h-90">
                    {dropdownOptions[dropdownIdx]}
                  </label>
                  
                  <ul
                    tabIndex="0"
                    className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    {dropdownOptions.map((metric, i) => {
                      return i !== dropdownIdx ? (
                        <li
                        onClick={() => {
                          setDropdownIdx(i);
                          console.log(dropdownIdx);
                        }}
                        key={i}
                      >
                        <a>{ dropdownOptions[i]}</a>
                      </li>
                      ) : (
                        <li
                        className="shadow bg-blue-100 rounded-box w-42"
                        key={i}
                    >
                      <a>{ dropdownOptions[i]}</a>
                      </li>
                      );
                      
                    })}
                    
  
                  </ul>
                </th>
              ) : (
                <th className="border border-[#b9b9b9] " key={idx}>
                  {item}
                </th>
              );

           
          })}
           
        </tr>
      </thead>
      <tbody>
        {rows.length === 0 ? (
          <></>
        ) : (
          rows.map((row, row_idx) => {
            return (
              <tr
                className="text-sm cabin text-normal text-center bg-white cursor-pointer"
                key={row_idx}
              >
                {row.map((cell, cell_index) => {
                  return (
                    <td
                      className={
                        "border group border-[#b9b9b9] " +
                        (selected === row_idx ? "" + selectColor : "")
                      }
                      key={cell_index}
                      onClick={(e) => {
                        onClick(row_idx);
                      }}
                    >
                      {cell}
                      {deleteFn && cell_index === 0 ? (
                        <FaTrash
                          className={`float-right text-opacity-0 text-black group-hover:text-opacity-100 hover:text-red-500 mr-4`}
                          onClick={(e) => {
                            deleteFn(row_idx);
                            e.stopPropagation();
                          }}
                        />
                      ) : null}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}
