import Drawer from "components/atoms/Drawer/Drawer";
import { useState } from "react";
import Toggle from "components/atoms/Toggle/Toggle";
import { Table } from "util/Tables";
import Spinner from "components/pages/Spinner/Spinner";
import Button from "components/atoms/Button/Button";

interface MoleculeDataTableProps {
  table: Table;
  onSelectCol?: (columnIdx: number) => void;
  getColColor?: (columnIdx: number) => string;
  smilesCol?: number;
  height?: string;
  id?: string;
  downloadPredictions?: any;
}

export default function MoleculeDataTable({
  table,
  getColColor,
  onSelectCol,
  smilesCol = 0,
  id,
  height = "max-h-full",
  downloadPredictions,
}: MoleculeDataTableProps) {
  const [rendered, setIsRendered] = useState(false);
  const [fullSmiles, setFullSmiles] = useState(false);

  const getRowName = (str: any) => {
    const MAX_LENGTH = 25;
    if (typeof str === "string") {
      if (str.length >= MAX_LENGTH) {
        let adjusted = str.substring(0, MAX_LENGTH) + "...";
        return adjusted;
      }
    }
    return str;
  };

  const specialCols = ["Canonical SMILES - OAS", "predicted_values", "notes"];


  return (
    <div className={`flex flex-col max-h-full`} id={id}>
      <article className="w-full grow border-2 border-neutral rounded-xl overflow-auto">
        <div>
          <table className={`table-auto ${height}`}>
            <thead className="text-lg font-bold font-display text-black bg-neutral h-16">
              <tr>
                {table?.columns?.length !== 0 &&
                  table?.columns?.map((column_name, idx) => {
                    return (
                      <th
                        className={`px-4 whitespace-nowrap w-fit borderColorTable border ${
                          specialCols.includes(column_name) ? " bg-info" : ""
                        }`}
                        key={idx}
                      >
                        <div className="font-bold text-left">{column_name}</div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="text-base divide-y divide-gray-100">
              {table?.rows?.map((row, row_idx) => {
                return (
                  <tr key={row_idx}>
                    {row.map((cell, cell_index) => {
                      return (
                        <td
                          className={
                            `borderColorTable ${table.rows[row_idx][cell_index] === "" ? "" : " border "} w-fit max-w-2xl m-0 py-2 px-4 
                            ${getColColor ? getColColor(cell_index) : ""} 
                            ${cell_index <= 2  && table.rows[row_idx][smilesCol] !== "" ? " bg-neutral " : " "}
                            ${smilesCol === null ? " " : rendered === true ? " text-center " : " "}
                            `
                          }
                          key={cell_index}
                          onClick={() => onSelectCol ? onSelectCol(cell_index) : {}}
                        >
                          {smilesCol === cell_index && rendered === true ? (
                            <Drawer smilesStr={cell} className="w-64 h-full" />
                          ) : fullSmiles ? (
                            cell
                          ) : (
                            getRowName(cell)
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </article>
      <div className="flex flex-row gap-2 w-full border-2 border-neutral rounded-xl pl-2 mt-1 ">
        <Toggle
          defaultState={false}
          labelText="Render molecules"
          userHandleChange={() => {
            setIsRendered(!rendered);
            console.log(rendered);
          }}
        />
        <Toggle
          defaultState={false}
          labelText="See full smiles"
          userHandleChange={() => {
            setFullSmiles(!fullSmiles);
            console.log(fullSmiles);
          }}
        />
        {downloadPredictions ? (
          <div className="pt-1">
            <Button fullWidth={false} onClick={downloadPredictions}>
              Click to Download Full Predictions
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
