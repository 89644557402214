import { compress } from "compress-json";
import React from "react";
import { useSearchParams } from "react-router-dom";

export default function JsonVis() {
  let [searchParams] = useSearchParams();
  const json = searchParams.get("json") as any as string;
  return json ? (
    <iframe
      title="json-vis"
      src={`https://jsoncrack.com/widget?json=${encodeURIComponent(JSON.stringify(compress(JSON.parse(json))))}`}
      className="w-screen h-screen"
    ></iframe>
  ) : (
    <div>Invalid JSON in json= query param</div>
  );
}
