import React from "react";

interface ButtonProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  htmlFor?: string;
  className?: string;
  noAnimation?: boolean;
  id?: string;
  type?: "button" | "submit" | "reset";
}
export default function Button({
  children,
  fullWidth = true,
  fullHeight = false,
  disabled = false,
  noAnimation = false,
  onClick = () => {},
  htmlFor = "",
  className = "",
  type,
  id,
}: ButtonProps) {
  const disableAnimation = noAnimation ? "no-animation" : "";
  return htmlFor ? (
    <label
      id={id}
      htmlFor={htmlFor}
      className={
        "btn font-display text-white btn-primary btn-sm normal-case" +
        (fullWidth ? " w-full " : " mx-2") +
        (fullHeight ? " h-full " : "") +
        (disabled ? " btn-disabled" : "")
      }
    >
      {children}
    </label>
  ) : (
    <button
      id={id}
      className={
        `btn ${disableAnimation} font-display text-white btn-primary btn-sm normal-case` +
        (fullWidth ? " w-full " : " mx-2") +
        (fullHeight ? " h-full " : "") +
        (disabled ? " btn-disabled bg-info" : "") +
        " " +
        className
      }
      onClick={onClick}
      type={type}
    >
      {" "}
      {children}{" "}
    </button>
  );
}
