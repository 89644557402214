///// IMPORTING FUNCTION AND VARIABLES THAT DRIVE ENDPOINT DASHBOARD /////
import useQuickPredictSmiles from './useQuickPredictSmiles';
import useFileUpload from './useFileUpload';
import React, {useState} from 'react';
import { useErrorUpdate } from 'contexts/ErrorContext';
import { Log, Status } from 'api/BaseDatabase';
import database from 'api/FirestoreDatabase';
import { useMachine } from '@xstate/react';
import EndpointMachine from './EndpointMachine';
/////////////////////////////////////////////////////////////////////////


export const useEndpointDashboard = (mid: string ) => {

    const setError = useErrorUpdate();
    const throwError = async (out: any, traceback: string, api_function_called: string, when: Date) => {
        if (out.status === "error" || out.status === "Error") {
            logError(traceback, api_function_called, when)
            return out;
        }
        return out;

    }

    const logError  = async (traceback: string, api_function_called: string, when: Date) => {
        const log: Log = {
            uid: database.user.uid,
            traceback: JSON.stringify(traceback),
            api_function_called: api_function_called,
            when: when,
        }
        const Error = await database.createLog(log);

        setError(Error);
    } 
    const [state, send] = useMachine(EndpointMachine);
    const [userInput, setUserInput] = useState<string>("");

    


    const {
        invalidMolecules,
        fileInfo,
        fileName,
        uploadDataset,
        handleUpload,
        handleRemove,
        predictedValues,
        validateAfterDeletion,
        downloadPredictions,
        inferStatus,
        invalidMid,
    } = useFileUpload(mid, userInput, setUserInput, throwError, state, send);


    const {
        prediction,
        nextButtonText,
        next,
        handlePaste,
        handleKeyPress,
    } = useQuickPredictSmiles(mid, throwError, userInput, setUserInput, handleRemove, state, send);

    return {
        fileInfo,
        fileName,
        handleUpload,
        handleRemove,
        userInput,
        prediction,
        nextButtonText,
        setUserInput,
        next,
        handlePaste,
        invalidMolecules,
        uploadDataset,
        predictedValues,
        validateAfterDeletion,
        downloadPredictions,
        inferStatus,
        invalidMid,
        state,
        send,
        handleKeyPress,
    };


}