import React, { useState, useEffect } from 'react';
import database from 'api/FirestoreDatabase';
import { api } from 'api/FirestoreDatabase';
import { Dataset } from 'api/BaseDatabase';
import { Table } from 'util/Tables';
import { ModelDB, DatasetDB, Status } from 'api/BaseDatabase';
import { getStructureFromTable, csvToTable } from 'util/Tables';
import { validateMolecules } from '../BuildUpload/validateMolecules';
import { DatabaseObject } from 'api/BaseDatabase';
import { saveAs } from "file-saver";

export default function useFileUpload(mid: string, userInput: string, setUserInput: Function, throwError: Function, state: any, send: any) {

  ////// UPLOAD FIELDS //////
  // KEEP
  const userId = database.user.uid;
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [fileName, setFileName] = useState<string>("Uploading...");
  const [datasetID, setDatasetID] = useState<string>("");
  const [invalidMolecules, setInvalidMolecules] = useState<string[]>([]);
  const [invalidMid, setInvalidMid] = useState<boolean>(false);
  const [inference, setInference] = useState<DatabaseObject | null>(null);
  const inferStatus = inference?.content?.status || "Submitting inference request";
  ///////////////////////////

  useEffect(() => {
    if (!mid) return;
    database.getModel(mid).then((model) => {
      console.log(model.id);
    }).catch((err) => {
      setInvalidMid(true);
    });
  }, [mid]);



  const handleUploadCSV: (file: File) => Promise<[Status, DatasetDB | null]> = async (file: File) => {
    setInference(null);
    setUserInput("");
    const _table = await csvToTable(file);
    if (!_table) { return [{ status: "error" }, null]; }
    structure_col_idx = await getStructureFromTable(_table);
    fileRef = file;
    setFileInfo(file);
    structure_col = _table.columns[structure_col_idx];
    let df = {
      uid: userId,
      data: {
        name: file.name,
        property_col: "",
        feature_cols: [],
      }
    }
    const dataset = await database.createDataset(df);
    setDatasetID(dataset.id);
    did = dataset.id
    const file_path = `gs://oloren-ai.appspot.com/${userId}/datasets/${dataset.id}.csv`;
    await database.uploadToStorage(file_path, file);
    const newdf = {
      ...df,
      data: { ...df.data, file_path: file_path, structure_col: structure_col },
    };
    await database.updateObj(dataset.path, newdf);
    setFileName(file.name);
    return [{ status: "success" }, dataset];
  }

  const handleUploadAlt: (file: File) => Promise<[Status, DatasetDB | null]> = async (file: File) => {
    setInference(null);
    setUserInput("");
    fileRef = file;
    setFileInfo(file);
    structure_col_idx = 0;
    structure_col = "Smiles"
    let df = {
      uid: userId,
      data: {
        name: file.name,
        property_col: "",
        feature_cols: [],
      }
    }
    const dataset = await database.createDataset(df);
    setDatasetID(dataset.id);
    did = dataset.id
    const suffix = file.name.split(".")[1];
    console.log(suffix);
    const file_path = `gs://oloren-ai.appspot.com/${userId}/datasets/${dataset.id}.${suffix}`;
    await database.uploadToStorage(file_path, file);
    const newdf = {
      ...df,
      data: { ...df.data, file_path: file_path, structure_col: structure_col },
    };
    await database.updateObj(dataset.path, newdf);
    setFileName(file.name);
    return [{ status: "success" }, dataset]
  }

  let structure_col_idx: number;
  let fileRef: File;
  let structure_col: string;
  let did: string;
  const handleUpload: (file: File) => Promise<[Status, DatasetDB | null]> = async (file: File) => {
    send({type: "INITIAL"})
    send({type: "DATASET_UPLOAD"});
    console.log(state.value);
    if (file.name.includes("csv")) { return handleUploadCSV(file); }
    if (file.name.includes("sdf")) { return handleUploadAlt(file); }


    return [{ status: "error" }, null]

  }

  const handleRemove = async () => {
    if (state.matches("MoleculeDataTable") || 
        state.matches("LoadingSpinner-Dataset") ||
        state.matches("InvalidMoleculesCorrector")) {
          send({type: "INITIAL"});
        }
    setInference(null);
    setPredictedValues(null);
    setFileName("Removing...");
    setFileInfo(null);
    setFileName("Uploading...");
    setInvalidMolecules([]);
    if (datasetID !== "") {
      const dataset = await database.getDataset(datasetID);
      console.log(dataset);
      const deleted = await database.deleteDataset(dataset);
      console.log(deleted, "<====== DELETED STATUS");
      setDatasetID("");
    }
  }

  const uploadDataset = async (file: File, deletedMolecules?: string[], updateMap?: { [key: string]: string }) => {
    const [status, dataset] = await handleUpload(file);
    console.log(status, "<==== UPLOAD STATUS")
    console.log(fileRef, "<====== FILE INFO");
    console.log(structure_col, "<====== STRUCTURE COLUMN");
    validateAndInfer(status, dataset, deletedMolecules, updateMap);
  };

  const validateAndInfer = async (status: Status, dataset: DatasetDB | null, deletedMolecules?: string[], updateMap?: { [key: string]: string }) => {

    if (fileRef && structure_col && (!deletedMolecules || !(updateMap))) {

      const invalidMols = (await validateMolecules(fileRef, structure_col)) as any as string[];
      console.log(invalidMols, "<====== INVALID MOLECULES");

      if (invalidMols.length > 0) {
        setInvalidMolecules(invalidMols);
        send({type: "INVALID_DATASET"});
        return;
      }

      if (status.status === "success" && dataset) {
        validateAfterDeletion(deletedMolecules, updateMap);
      } else {
        console.log("Upload Error");
        console.log(status);
      }
    }
  }

  const validateAfterDeletion = async (deletedMolecules?: string[], updateMap?: { [key: string]: string }) => {
    send({type: "VALID_DATASET"});
    let validateStatus;
    const dataset = datasetID !== "" ? await database.getDataset(datasetID) : await database.getDataset(did);
    if (deletedMolecules || updateMap)
      validateStatus = await api.validateDataset(dataset, deletedMolecules, updateMap).then(async (out) => {
        const status = await throwError(out, out.traceback, "validateDataset", new Date());
        return status;
      });
    else validateStatus = await api.validateDataset(dataset).then(async (out) => {
      const status = await throwError(out, out.traceback, "validateDataset", new Date());
      return status;
    });

    if (validateStatus.status === "success") {
      setInvalidMolecules([]);
      await infer(mid, dataset.id);
      console.log("predicted");
    } 
  }



  const infer = async (mid: string, did: string) => {

    await api.inferModel(mid, did).then(async (out) => {
      console.log(out);
      const status = await throwError(out, out.traceback, "inferModel", new Date());
      return status;
    })
  }

  useEffect(() => {
    const checkInferenceStatus = async () => {
      if (inference?.content?.status === "Error") {
        await throwError(inference?.content, inference?.content?.traceback, "inference error", new Date());
      }
    }
    checkInferenceStatus();

  }, [inference])
  


  useEffect(() => {
    if (datasetID !== "") {
      database.getDocumentLive(`datasets/${datasetID}/inferences/${mid}`, setInference);
    }
  }, [mid, datasetID]);



  // Get predicted values once inference is finished
  const [predictedValues, setPredictedValues] = useState<Table | null>(null);

  useEffect(() => {
    console.log(inference);
    if (inference?.content?.predictions) {
      database.downloadFromStorage(inference.content.predictions || "").then((file) => {
        csvToTable(file).then((table) => {
          setPredictedValues(table);
          send({type: "INFER_DATASET_COMPLETED"});
        });
      });
    }
  }, [inference]);



  const downloadPredictions = async () => {
    if (inference?.content?.predictions) {
      saveAs(await database.downloadLinkFromStorage(inference.content.predictions), `predictions-${datasetID}-${mid}.csv`);
    }
  };




  return {
    invalidMolecules,
    fileInfo,
    fileName,
    datasetID,
    uploadDataset,
    handleUpload,
    handleRemove,
    predictedValues,
    validateAfterDeletion,
    downloadPredictions,
    inferStatus,
    invalidMid,
  }
}