import NoVisSelected from "components/atoms/NoVisSelected/NoVisSelected";
import Panel from "components/atoms/Panel/Panel";
import "./VisualizationTabs.css";

export default function VisualizationTabs({
  tabHeaders,
  tabContents,
  activeTab,
  addTab = () => {},
  removeTab = (idx) => {},
  selectTab = (idx) => {},
}) {
  const displayDelete = (name, idx) => {
    if (name === activeTab) {
      return <div className="inline"></div>;
    }
  };

  let content = <NoVisSelected />;
  if (tabContents[activeTab] !== undefined) {
    content = tabContents[activeTab].plot;
  }

  return (
    <div className="w-full h-full">
      <div className="tabs">
        {tabHeaders.map((name, idx) => {
          return (
            <div
              key={idx}
              className={
                idx === activeTab
                  ? "vistab tab-active-bg tab-active tab-lifted font-medium bg-opacity-100"
                  : "tab tab-lifted bg-info hover:bg-neutral font-medium"
              }
              onClick={() => selectTab(idx)}
            >
              <div className="flex flex-row space-x-1">
                <p>{name}</p>
                <button
                  className="hover:font-bold stroke-current stroke-[2] hover:stroke-[3]"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTab(idx);
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 24 24 ">
                    <path d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          );
        })}
        <button
          className="tab tab-lifted bg-info hover:bg-neutral font-medium text-xl"
          onClick={() => {
            addTab();
            // selecting tabHeader.length as tabHeader has not yet been updated 
            selectTab(tabHeaders.length);
          }}
        >
          +
        </button>
      </div>
      <div id="panel-wrapper" className="flex-grow h-full w-full">
        <Panel children={content} />
      </div>
    </div>
  );
}
