import database from "api/FirestoreDatabase";
import YesNoModal from "components/molecules/YesNoModal/YesNoModal";
import React, { useEffect, useState } from "react";
import { DatabaseObject } from "api/BaseDatabase";
import { ModelDB } from "api/BaseDatabase";
import DocumentEditor from "components/molecules/DocumentEditor/DocumentEditor";
import { ManualToggle } from "components/atoms/Toggle/Toggle";
import Button from "components/atoms/Button/Button";
import { documentId } from "firebase/firestore";

export function OpenModelEditorButton() {
  return (
    <Button fullWidth={true} htmlFor={"my-modal-editor"}>
      Open Model Card
    </Button>
  );
}

export default function ModelDocumentEditor({ user, model }: { user: DatabaseObject; model: ModelDB }) {
  const [description, setDescription] = useState(model?.content?.data?.description || "");

  const [showModal, setShowModal] = useState(false);
  const [validVids, setValidVids] = useState<Set<string>>(new Set());

  const shareStatus =
    "autoShareVis" in model?.content?.data ? (model.content.data.autoShareVis ? "yes" : "no") : "unset";
  const [visualizations, setVisualizations] = useState<DatabaseObject[]>([]);
  useEffect(() => {
    if (validVids.size === 0) return;
    database.getLive(database.queryVisualizationList([...validVids]), setVisualizations);
  }, [validVids]);

  useEffect(() => {
    if (description === "" && model) {
      setDescription(model?.content?.data?.description || "");
    }
  }, [model]);

  useEffect(() => {
    if (shareStatus !== "no") {
      visualizations.every((v) => {
        if (v.content?.isPublic) return true;
        // check if visualization is not shared properly
        if (model?.content?.isPublic) {
          if (shareStatus === "yes") {
            alert("public-sharing");
            database.updateDocument(v, { isPublic: true }); // share publically if model is public
          } else {
            setShowModal(true);
            return false;
          }
        } else {
          console.log("Hello: ", model?.content?.sharedUsers);
          for (const u of model?.content?.sharedUsers || []) {
            if (!v?.content?.sharedUsers.includes(u)) {
              if (shareStatus === "yes") {
                alert("user-sharing");
                database.updateDocument(v, {
                  sharedUsers: [...v?.content?.sharedUsers, u],
                });
                // share with model users if model is shared
              } else {
                setShowModal(true);
                return false;
              }
            }
          }
        }
        return true;
      });
    }
  }, [model, visualizations, shareStatus]);

  return showModal ? (
    <YesNoModal
      title="Share Visualizations with Model Users?"
      description="Some visualizations are not shared with model users. Do you want us to automatically share the visualizations with model users?"
      visible={showModal}
      response={(answer: boolean) => {
        database.updateDocument(model, { "data.autoShareVis": answer });
        setShowModal(false);
      }}
    />
  ) : (
    <>
      <input
        type="checkbox"
        id="my-modal-editor"
        className="modal-toggle"
        defaultChecked={!user?.content?.[model.id]}
      />
      <div className="modal w-full">
        <div
          className={
            "modal-box  max-w-none h-full overflow-clip " +
            (model?.content?.uid === database.user.uid ? "w-11/2" : "w-1/2") +
            " bg-white"
          }
        >
          <label htmlFor="my-modal-editor" className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <DocumentEditor
            validVids={validVids}
            setValidVids={setValidVids}
            content={description}
            setContent={(x: string) => {
              setDescription(x);
              if (model) database.updateDocument(model, { "data.description": x });
            }}
            viewOnly={model?.content?.uid !== database.user.uid}
            underComponent={
              <div className="flex flex-row space-x-4">
                <ManualToggle
                  isChecked={!user?.content?.[model.id]}
                  labelText={"Show Model Card on Open"}
                  handleChange={(x: any) => {
                    if (user) {
                      database.updateDocument(user, { [model.id]: !user?.content?.[model.id] as boolean });
                    }
                  }}
                />

                <ManualToggle
                  isChecked={shareStatus === "yes"}
                  labelText={"Auto-Share Visualizations with Model Users"}
                  handleChange={(x: any) => {
                    if (user) {
                      database.updateDocument(model, {
                        "data.autoShareVis": !model.content.data.autoShareVis as boolean,
                      });
                    }
                  }}
                />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}
