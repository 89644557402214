import { ModelDB } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function useBuildSave(did: string) {
  const navigate = useNavigate();
  if (!did) {
    navigate("/build/upload");
  }

  // Get all models, including their metrics and other data.
  const [models, setModels] = useState<ModelDB[]>([]);
  useEffect(() => {
    return database.getLive(database.queryDatasetModels(did), setModels);
  }, [did]);

  const [selectedModel, setSelectedModel] = useState<ModelDB | null>(null);

  return { models, selectedModel, setSelectedModel };
  // const shareOptions = [
  //   {
  //     id: 1,
  //     title: "Only you can access",
  //     image: <BiLockAlt className="flex-shrink-0 h-6 w-6 rounded-full" />,
  //   },
  //   {
  //     id: 2,
  //     title: "Anyone with the link can access",
  //     image: <BiLink className="flex-shrink-0 h-6 w-6 rounded-full" />,
  //   },
  // ];
  // const [shareSelected, setShareSelected] = useState(shareOptions[0]);

  // const {
  //   models,
  //   setModels,
  //   modelSelected,
  //   modelTable,
  //   selectModel,
  //   selectedModel,
  //   modelId,
  //   deleteModel,
  // } = useModels(userId, datasetId);

  // let { metrics } = useModelDisplay(modelId);

  // const [modelMetadata, setModelMetadata] = useState({ name: "", description: "", endpoint: "" });

  // useEffect(() => {
  //   if (modelSelected) {
  //     const model = Object.values(models)[selectedModel];
  //     setModelMetadata({
  //       name: model.name || "",
  //       endpoint: model.endpoint || "",
  //       description: model.description || "",
  //     });
  //     setShareSelected(shareOptions[model.share_id ? model.share_id - 1 : 0]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedModel, models]);

  // const updateMetadata = async () => {
  //   await updatePath(`models/${modelId}`, modelMetadata);
  // };

  // const saveSharingPermissions = async () => {
  //   updatePath(`models/${modelId}`, { share_id: shareSelected.id });
  // };

  // return {
  //   models,
  //   setModels,
  //   modelSelected,
  //   modelTable,
  //   selectModel,
  //   selectedModel,
  //   modelId,
  //   deleteModel,
  //   metrics,
  //   modelMetadata,
  //   setModelMetadata,
  //   updateMetadata,
  //   shareSelected,
  //   setShareSelected,
  //   shareOptions,
  //   saveSharingPermissions,
  // };
}
