import React from "react";

export default function Metrics({ metrics }) {
  return (
    <div className="stats flex-none shadow flex items-center overflow-x-scroll">
      {metrics
        ? Object.keys(metrics).map((metric, idx) => {
            return (
              <div className="stat" key={idx}>
                <div className="stat-title">
                  {metric === "r2" ? (
                    <>
                      R<sup>2</sup>
                    </>
                  ) : (
                    metric
                  )}
                </div>
                <div className="stat-value text-primary font-bold font-display text-lg">
                  {Math.round(metrics[metric] * 100) / 100}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
