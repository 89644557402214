import React, { useEffect, useRef, useState } from "react";
import SmilesDrawer from "smiles-drawer";

export default function Drawer({ smilesStr, className = "w-full h-full" }) {
  const [drawable, setDrawable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [numDraws, setNumDraws] = useState(0);

  const svgRef = useRef(null);
  const drawer = new SmilesDrawer.SvgDrawer({});

  useEffect(() => {
    if (numDraws < 2 && smilesStr !== "") {
      SmilesDrawer.parse(smilesStr, parseSuccess, parseFail);
    }
  });

  useEffect(() => {
    setNumDraws(0);
  }, [smilesStr]);


  return drawable ? (
    <div id="smileRoot" className={className}>
      <svg id="pass" xmlns="http://www.w3.org/2000/svg" ref={svgRef}></svg>
    </div>
  ) : errorMessage === "" ? (
    <p>Loading...</p>
  ) : (
    <div id="log" className="alert alert-error shadow-lg">
      <p>Could not render SMILES.</p>
    </div>
  );

  function parseSuccess(data) {
    drawer.draw(data, svgRef.current, "light", false);
    console.log("parsing success");
    setDrawable(true);
    setNumDraws(numDraws + 1);
  }

  function parseFail(err) {
    console.log("parsing fail");
    setDrawable(false);
    setErrorMessage(err.toString());
    setNumDraws(numDraws + 1);
  }
}
