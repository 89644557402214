import database from "api/FirestoreDatabase";
import Home from "components/pages/Home/Home";
import { useAuth } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import BuildCreate from "./components/pages/BuildCreate/BuildCreate";
import BuildSave from "./components/pages/BuildSave/BuildSave";
import BuildUpload from "./components/pages/BuildUpload/BuildUpload";
import PredictInfer from "./components/pages/PredictInfer/PredictInfer";
import PredictSelect from "./components/pages/PredictSelect/PredictSelect";
import PredictUpload from "./components/pages/PredictUpload/PredictUpload";
import Settings from "components/pages/Settings/Settings";
import Visualize from "./components/pages/Visualize/Visualize";
import { AuthProvider } from "./contexts/AuthContext";
import { AppProvider } from "./contexts/GlobalContext";
// import Login from "./legacy/Login";
// import Signup from "./legacy/Signup";
// import ForgotPassword from "./legacy/ForgotPassword";
import PrivateRoute from "./legacy/PrivateRoute";
import Login from "./components/pages/Login/Login";
import Signup from "./components/pages/Signup/Signup";
import ForgotPassword from "./components/pages/ForgotPassword/ForgotPassword";
import UploadModel from "components/pages/UploadModel/UploadModel";
import OamAuth from "components/pages/OamAuth/OamAuth";
import { ErrorProvider } from "contexts/ErrorContext";
import ErrorModal from "components/atoms/ErrorModal/ErrorModal";
import React from "react";
import EndpointDashboard from "./components/pages/EndpointDashboard/EndpointDashboard";
import Visualization from "components/pages/Visualization/Visualization";
import ReactGA from "react-ga4";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import JsonVis from "components/pages/JsonVis/JsonVis";

ReactGA.initialize("G-ERT2N5F8MG");

const queryClient = new QueryClient();

export function Base({ children, admin = false }) {
  const { currentUser } = useAuth();
  database.authenticate(currentUser);
  return (
    <PrivateRoute admin={admin}>
      <div className="w-screen h-screen" data-theme="olorenlight">
        {children}
      </div>
    </PrivateRoute>
  );
}

function App() {
  return (
    <Router>
      <AppProvider>
        <AuthProvider>
          <DndProvider backend={HTML5Backend}>
            <ErrorProvider>
              <ErrorModal />
              <QueryClientProvider client={queryClient}>
                {/* prettier-ignore */}
                {/* added comment */}
                <Routes>
                  {/* Redirect to Model Building */}
                  <Route
                    exact
                    path="/"
                    element={
                      <Base>
                        {" "}
                        <Navigate to="/home" />{" "}
                      </Base>
                    }
                  />
                  <Route exact path="/debug" element={<Base> Debug </Base>} />
                  <Route exact path="/admin" element={<Base admin={true}> This is confidential. </Base>} />

                  <Route exact path="/jsonvis" element={<JsonVis />} />
                  <Route exact path="/vis" element={<Visualization />} />

                  <Route
                    exact
                    path="/home"
                    element={
                      <Base>
                        {" "}
                        <Home />{" "}
                      </Base>
                    }
                  />
                  <Route
                    exact
                    path="/auth"
                    element={
                      <Base>
                        {" "}
                        <OamAuth />{" "}
                      </Base>
                    }
                  />

                  <Route
                    exact
                    path="/endpoint"
                    element={
                      <Base>
                        {" "}
                        <EndpointDashboard />{" "}
                      </Base>
                    }
                  />
                  <Route
                    exact
                    path="/upload/model"
                    element={
                      <Base>
                        {" "}
                        <UploadModel />{" "}
                      </Base>
                    }
                  />

                  {/* Model Building Routes */}
                  <Route
                    exact
                    path="/build/upload"
                    element={
                      <Base>
                        {" "}
                        <BuildUpload />{" "}
                      </Base>
                    }
                  />
                  <Route
                    exact
                    path="/build/create/"
                    element={
                      <Base>
                        {" "}
                        <BuildCreate />{" "}
                      </Base>
                    }
                  />
                  <Route
                    exact
                    path="/build/save/"
                    element={
                      <Base>
                        {" "}
                        <BuildSave />{" "}
                      </Base>
                    }
                  />

                  {/* Predicting Routes */}
                  <Route
                    exact
                    path="/predict/select"
                    element={
                      <Base>
                        {" "}
                        <PredictSelect />{" "}
                      </Base>
                    }
                  />
                  <Route
                    exact
                    path="/predict/upload"
                    element={
                      <Base>
                        {" "}
                        <PredictUpload />{" "}
                      </Base>
                    }
                  />
                  <Route
                    exact
                    path="/predict/infer"
                    element={
                      <Base>
                        {" "}
                        <PredictInfer />{" "}
                      </Base>
                    }
                  />

                  {/* Visualize Routes */}
                  <Route
                    exact
                    path="/visualize"
                    element={
                      <Base>
                        {" "}
                        <Visualize />{" "}
                      </Base>
                    }
                  />

                  {/* Settings Routes */}
                  <Route
                    exact
                    path="/settings"
                    element={
                      <Base>
                        {" "}
                        <Settings />{" "}
                      </Base>
                    }
                  />

                  {/* Auth Routes */}
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                </Routes>
              </QueryClientProvider>
            </ErrorProvider>
          </DndProvider>
        </AuthProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
