import React, { useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "api/Firebase";
import { useNavigate } from "react-router-dom";

import database from "api/FirestoreDatabase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children, user = null }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  // const [errorOnLoginMessage, setErrorOnLoginMessage] = useState("");
  // const [errorOnSignupMessage, setErrorOnSignupMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      setLoading(false);
    }
  }, [user]);

  async function signup(email, password, confirmPassword, setErrorMessage, redirectTo = null) {
    setErrorMessage("");
    if (password === confirmPassword) {
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate(redirectTo == null ? "/home" : redirectTo);
        // setErrorOnSignupMessage("");
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setErrorMessage("Given email is already in use.");
        } else if (error.code === "auth/invalid-email") {
          setErrorMessage("Given email is invalid.");
        } else if (error.code === "auth/operation-not-allowed") {
          setErrorMessage(
            "Email/Password accounts are not currently enabled. Please contact support for more information."
          );
        } else if (error.code === "auth/weak-password") {
          setErrorMessage("Given password is too weak. Please use a stronger password.");
        }
      }
    } else {
      setErrorMessage("Passwords do not match");
    }
  }

  async function login(email, password, setErrorMessage, redirectTo = null) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(redirectTo == null ? "/home" : redirectTo);
    } catch (error) {
      console.log(error.code);
      if (error.code === "auth/invalid-email") {
        setErrorMessage("Given email is invalid.");
      } else if (error.code === "auth/wrong-password") {
        setErrorMessage("Given password is incorrect.");
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage("No user found with the given email.");
      } else if (error.code === "auth/user-disabled") {
        setErrorMessage("Account with the given email has been disabled. Please contact support for more information.");
      }
      // console.log(errorOnLoginMessage);
    }
  }

  async function logout() {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  }

  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  const provider = new GoogleAuthProvider();
  function logInWithGoogle(redirectTo = null) {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // // The signed-in user info.
        // const user = result.user;
        navigate(redirectTo == null ? "/home" : redirectTo);
        // ...
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const checkUserInDatabase = async (uid) => {
    const docRef = doc(database.db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const parseEmail = (email) => {
    // get the email organization after the @ from email
    const emailOrg = email.split("@")[1];

    return emailOrg;
  };

  const checkOrgInDatabase = async (org) => {
    const docRef = doc(database.db, "organizations", org);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      database.authenticate(user);
      try {
        const userInDatabase = await checkUserInDatabase(user.uid);
        if (!userInDatabase) {
          console.log("User not in database");
          await setDoc(doc(database.db, "users", user.uid), {
            admin: false,
          });
        } else {
          console.log("User is in database");
        }
        const userEmail = user.email;
        const userEmailOrg = parseEmail(userEmail);
        const orgInDatabase = await checkOrgInDatabase(userEmailOrg);
        console.log("checked org in database");
        if (orgInDatabase) {
          const userRef = doc(database.db, "users", user.uid);
          setDoc(userRef, { organization: userEmailOrg }, { merge: true });
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    logInWithGoogle,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
