import { memo, useState, useEffect } from "react";
import Drawer from "components/atoms/Drawer/Drawer";
import Spinner from "components/pages/Spinner/Spinner";
import Button from "components/atoms/Button/Button";
import { validateSMILES } from "util/Molecules";

interface PredictionDisplay {
    userInput: string;
    prediction: any;
  }

export default function PredictionDisplay({userInput, prediction}: PredictionDisplay) {

    const [smilesRender, setSmilesRender] = useState<any>(<div></div>);
    let confidenceInterval;
    let userInputPrediction: any;
    let errorHist;

    if (prediction?.status === "success") {
      userInputPrediction =  parseFloat(JSON.parse(prediction.data).prediction).toFixed(2);
      if (JSON.parse(prediction.data).ci) {
        confidenceInterval =  parseFloat(JSON.parse(prediction.data).ci).toFixed(2);
        errorHist =  JSON.parse(prediction.data).vis ;
      } else {
        confidenceInterval = "Not present";
        errorHist = "Not present";
      }
    } else {
      confidenceInterval = "Not present";
      errorHist = "Not present";
      userInputPrediction = "Error in predicting"
    }

    console.log(confidenceInterval);
    
    const renderErrorPlot = (errorHist: any) => {

        return <PureIFrame src={errorHist} className="w-full h-full" scrolling="no" />;
    };
  
    const smilesString = (userInput: any) => {
      if (userInput.length >= 17) {
        let adjusted = userInput.substring(0, 17) + "...";
        return adjusted;
      }
      return userInput;
    };
  
    useEffect(() => {
      if (userInput !== "") {
        const fetchSmilesValidity = async () => {
          let valid = (await validateSMILES(userInput)) as any as boolean;
          if (valid) {
            setSmilesRender(<Drawer smilesStr={userInput} className="h-full w-full" />);
          } else {
            setSmilesRender(<div>Invalid SMILES, please try again.</div>);
          }
        };
        fetchSmilesValidity();
      }
    }, [userInput]);
  
    const getConfidenceInterval = (confidenceInterval: any) => {
        const minusPredicted = parseFloat(userInputPrediction) - parseFloat(confidenceInterval);
        const plusPredicted = parseFloat(userInputPrediction) + parseFloat(confidenceInterval);
        const res = "(" + minusPredicted.toFixed(2) + ", " + plusPredicted.toFixed(2) + ")";
        return res;
    };
  
    const PureIFrame = memo((props: any) => <iframe title="plot" {...props} />);
  

    return (
        <>
            { !errorHist.includes("%") ?
                <>
                <div className="bg-neutral w-full px-3 pt-2 h-full max-h-full">
                  <div className="flex flex-col gap-0 h-full items-center">
                    <div className="max-h-4/5 h-4/5 w-full h-full rounded-xl bg-base-100 text-center grid place-items-center">
                      <div className="w-64">{smilesRender}</div>
                    </div>
                    <div className="flex flex-col gap-2 grid place-items-center w-fit">
                      <div className="max-h-1/5 h-1/5">
                        <h3 className="text-md font-display rounded-xl bg-base-100 font-bold p-2 ">
                          Molecule SMILES:
                          <span className="text-md text-normal font-normal ml-1 p-2">
                            {smilesString(userInput)}
                          </span>
                        </h3>
                      </div>
                      <div className="max-h-1/5 h-1/5 mb-6">
                        <div className="rounded-xl bg-base-100 p-2  max-w-fit">
                          
                            <h3 className="text-md font-display font-bold">
                            Prediction:
                            <span data-testid="prediction" className="text-md text-normal font-normal ml-1">{userInputPrediction}</span>
                            </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </> 
              :
              <div className="flex grow bg-neutral p-3 w-full h-full">
                <div className=" flex flex-row gap-2 rounded-xl w-full" data-testid="prediction-window">
                  <div className="w-1/4 flex flex-col gap-1 lg:gap-6">
                    <div className="rounded-xl bg-base-100 p-2 w-full h-fit">{smilesRender}</div>
                    <div className="rounded-xl bg-base-100 p-2 w-full h-fit">
                      <h3 className="text-md font-display font-bold">Molecule SMILES:</h3>
                      <p className="font-body text-md">{smilesString(userInput)}</p>
                    </div>
                    <div className="rounded-xl bg-base-100 p-2 w-full h-fit">
                      <h3 className="text-md font-display font-bold pb-2">
                        Prediction:
                        <span className="text-md text-normal font-normal ml-1">{userInputPrediction}</span>
                      </h3>
                      <h3 className="text-md font-display font-bold">80% Confidence Interval:</h3>
                      <span className="font-body text-md">{getConfidenceInterval(confidenceInterval)}</span>
                    </div>
                  </div>
                  <div className={"rounded-xl bg-base-100 w-3/4 p-2"}>
                    {renderErrorPlot(errorHist)}
                  </div>
                </div>
              </div>
            }
        </> 
        


    )
}