import { DatabaseObject } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";
import { Base } from "App";
import Collapser from "components/atoms/Collapser/Collapser";
import { ManualToggle } from "components/atoms/Toggle/Toggle";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import Navbar from "components/organisms/Navbar/Navbar";
import { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Creatable from "react-select/creatable";
import ReactGA from "react-ga4";
import CopyTextButton from "components/molecules/CopyTextButton/CopyTextButton";

const PureIFrame = memo((props: any) => <iframe title="plot" {...props} />);

export default function Visualization() {
  let [searchParams] = useSearchParams();
  const vid = searchParams.get("vid");

  const [vis, setVis] = useState<DatabaseObject | null>(null);
  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
    database.isDocumentPublic(`visualizations/${vid}`).then((p: boolean) => {
      if (p) database.getDocumentLive(`visualizations/${vid}`, setVis);
      else setIsPublic(false);
    });
  }, [vid]);

  useEffect(() => {
    if (vis?.content?.isPublic) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, [vis]);

  const plot = (
    <div className="flex flex-col w-full h-full">
      <div className="w-full grow grid place-items-center text-4xl">
        {vid ? (
          vis ? (
            vis?.content?.status === "Visualization rendered" ? (
              <PureIFrame src={vis.content.data_url} className="block w-full h-full" scrolling="no" />
            ) : (
              <h1>Visualization is invalid or you do not have access to it.</h1>
            )
          ) : (
            <div>Loading...</div>
          )
        ) : (
          <h1>Invalid Visualization Link</h1>
        )}
      </div>
      <CopyTextButton label="Data URL:" text={vis?.content.data_url} />
    </div>
  );
  const vis_element =
    vis?.content?.uid === database?.user?.uid && database?.user?.uid ? (
      <SidebarLayout
        sidebarPanel={
          <Collapser headerName="Visualization Sharing">
            <Creatable
              formatCreateLabel={(x: string) => `Invite ${x}`}
              placeholder={"Enter emails to share model with..."}
              value={(vis?.content?.sharedUsers || [])
                .filter((x: string) => x !== database.user.email)
                .map((x: string) => {
                  return { value: x, label: x };
                })}
              isMulti={true}
              allowCreateWhileLoading={false}
              onChange={(x: any) => {
                if (vis)
                  database.updateDocument(vis, {
                    sharedUsers: x.map((a: any) => a.value).filter((x: string) => x !== database.user.email),
                  });
              }}
              isDisabled={vis?.content?.isPublic || false}
            />
            <ManualToggle
              labelText={"Publicly Accessible"}
              isChecked={vis?.content?.isPublic || false}
              handleChange={() => {
                if (vis) database.updateDocument(vis, { isPublic: !(vis?.content?.isPublic || false) });
              }}
            />
          </Collapser>
        }
        mainPanel={plot}
      />
    ) : (
      <div className="w-screen h-screen flex flex-col bg-white">
        <Navbar />
        {plot}
      </div>
    );

  return isPublic ? vis_element : <Base> {vis_element} </Base>;
}
