import React, { useState } from "react";
import { useFormik } from "formik";
import logoImg from "images/logo.svg";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <>
      <section className="w-full bgGradient h-screen flex items-center justify-center flex-col">
        <img src={logoImg} alt="logo" className="mb-8 h-20 sm:h-24" />
        <article className="bg-white py-8 px-8 rounded-xl w-11/12 xl:w-3/12 lg:w-4/12 sm:w-6/12">
          <h2 className="my-4 text-2xl font-semibold text-gray-700">Password Reset</h2>
          <form onSubmit={formik.handleSubmit}>
            <div id="emailDiv" className="grid">
              <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                required
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
              />
            </div>
            <button
              disabled={loading}
              className="w-full px-4 my-2 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-700 rounded-md shadow hover:bg-blue-400 focus:outline-none focus:ring-blue-200 focus:ring-4"
              type="submit"
            >
              Reset Password
            </button>
          </form>
          <div id="rememberPassword" className="w-100 text-right mt-2 text-base font-semibold text-gray-500 py-1.5">
            Remember your password?{" "}
            <Link to="/login" className="text-base text-blue-600 hover:underline focus:text-blue-800">
              Log In
            </Link>
          </div>
          <div id="signUp" className="w-100 text-right mt-2 text-base font-semibold text-gray-500 py-1.5">
            New to Oloren.AI?{" "}
            <Link to="/signup" className="text-base text-blue-600 hover:underline focus:text-blue-800">
              Sign Up
            </Link>
          </div>
        </article>
      </section>
    </>
  );
}
