import { Status, Visualization } from "api/BaseDatabase";
import { api } from "api/FirestoreDatabase";
import database from "api/FirestoreDatabase";
import { memo, ReactNode, useEffect, useState } from "react";
import { useQuery } from "react-query";
import ClipLoader from "react-spinners/ClipLoader";
import { doc, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "api/Firebase";
import sha256 from "crypto-js/sha256";
import Spinner from "components/pages/Spinner/Spinner";

interface PlotProps {
  visualizationType: string;
  attributes: any;
  visualizationName: string;
}

const PureIFrame = memo((props: any) => <iframe title="plot" {...props} />);

export default function Plot({ visualizationType, attributes, visualizationName }: PlotProps) {
  const [iframe, setIframe] = useState<ReactNode>(null);
  const [d, setD] = useState<any>(null);
  const [vid, setVid] = useState<string>("");
  const [vis, setVis] = useState<Visualization | null>(null);
  const [visStatus, setVisStatus] = useState<string>("");

  const typeAttribute = visualizationType + ":" + JSON.stringify(attributes);
  const hash = sha256(typeAttribute).toString();
  // query for the hash to see if it exists
  useQuery(
    [hash, visualizationType, attributes],
    async () => {
      const q = database.queryVisualization(hash);
      const qSnapshot = await getDocs(q);
      if (qSnapshot.empty) {
        const p: Status = await api.queueBuildVisualization(visualizationType, attributes, visualizationName);
        if (p.status === "error") throw new Error(p.traceback);
        else {
          setVid(p.data);
        }
      } else {
        console.log("visualization already exists");
        const doc = qSnapshot.docs[0];
        setVid(doc.id);
        setVis(vis);
      }
    }
    // { cacheTime: Infinity, staleTime: Infinity, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  // const { status, data, error } = useQuery(
  //   [visualizationType, attributes],
  //   async () => {
  //     // const p: Status = await api.visualizationFromAttributes(visualizationName, attributes);
  //     const p: Status = await api.queueBuildVisualization(visualizationType, attributes, visualizationName);
  //     if (p.status === "error") throw new Error(p.traceback);
  //     else {
  //       setVid(p.data);
  //       return p.data;
  //     };
  //   },
  //   { cacheTime: Infinity, staleTime: Infinity, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false}
  // );

  useEffect(() => {
    if (vid) {
      // setVisStatus(vis!.status);
      // setIframe(<PureIFrame src={vis?.data_url} className="block w-full h-full" scrolling="no" />);
      console.log(database.getVisualization(vid));
      database.getVisualization(vid).then((v) => {
        console.log("Resetting iframe!", vid);
        console.log(v);
        setD(v);
        setVisStatus(v.content.status);
        setIframe(<PureIFrame src={v.content.data_url} className="block w-full h-full" scrolling="no" />);
      });
    }
  }, [vid, vis]);

  useEffect(() => {
    console.log(hash);
    database.getLive(database.queryVisualization(hash), setVis);
  }, [visStatus]);

  // useEffect(() => {
  //   if (d !== data) {
  //     console.log("Resetting iframe!", d, data);
  //     setD(data);
  //     setIframe(<PureIFrame src={data} className="block w-full h-full" scrolling="no" />);
  //   }
  // }, [d, data]);

  // return status === "success" ? (
  //   <div className="w-full h-full">{iframe}</div>
  // ) : status === "error" ? (
  //   <div className="w-full h-full bg-white grid place-items-center">
  //     <div>
  //       {/* @ts-ignore */}
  //       {error.message}
  //     </div>
  //   </div>
  // ) : (
  //   <div className="w-full h-full bg-white grid place-items-center">
  //     <div>
  //       <ClipLoader color={"blue"} loading={true} size={150} />
  //       <p>{visStatus} </p>
  //     </div>
  //   </div>
  // );

  return visStatus === "Visualization rendered" ? (
    <div className="w-full h-full">{iframe}</div>
  ) : visStatus === "Error" ? (
    <div className="w-full h-full bg-white grid place-items-center">
      <div>
        {/* @ts-ignore */}
        {d.content.traceback}
      </div>
    </div>
  ) : (
    <Spinner statusStr={visStatus} />
  );
}
