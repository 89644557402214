import gradient from "random-gradient";
import { shrinkOnHover } from "util/Transitions";

export default function NameCard({
  name,
  uniqueKey,
  selected,
}: {
  name: string;
  uniqueKey?: string;
  selected?: boolean;
}) {
  if (!uniqueKey) uniqueKey = name;
  const colors = gradient(uniqueKey).split(/([,)])/);

  const nameAdjuster = (userInput: any) => {
    if (userInput.length >= 20) {
      let adjusted = userInput.substring(0, 20) + "...";
      return adjusted;
    }
    return userInput;
  };

  const bgGradient = { background: `linear-gradient(to top right, ${colors[2]} -25%,  ${colors[4]} 125%)` };

  return (
    <div className={"h-32 max-w-48 w-48 cursor-pointer grid place-items-center"}>
      <div className={shrinkOnHover + " w-48 " + (selected ? "border border-primary border-4 p-1" : "")}>
        <div style={bgGradient} className="h-3/4 max-w-full w-full rounded-t-xl "></div>
        <div className="h-1/4 w-full rounded-b-xl px-2 pt-1.5 text-sm bg-white align-middle overflow-ellipsis whitespace-nowrap overflow-hidden ">
          {nameAdjuster(name)}
        </div>
      </div>
    </div>
  );
}
