import { memo, useState, useEffect } from "react";
import Spinner from "components/pages/Spinner/Spinner";
import MoleculePreview from "./MoleculePreview-Button/MoleculePreview-Button";
import PredictionDisplay from "components/organisms/MoleculePredictDash/PredictionDisplay/PredictionDisplay";
import { State } from "xstate";
import SpinnerSingle from "./LoadingSpinner-Single/LoadingSpinner-Single";

interface MoleculePredictDashProps {
  userInput: string;
  next: Function;
  prediction: any;
  nextButtonText: string;
  state: any;
}

export default function MoleculePredictDash({
  userInput,
  next,
  nextButtonText,
  prediction,
  state,
}: MoleculePredictDashProps) {

  const ToggleDisplay = () => {
    console.log(state.value);
    if (state.matches('PredictionDisplay')) {
      return <PredictionDisplay userInput={userInput} prediction={prediction} />
    }
    else if (state.matches("LoadingSpinner-Single")) {
      return <SpinnerSingle state={state} />
    }
    else if (state.matches('MoleculePreview-Button')) {
      return <MoleculePreview userInput={userInput} nextButtonText={nextButtonText} next={next} />
    }
    return <div>HI</div>
  }

  return (
    <>
      <ToggleDisplay />
    </>
  );

}
