import { VisualizationDB, ModelDB } from "api/BaseDatabase";
import { ChonkyActions, ChonkyIconName, FileActionHandler, FileAction, FileData, FullFileBrowser, FileActionData } from "chonky";
import useObjectBrowser from "./useObjectBrowser";



export default function ObjectBrowser({DatabaseObjects, model}: {DatabaseObjects: any[], model?: ModelDB}) {
  console.log(model, "<====== PATH")
  const {
    handleCreate,
    handleDelete,
    handleFileMove,
    handleFileOpen,
    handleRename,
    folderChain,
    renameFolder,
    fileActions,
    currentFolders,
    currentPath,
    tempFolders,
    files,
  } = useObjectBrowser(DatabaseObjects, model);

  const handleAction: FileActionHandler = async (data) => {
    if (data.id === ChonkyActions.OpenFiles.id) {
      handleFileOpen(data);
    } else if (data.id === ChonkyActions.MoveFiles.id) {
      handleFileMove(data);
    } else if (data.id === ChonkyActions.CreateFolder.id) {
      handleCreate(data);
    } else if (data.id === ChonkyActions.DeleteFiles.id) {
      handleDelete(data);
    } else if (data.id === renameFolder.id) {
      handleRename(data);
    }
    console.log(data);
  };
  const actionsToDisable = [ChonkyActions.OpenSelection.id, ChonkyActions.ToggleHiddenFiles.id, ChonkyActions.SortFilesBySize.id, ChonkyActions.SortFilesByDate.id];
  
  const processedCurrFolders = (currentFolders).map((folder, i) => ({ id: `__models__/` + currentPath + folder, name: folder, isDir: true}));
  const processedTempFolders = tempFolders.filter((f) => f.id === '__models__/' + currentPath + f.name || processedCurrFolders.includes(f));
  const processed_files = files.concat(processedTempFolders).concat(processedCurrFolders);

  return (
    <FullFileBrowser
      disableDefaultFileActions={actionsToDisable}
      files={processed_files}
      fileActions={fileActions}
      onFileAction={handleAction}
      folderChain={folderChain}
    />
  );
}
