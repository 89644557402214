import { Field } from "formik";
import React from "react";

export default function Range({ name, min, max }: { name: string; min: number; max: number }) {
  return (
    <>
      <Field type="range" min={min} max={max} className="range range-info range-sm w-full justify-center" name={name} />
      <div className="relative">
        <div className="flex w-full justify-between text-xs -mb-5">
          <span>{min}</span>
          <span>{max}</span>
        </div>
      </div>
    </>
  );
}
