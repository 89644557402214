import database from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import Collapser from "components/atoms/Collapser/Collapser";
import Metrics from "components/atoms/Metrics/Metrics";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import DisplayTable from "components/organisms/DisplayTable/DisplayTable";

import { Field, Form, Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useBuildSave } from "./useBuildSave";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { HiClipboardCopy } from "react-icons/hi";
import { useRef } from "react";

export default function BuildSave() {
  let [searchParams] = useSearchParams();
  const did = searchParams.get("did");
  const { models, selectedModel, setSelectedModel } = useBuildSave(did);

  const introJsRef = useRef(null);

  return (
    <>
      <SidebarLayout
        sidebarPanel={
          <>
            <div id="save-0">
              <Collapser
                headerName="Models"
                helpMessage="Click a Finished/Runnable model here to see its relevant metadata and share it with others!"
                checked={true}
              >
                <DisplayTable
                  rows={models.map((model) => [model.content.data.name, model.content.data.status])}
                  header={["Model Name", "Status"]}
                  onClick={(idx) =>
                    models[idx] === selectedModel ? setSelectedModel(null) : setSelectedModel(models[idx])
                  }
                  selected={selectedModel ? models.map((model) => model.id).indexOf(selectedModel.id) : -1}
                  deleteFn={(idx) => database.deleteModel(models[idx])}
                />
              </Collapser>
            </div>
          </>
        }
        sidebarEnd={
          // Button that redirects to homepage onClick

          <a href="/">
            <Button fullWidth={true} fullHeight={true}>
              Return Home
            </Button>
          </a>
        }
        mainPanel={
          selectedModel ? (
            <div>
              <Collapser headerName="Model Metadata" id="save-1">
                <div className="form-control space-y-2">
                  <Metrics metrics={selectedModel.content.data.metrics} />

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: selectedModel.content.data.name,
                      description: selectedModel.content.data.description || selectedModel.content.data.parameters,
                      sharing: selectedModel.content.isPublic ? "public" : "private",
                    }}
                    onSubmit={(values) => {
                      database.updateDocument(selectedModel, {
                        "data.name": values.name,
                        "data.description": values.description,
                        isPublic: values.sharing === "public",
                      });
                    }}
                  >
                    <Form>
                      <div className="flex flex-col space-y-2">
                        <div>
                          <label class="label">
                            <span class="label-text">Model Name</span>
                          </label>
                          <Field
                            name="name"
                            type="text"
                            className="input w-full hover:input-bordered active:input-primary"
                          />
                        </div>

                        <div>
                          <label class="label">
                            <span class="label-text">Model Description</span>
                          </label>
                          <Field
                            name="description"
                            type="text"
                            as="textarea"
                            className="textarea w-full hover:textarea-bordered active:textarea-primary h-36"
                          />
                        </div>

                        <div>
                          <label class="label">
                            <span class="label-text">Model Sharing</span>
                          </label>
                          <Field
                            id="sharing"
                            name="sharing"
                            type="text"
                            as="select"
                            className="select w-full hover:select-bordered active:select-primary font-normal"
                          >
                            <option value="private" className="font-normal">
                              Only you can access
                            </option>
                            <option value="public" className="font-normal">
                              Anyone with the link can access
                            </option>
                          </Field>
                        </div>

                        <div>
                          <label class="label">
                            <span class="label-text">Model Link</span>
                          </label>
                          <div className="flex flex-row">
                            <input
                              type="text"
                              value={`${window.location.protocol}//${window.location.host}/predict/upload/?mid=${selectedModel.id}`}
                              className="input w-full grow"
                              readOnly
                            />
                            <CopyToClipboard
                              text={`${window.location.protocol}//${window.location.host}/predict/upload/?mid=${selectedModel.id}`}
                            >
                              <Button fullWidth={false} fullHeight={true} id="copy-link">
                                <HiClipboardCopy className="text-4xl h-full py-1" />
                              </Button>
                            </CopyToClipboard>
                            <div id="save-3">
                              <Button fullWidth={false} fullHeight={true}>
                                <a href="/predict/select">Move to Predict</a>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <a id="save-2">
                          {" "}
                          <Button type="submit">Save Changes</Button>{" "}
                        </a>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </Collapser>
            </div>
          ) : null
        }
        // mainPanel={
        //   modelSelected ? (
        //     <>
        //       <Collapser headerName="Model Metadata">
        //         <div className="form-control space-y-2">
        //           <Metrics metrics={metrics} />
        //           <div>
        //             <label className="label">
        //               <span className="label-text">Model Name</span>
        //             </label>
        //             <input
        //               type="text"
        //               value={modelMetadata.name}
        //               onChange={(e) => {
        //                 setModelMetadata({ ...modelMetadata, name: e.target.value });
        //               }}
        //               className="input w-full"
        //             />
        //           </div>

        //           <div className="">
        //             <label className="label">
        //               <span className="label-text">Model Endpoint</span>
        //             </label>
        //             <input
        //               type="text"
        //               value={modelMetadata.endpoint}
        //               onChange={(e) => {
        //                 setModelMetadata({ ...modelMetadata, endpoint: e.target.value });
        //               }}
        //               className="input w-full"
        //             />
        //           </div>

        //           <div>
        //             <label className="label">
        //               <span className="label-text">Model Description</span>
        //             </label>
        //             <textarea
        //               type="text"
        //               value={modelMetadata.description}
        //               onChange={(e) => {
        //                 setModelMetadata({ ...modelMetadata, description: e.target.value });
        //               }}
        //               className="textarea w-full"
        //             />
        //           </div>
        //           <Button onClick={updateMetadata}>Save Changes</Button>
        //         </div>
        //       </Collapser>
        //       <Collapser headerName="Model Sharing">
        //         <div className="form-control w-full space-y-2">
        //           <ImageDropdown
        //             options={shareOptions}
        //             selected={shareSelected}
        //             setSelected={setShareSelected}
        //           />
        //           <div>
        //             <label className="label">
        //               <span className="label-text">Model Share Link</span>
        //             </label>
        //             <div className="flex flex-row h-fit">
        //               <input
        //                 type="text"
        //                 value={`${window.location.protocol}//${window.location.host}/predict/upload/?mid=${modelId}`}
        //                 className="input w-full grow"
        //                 readOnly
        //               />
        //               <CopyToClipboard
        //                 text={`${window.location.protocol}//${window.location.host}/predict/upload/?mid=${modelId}`}
        //               >
        //                 <Button fullWidth={false} fullHeight={true}>
        //                   <HiClipboardCopy className="text-4xl h-full py-1" />
        //                 </Button>
        //               </CopyToClipboard>
        //             </div>
        //           </div>
        //           <Button onClick={saveSharingPermissions}>Save Sharing Permissions</Button>
        //         </div>
        //       </Collapser>
        //     </>
        //   ) : null
        // }
      />
    </>
  );
}
