import { useState, useEffect } from "react";

export default function Tabs({ 
  tabs=[],
  tabContent=[],
 }) {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, [tabs]);

  return (
    <>
      <div className="flex flex-grow">{tabContent[tabs.indexOf(activeTab)]}</div>
      <div className="tabs tabs-boxed w-full h-fit my-2">
        {tabs.map((tab, index) => {
          return (
            <button
              className={"tab" + (tab === activeTab ? " tab-active " : "") + " grow"}
              onClick={() => {
                setActiveTab(tab);
              }}
              key={index}
            >
              {tab}
            </button>
          );
        })}
      </div>
    </>
  );
}
