import { useState, useEffect } from "react";
import { api } from "api/FirestoreDatabase";
import { validateSMILES } from "util/Molecules";

export default function useQuickPredictSmiles(mid: string, throwError: Function, userInput: string, setUserInput: Function, handleRemove: Function, state: any, send: any) {
  ////// PREDICT ON QUICK SMILES FIELDS //////
  
  const [prediction, setPrediction] = useState<any | null>(null);
  const [nextButtonText, setNextButtonText] = useState<string>("Predict on Quick Input");
  /////////////////////////////////////////////

  useEffect(() => {
    if (userInput === "" && 
        !state.matches("LoadingSpinner-Dataset") &&
        !state.matches("MoleculeDataTable")) {
      send({type: "INITIAL"})
    }
  }, [userInput])

  const initMoleculePredictDash = (prediction: any) => {
    if (prediction.status === "success") {
      setPrediction(prediction);
    } else {
      setPrediction("Error in predicting");
    }
  };

  const next = async (pastedData = "") => {
    send({type: "INFER_SINGLE"});
    let data: string;
    if (pastedData === "") {
      data = userInput;
    } else {
      data = pastedData;
    }
    
    if (data !== "") {
      const valid: boolean = (await validateSMILES(data)) as any as boolean;

      if (valid) {
        // make call to predict and set prediction to the result
        const prediction = await api.quickInfer(mid, data).then(async (out) => {
          const status = await throwError(out, out.traceback, "quickInfer", new Date());
          return status;
        });
        console.log(prediction);
        initMoleculePredictDash(prediction);
        send({type: "INFER_COMPLETED"});

      } else {
        setNextButtonText("Prediction Error: Invalid SMILES");
      }
      
    }
  };

  const handlePaste = async (event: any) => {
    handleRemove();
    send({type: "PASTED_INPUT"});
    const pastedData = event.clipboardData.getData("text");
    console.log(pastedData, " <-- pasted data");
    const valid = (await validateSMILES(pastedData)) as any as boolean;
    console.log(valid);
    if (valid) {
      console.log(state);
      await next(pastedData);
    } else {
      handleKeyPress();
    }
  };

  const handleKeyPress = () => {
    if (!state.matches("MoleculePreview-Button")) {
      handleRemove();
      send( {type: "TYPED_INPUT"});
    }
    
  }

  

  return {
    userInput,
    nextButtonText,
    prediction,
    setUserInput,
    next,
    handlePaste,
    handleKeyPress,
  };
}
