import { matchingChildExists } from "api/Firebase";
import database from "api/FirestoreDatabase";
import { useAuth } from "contexts/AuthContext";
import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export default function PrivateRoute({ children, admin = false, bypassAuth = null }) {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [validUser, setValidUser] = useState(true);
  const [adminUser, setAdminUser] = useState(false);

  if (bypassAuth) {
  }

  const curPath = useLocation().pathname;

  useEffect(() => {
    if (admin && currentUser) {
      database.isUserAdmin().then((isAdmin) => {
        if (!isAdmin) {
          alert("You are not authorized to access this page.");
          navigate("/home");
        } else setAdminUser(isAdmin);
      });
    }
  }, [admin, navigate, currentUser]);

  if (!currentUser) {
    return <Navigate to={`/login?redirectTo=${window.location.pathname + window.location.search}`} />;
  }

  if (!validUser) {
    matchingChildExists("users", "email", currentUser.email).then((exists) => {
      if (exists) {
        setValidUser(true);
      } else {
        logout().then(() => {
          alert("You are not yet authorized to use the Oloren Analysis Suite. Join our waitlist @ https://oloren.ai/");
          navigate(`/login?redirectTo=${curPath}`);
        });
      }
    });
  }

  return validUser && (!admin || adminUser) ? children : null;
}
