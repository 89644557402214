import React from "react";
import { symbolName } from "typescript";
import { useState } from "react";
import HelpIcon from "../HelpIcon/HelpIcon";

interface CollapserProps {
  children: React.ReactNode;
  headerName?: string | React.ReactNode;
  helpMessage?: string;
  color?: string;
  id?: string;
  checked?: boolean;
  className?: string;
}

export default function Collapser({
  headerName = "Sample Header",
  children,
  helpMessage,
  color,
  checked = true, 
  className = "",
}: CollapserProps) {
  const [open, setOpen] = useState(checked);
  const overflow = open ? " overflow-visible" : " overflow-hidden";

  const bgColor = color ? color : "bg-neutral";

  return (
    <div className={`collapse collapse-arrow ${bgColor} mb-3 rounded-lg overflow-visible ${className}`}>
      <input
        type="checkbox"
        checked={open}
        onChange={() => {
          setOpen(!open);
        }}
      />
      <div className="collapse-title text-xl font-display font-bold flex items-center justify-between">
        {headerName}
        {helpMessage ? <HelpIcon message={helpMessage} /> : null}
      </div>
      <div className={"collapse-content font-body" + overflow}>{children}</div>
    </div>
  );
}
/*{"collapse-title " + {headerStyling} + " font-display font-bold "} */
