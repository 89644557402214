export const objectMap = (obj: any, fn: Function) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export function isDefaultModelName(name: string, parameters: string) {
  const tokens = name.split(" ");
  if (tokens.length !== 2) return false; // two tokens
  if (!parameters.includes(tokens[0])) return false; // class name should be in parameters
  if (tokens[1].length !== 8) return false; // hash is 8 characters long
  return true;
}
