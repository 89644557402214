import database from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import Collapser from "components/atoms/Collapser/Collapser";
import Panel from "components/atoms/Panel/Panel";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import DisplayTable from "components/organisms/DisplayTable/DisplayTable";
import ModelDisplay from "components/organisms/ModelDisplay/ModelDisplay";
import { useAuth } from "contexts/AuthContext";
import React from "react";
import usePredictSelect from "./usePredictSelect";

export default function PredictSelect({ uid }) {
  const { currentUser } = useAuth();
  const userId = currentUser ? currentUser.uid : uid;
  const { models, selectedModel, setSelectedModel, next } = usePredictSelect(userId);

  return (
    <>
      <SidebarLayout
        sidebarPanel={
          <>
            <a id="predict-0">
              <Collapser
                headerName="Models"
                helpMessage="Click to select which model will be used for inference. Make sure the model status is Runnable!"
              >
                <DisplayTable
                  rows={models.map((model) => [model.content.data.name, model.content.data.status])}
                  header={["Model Name", "Status"]}
                  onClick={(idx) =>
                    models[idx] === selectedModel ? setSelectedModel(null) : setSelectedModel(models[idx])
                  }
                  selected={selectedModel ? models.map((model) => model.id).indexOf(selectedModel.id) : -1}
                  deleteFn={(idx) => database.deleteModel(models[idx])}
                />
              </Collapser>
            </a>
          </>
        }
        sidebarEnd={
          <div id="predict-2">
            <Button fullWidth={true} disabled={false} onClick={next}>
              Next
            </Button>
          </div>
        }
        mainPanel={
          <a id="predict-1">
            {selectedModel ? (
              <Panel>
                <ModelDisplay model={selectedModel} />{" "}
              </Panel>
            ) : null}
          </a>
        }
      />
    </>
  );
}
