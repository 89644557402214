import database, { api } from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import Collapser from "components/atoms/Collapser/Collapser";
import FileUpload from "components/molecules/FileUpload/FileUpload";
import Navbar from "components/organisms/Navbar/Navbar";
import { useState, ReactNode } from "react";
import { Field, Form, Formik } from "formik";
import Traceback from "components/atoms/Traceback/Traceback";

export default function UploadModel() {
  const [file, setFile] = useState<File | null>(null);

  const [nextButtonText, setNextButtonText] = useState("Upload Model");

  const [traceback, setTraceback] = useState<ReactNode | null>(null);

  const uploadModel = async (submitValues: any) => {
    setTraceback(null);
    if (file) {
      const model = await database.createModel({
        uid: database.user.uid,
        did: submitValues.did,
        data: { status: "Runnable", name: submitValues.name, description: submitValues.description },
      });
      const save_path = `gs://oloren-ai.appspot.com/${database.user.uid}/models/${model.id}.pkl`;
      setNextButtonText("Uploading Model...");
      await database.uploadToStorage(save_path, file);
      setNextButtonText("Updating Database...");
      database.updateDocument(model, { data: { ...model.content.data, file_path: save_path } });
      setNextButtonText("Processing Model...");
      const response = await api.transformOceModel(model.id);
      setNextButtonText("Next");
      if (response.status === "success") {
        alert("Successfully operated on model! Your model is available: " + model.id);
      } else {
        setTraceback(<Traceback traceback={response.traceback || ""} />);
        await database.deleteFromStorage(save_path);
        await database.deleteDocument(model);
      }
    } else {
      alert("No file uploaded!");
    }
  };

  return (
    <>
      <Navbar />
      <Formik
        initialValues={{ did: "", name: "", description: "" }}
        onSubmit={async (values) => {
          await uploadModel(values);
        }}
      >
        <Form>
          <div className="p-4">
            <Collapser headerName="Upload .oam Model File">
              {file ? (
                `${file.name} has been uploaded!`
              ) : (
                <FileUpload
                  fullHeight={true}
                  handleUpload={(file) => {
                    setFile(file);
                  }}
                  allowedFileTypes={["oam"]}
                />
              )}
            </Collapser>

            <Collapser headerName="Set Model Attributes">
              <div className="space-y-3">
                <p> Note, the dataset id is a mandatory field as any required feature columns are pulled from it.</p>
                <div className="flex flex-row">
                  Dataset ID: <Field className="ml-3 input input-sm grow" name="did" type="text" />
                </div>
                <div className="flex flex-row">
                  Model Name: <Field className="ml-3 input input-sm grow" name="name" type="text" />
                </div>
                <div className="flex flex-row">
                  Description: <Field className="ml-3 input input-sm grow" name="description" type="text" />
                </div>
              </div>
            </Collapser>
            <Button type="submit">{nextButtonText}</Button>
          </div>
        </Form>
      </Formik>
      {traceback}
    </>
  );
}
