import MoleculeDataTable from "../MoleculeDataTable/MoleculeDataTable";
import Spinner from "components/atoms/Spinner/Spinner";
import InvalidMoleculesCorrector from "components/organisms/InvalidMoleculesCorrector/InvalidMoleculesCorrector";

interface DatasetPredictDash {
    invalidMolecules: any,
    handleCorrection: Function;
    predictedValues: any,
    inferStatus: any,
    downloadPredictions: Function,
    state: any,
}

export default function DatasetPredictDash({
    invalidMolecules,
    handleCorrection,
    predictedValues,
    inferStatus,
    downloadPredictions,
    state,
}: DatasetPredictDash) {

    const ToggleDisplay = () => {
        console.log(state.value);
        if (state.matches("InvalidMoleculesCorrector")) {
            return (
                <InvalidMoleculesCorrector
                    invalidMolecules={invalidMolecules}
                    next={handleCorrection}
                    nextButtonText={"Predict"}
                />
            );
        }
        else if (state.matches("LoadingSpinner-Dataset")) {
            return (
                <Spinner  
                    statusStr={inferStatus}
                    background={"bg-neutral"}
                />
            );
        }
        else if (state.matches("MoleculeDataTable")) {
            return (
                <div className='h-4/5'>
                    <MoleculeDataTable
                    table={predictedValues}
                    downloadPredictions={downloadPredictions}
                />
                </div>
            );
        }
        return <div></div>
    }
    return (
        <>
            <ToggleDisplay />
        </>
    );
}