import database from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import Collapser from "components/atoms/Collapser/Collapser";
import Selector from "components/atoms/Selector/Selector";
import Textbox from "components/atoms/Textbox/Textbox";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import FileUpload from "components/molecules/FileUpload/FileUpload";
import InvalidMoleculesCorrector from "components/organisms/InvalidMoleculesCorrector/InvalidMoleculesCorrector";
import MoleculeDataTable from "components/organisms/MoleculeDataTable/MoleculeDataTable";
import { useState } from "react";
import { useBuildUpload } from "./useBuildUpload";
import { useRef, createRef } from "react";

export default function BuildUpload({ uid }: { uid: string }) {
  const userId = database.user.uid;
  const [sidebarComplete, setSidebarComplete] = useState(false);
  const {
    datasets,
    table,
    datasetFields,
    setDatasetFields,
    handleUpload,
    handleRemove,
    clickColumn,
    next,
    selectDataset,
    selectedDataset,
    nextButtonText,
    loading,
    setLoading,
    invalidMolecules,
  } = useBuildUpload({ userId });

  const getColColor = (idx: number) => {
    const colName = table?.columns[idx] || "column doesn't exist";
    if (colName === datasetFields.data.structure_col) return "bg-primary/20";
    if (colName === datasetFields.data.property_col) return "bg-secondary/20";
    else if (datasetFields.data.feature_cols?.includes(colName)) return "bg-accent/20";
    return "";
  };

  const loadingTable = () => {
    return (
      <div className="flex flex-col max-h-full" id="table-note">
        <article className="w-full grow border-2 border-neutral rounded-xl overflow-auto">
          <div>
            <table className="table-auto h-5/6 max-h-5/6">
              <thead className="text-lg w-full bg-neutral rounded p-6 font-bold font-display text-black bg-neutral h-16">
                <tr>
                  <th className="p-4 borderColorTable border animate-pulse rounded" style={{ width: "300px" }}>
                    <div className="h-4 bg-gray-300 rounded col-span-2"></div>
                  </th>
                  {[...Array(4)].map((header, h_idx) => {
                    return (
                      <th
                        key={h_idx}
                        className="p-4 borderColorTable animate-pulse border rounded"
                        style={{ width: "100px" }}
                      >
                        <div className="h-4 bg-gray-300 rounded col-span-2"></div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-base divide-y divide-gray-100">
                {[...Array(5)].map((row, row_idx) => {
                  return (
                    <tr key={row_idx} className="bg-base-100 p-2">
                      <td className="p-4 borderColorTable border h-fit animate-pulse" style={{ width: "250px" }}>
                        <div className="h-4 py-20 bg-gray-300 rounded-box"></div>
                      </td>
                      {[...Array(4)].map((col, col_idx) => {
                        return (
                          <td
                            key={col_idx}
                            className="p-4 borderColorTable border animate-pulse h-fit "
                            style={{ width: "150px" }}
                          >
                            <div className="h-4 py-20 bg-gray-300 rounded-box"></div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </article>
      </div>
    );
  };

  const fileUpload = () => {
    return <FileUpload id="table-note" fullHeight={true} handleUpload={handleUpload} />;
  };

  console.log(invalidMolecules, "<-- Invalid Molecules");

  return (
    <>
      <SidebarLayout
        sidebarPanel={
          <>
            <Collapser
              id="upload-0"
              headerName={
                <h1>
                  Upload CSV or Select Data <i className="text-red-600">*</i>
                </h1>
              }
              helpMessage="Upload a CSV (table file) containing the data you want to use to train a model! Remember to include a header row in your CSV file."
            >
              <FileUpload
                allowedFileTypes={["csv"]}
                fileName={table ? datasetFields.data.name : undefined}
                handleUpload={handleUpload}
                handleRemove={handleRemove}
              />
              <div className="h-3" /> {/* Spacer */}
              <div id="upload-1">
                <Selector
                  options={datasets.map((dataset) => dataset.content.data?.name).filter((x) => x)}
                  selected={[selectedDataset ? datasets.indexOf(selectedDataset, 0) : -1]}
                  selectFn={selectDataset}
                  nullText="No datasets uploaded."
                  selectedClass="bg-black/20"
                  deleteFn={(idx: number) => {
                    if (selectedDataset != null && idx === datasets.indexOf(selectedDataset)) handleRemove();
                    database.deleteDataset(datasets[idx]);
                  }}
                />
              </div>
            </Collapser>

            <Collapser
              id="select-2"
              headerName={
                <h1>
                  Select Structure Column <i className="text-red-600">*</i>
                </h1>
              }
              helpMessage="This should be the column of your file that contains molecules in SMILES format!"
            >
              <Selector
                options={table?.columns}
                selectFn={(idx: number) => clickColumn(idx, "structure_col")}
                selected={[table?.columns.indexOf(datasetFields.data.structure_col || "")]}
                // selectFn={(idx) => clickColumn(idx, "structure")}
                nullText="You must upload or select a dataset to select a structure column."
                selectedClass={"bg-primary/20"}
              />
            </Collapser>

            <Collapser
              id="select-3"
              headerName={
                <h1>
                  Select Property Column <i className="text-red-600">*</i>
                </h1>
              }
              helpMessage="This is the column you're interested in having a model learn to predict."
            >
              <Selector
                options={table?.columns}
                selected={[table?.columns.indexOf(datasetFields.data.property_col || "")]}
                selectFn={(idx: number) => clickColumn(idx, "property_col")}
                nullText="You must upload or select a dataset to select a property column."
                selectedClass={"bg-secondary/20"}
              />
            </Collapser>

            <Collapser
              id="select-4"
              headerName="Select Feature Column(s)"
              helpMessage="If you have any additional features you'd like to provide the model (aside from the molecular structure), select these columns here!"
            >
              <Selector
                options={table?.columns}
                selected={datasetFields.data.feature_cols?.map((col) => table?.columns.indexOf(col))}
                selectFn={(idx: number) => clickColumn(idx, "feature_cols")}
                nullText="You must upload or select a dataset to select feature columns."
                selectedClass={"bg-accent/20"}
              />
            </Collapser>

            <Collapser
              id="select-5"
              headerName="Name the Dataset"
              helpMessage="Make the name descriptive so you can find this dataset later!"
            >
              {table ? (
                <Textbox
                  setText={(name: string) =>
                    setDatasetFields({
                      ...datasetFields,
                      data: { ...datasetFields.data, name: name },
                    })
                  }
                  text={datasetFields.data?.name || ""}
                  label={null}
                  ref={null}
                  altLabel={null}
                />
              ) : (
                <Selector nullText={"You must upload or select a dataset to name it."} />
              )}
            </Collapser>
          </>
        }
        sidebarEnd={
          <Button
            id="end-build"
            fullWidth={true}
            disabled={
              datasetFields.data.structure_col &&
              datasetFields.data.property_col &&
              nextButtonText === "Next" &&
              !(invalidMolecules.length > 0)
                ? false
                : true
            }
            onClick={() => {
              next();
            }}
          >
            {invalidMolecules.length > 0 ? "Next" : nextButtonText}
          </Button>
        }
        mainPanel={
          loading ? (
            loadingTable()
          ) : invalidMolecules.length > 0 ? (
            <InvalidMoleculesCorrector
              invalidMolecules={invalidMolecules}
              next={next}
              nextButtonText={nextButtonText}
              id="table-note"
            />
          ) : table ? (
            <MoleculeDataTable
              id="table-note"
              table={table}
              onSelectCol={clickColumn}
              getColColor={getColColor}
              smilesCol={table.columns.indexOf(datasetFields.data.structure_col || "")}
            />
          ) : (
            <span id="table-note">{fileUpload()}</span>
          )
        }
      />
      );
    </>
  );
}
