import { CookiesProvider } from "react-cookie";
import "./index.css";
import "./tutorial.css";

import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import App from "App";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import { setChonkyDefaults } from "chonky";


setChonkyDefaults({ iconComponent: ChonkyIconFA });

const container = document.getElementById("root");
const root = createRoot(container!); // if you use TypeScript
root.render(
  
  <CookiesProvider>
    <StrictMode>
        <App />
    </StrictMode>
  </CookiesProvider>
);
