import ClipLoader from "react-spinners/ClipLoader";



export default function Spinner({ statusStr, background='bg-white' }:{ statusStr: string, background?: string }) {
  return (
    <div className={`w-full h-full ${background} grid text-center place-items-center`}>
      <div>
        <ClipLoader color={"blue"} loading={true} size={150} />
        <p>{ statusStr } </p>
      </div>
    </div>
  );

}