import Papa from "papaparse";
import { initRDKit } from "util/Molecules";

Papa.parsePromise = function (file, config) {
  return new Promise(function (complete, error) {
    Papa.parse(file, { ...config, complete, error });
  });
};

export async function validateMolecules(file, structureColumn) {
  await initRDKit();
  let invalidMolecules = [];
  await Papa.parsePromise(file, {
    header: true,
    step: function (row) {
      const smiles = row.data[structureColumn];
      if (smiles) {
        const mol = window.RDKit.get_mol(smiles);
        if (!mol.is_valid()) {
          invalidMolecules.push(smiles);
        }
        mol.delete();
      }
    },
  });
  return invalidMolecules;
}

/**
 * Checks if an input string is a valid smiles input
 *
 * @param smiles a smiles string to be checked
 * @returns true if smiles is a valid smiles string
 */
export async function validateMolecule(smiles) {
  await initRDKit();
  const mol = window.RDKit.get_mol(smiles);
  console.log(mol);

  return mol.is_valid();
}
