import { changeListener, getPath } from "api/Firebase";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModelDB } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";

/** Provides backend logic for BuildCreate page.
 * @param did - Dataset on which models will be trained.
 */
export default function useBuildCreate(did: string) {
  const navigate = useNavigate();

  if (!did) {
    navigate("/build/upload");
  }

  // Get all models, including their metrics and other data.
  const [models, setModels] = useState<ModelDB[]>([]);
  useEffect(() => {
    return database.getLive(database.queryDatasetModels(did), setModels);
  }, [did]);

  const [selectedModel, setSelectedModel] = useState<ModelDB | null>(null);

  const next = async () => {
    navigate(`/build/save/?did=${did}`);
  };

  // Listen for changes on all models
  return {
    models,
    selectedModel,
    setSelectedModel,
    next,
  };
}
