import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import logoImg from "images/logo.svg";
import { useAuth } from "contexts/AuthContext";

export default function Signup() {
  // const admin = require("firebase-admin");
  // const serviceAccount = require

  const { signup, currentUser } = useAuth();
  // console.log('user', currentUser);
  // const admin = "2UZqtgtCfyUky3xGQ2NPZDPTfMs1";
  // database.authenticate(admin);
  // console.log(database.user);
  const [loading, setLoading] = useState(false);

  const [errorOnSignupMessage, setErrorOnSignupMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      organization: "",
      password: "",
      confirmPass: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true);
      signup(values.email, values.password, values.confirmPass, setErrorOnSignupMessage);
      setLoading(false);
    },
  });

  useEffect(() => {
    const orgCheckbox: HTMLInputElement = document.getElementById("orgCheckbox") as HTMLInputElement;
    const orgDiv = document.getElementById("orgDiv");
    // console.log("Adding event listener", orgCheckbox);
    if (orgCheckbox !== null) {
      orgCheckbox.addEventListener("click", () => {
        console.log(orgCheckbox.checked);
        if (orgCheckbox.checked) {
          orgDiv!.style.display = "grid";
        } else {
          orgDiv!.style.display = "none";
        }
      });
    }
  });

  return (
    <>
      <section className="w-full bgGradient h-screen flex items-center justify-center flex-col">
        <img src={logoImg} alt="logo" className="mb-8 h-20 sm:h-24" />
        <article className="bg-white py-8 px-8 rounded-xl w-11/12 xl:w-3/12 lg:2-4/12 sm:w-6/12">
          <h2 className="my-4 text-2xl font-semibold text-gray-700">Sign Up</h2>
          <form onSubmit={formik.handleSubmit}>
            <div id="emailDiv" className="grid">
              <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                required
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 mb-4"
              />
            </div>
            <div id="orgDiv" className="hidden">
              <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="orgaization">
                Organization
              </label>
              <input
                type="text"
                id="organization"
                name="organization"
                onChange={formik.handleChange}
                value={formik.values.organization}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 mb-4"
              />
            </div>
            <label className="align-items-left text-sm font-semibold text-gray-500 py-1.5">
              <input id="orgCheckbox" type="checkbox" className="checkbox-xxs" /> Part of an organization?
            </label>

            <div id="divider" className="divider m-2"></div>
            <div id="passDiv" className="grid">
              <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                required
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 mb-4"
              />
            </div>
            <div id="confirmPassDiv" className="grid">
              <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="confirmPass">
                Confirm Password
              </label>
              <input
                type="password"
                required
                id="confirmPass"
                name="confirmPass"
                onChange={formik.handleChange}
                value={formik.values.confirmPass}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 mb-4"
              />
            </div>
            {errorOnSignupMessage && (
              <div id="errorSignupMessage" className="text-sm font-semibold text-red-700 py-1.5 mb-4">
                {errorOnSignupMessage}
              </div>
            )}
            <button
              type="submit"
              disabled={loading}
              className="w-full px-4 my-2 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-700 rounded-md shadow hover:bg-blue-400 focus:outline-none focus:ring-blue-200 focus:ring-4"
            >
              Sign Up
            </button>
          </form>
          <div id="alreadyHaveAcc" className="font-semibold text-gray-500 w-100 text-center mt-5">
            Already have an account?{" "}
            <Link to="/login" className="text-base text-blue-600 hover:underline focus:text-blue-800">
              Log In
            </Link>
          </div>
        </article>
      </section>
    </>
  );
}
