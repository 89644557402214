import React from "react";
import { FileUploader } from "react-drag-drop-files";
import Button from "components/atoms/Button/Button";
import classnames from "classnames";

interface FileUploadProps {
  handleUpload: (file: File) => void;
  handleRemove?: () => void;
  allowedFileTypes?: string[];
  fileName?: string;
  fullHeight?: boolean;
  id?: string;
}

export default function FileUpload({
  handleUpload,
  handleRemove,
  allowedFileTypes = ["csv"],
  fileName,
  fullHeight = false,
  id,
}: FileUploadProps) {
  const className = classnames(
    "flex items-center border-primary border-dashed border-4 text-sm lg:text-base mx-auto justify-center",
    fullHeight ? "h-full flex-col" : "h-14"
  );

  const getFileName = (str: any) => {
    const MAX_LENGTH = 25;
      if (str.length >= MAX_LENGTH) {
        let adjusted = str.substring(0, MAX_LENGTH) + "...";
        return adjusted;
      }
      return str;
  };

  return fileName !== null && fileName !== undefined ? (
    <div className={className}>
      <label className="mr-4">{getFileName(fileName)}</label>
      <Button fullWidth={false} onClick={handleRemove}>
        Remove
      </Button>
    </div>
  ) : (
    <FileUploader
      id = {id}
      handleChange={handleUpload}
      // onDrop={handleUpload}
      // onDraggingStateChange={handleUpload}
      name="file"
      types={allowedFileTypes}
      classes={className}
    >
      <label className="">Drag and drop file here or</label>
      <Button fullWidth={false}>Browse</Button>
    </FileUploader>
  );
}
