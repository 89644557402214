import React from "react";

export default function NoVisSelected() {
  return (
    <div className="flex-grow grid h-full w-full place-items-center bg-base-100  border-primary border-dashed border-4 mx-auto ">
      <div className="flex w-25">
        <div className="text-sm text-center justify-center">
          {" "}
          Begin your visualization by selecting datasets and/or models.
        </div>
      </div>
    </div>
  );
}
/*style=
{{
width:'auto',
height:'auto',
left:'17px',
top:'53px',
boxSizing:'border-box',
border:'4px dashed #0A58EE'
}} */
