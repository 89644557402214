import Error from "components/atoms/Error/Error";
import Navbar from "components/organisms/Navbar/Navbar";
import { useGlobalContext } from "contexts/GlobalContext";
import React from "react";
import ErrorModal from "components/atoms/ErrorModal/ErrorModal";
import { useError, useErrorUpdate } from "contexts/ErrorContext";

interface SidebarLayoutProps {
  sidebarPanel?: React.ReactNode;
  mainPanel?: React.ReactNode;
  sidebarEnd?: React.ReactNode;
  tutorialSteps?: React.ReactNode;
}

export default function SidebarLayout({
  sidebarPanel = null,
  mainPanel = null,
  sidebarEnd = null,
}: SidebarLayoutProps) {
  


  return (
    <>
      <section className="h-screen w-screen relative">
        <Navbar />
        <section className="flex flex-col h-5/6 w-11/12 mx-auto min-h-0 max-h-5/6">
          <section className="w-full mx-auto flex-auto flex justify-between items-start mt-8 min-h-0">
            <section className="w-4/12 h-full flex flex-col min-h-0">
              <section className="flex-initial flex-col content-end min-w-0 min-h-0 overflow-x-hidden overflow-y-auto shrink pr-4">
                {sidebarPanel}
              </section>
              <section className={"flex-initial" + sidebarEnd ? " mt-2 pr-4" : ""}>{sidebarEnd}</section>
            </section>
            <section className="w-[65%] block h-full max-h-full">{mainPanel}</section>
          </section>
        </section>
      </section>
    </>
  );
}
