import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, useSearchParams } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { FcGoogle } from "react-icons/fc";
import logoImg from "images/logo.svg";

export default function Login() {
  const { logInWithGoogle, login } = useAuth();
  const [errorOnLoginMessage, setErrorOnLoginMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      setLoading(true);
      login(values.email, values.password, setErrorOnLoginMessage, redirectTo);
      setLoading(false);
    },
  });

  let [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  return (
    <>
      <section className="w-full bgGradient h-screen flex items-center justify-center flex-col">
        <img src={logoImg} alt="logo" className="mb-8 h-20 sm:h-24" />
        <article className="bg-white py-8 px-8 rounded-xl w-11/12 xl:w-3/12 lg:w-4/12 sm:w-6/12">
          <h2 className="my-4 text-2xl font-semibold text-gray-700">Log In</h2>
          <form onSubmit={formik.handleSubmit}>
            <div id="emailDiv" className="grid">
              <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                required
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 mb-4"
              />
            </div>
            <div id="passDiv" className="grid">
              <div id="passLabels" className="flex items-center justify-between">
                <label className="text-sm font-semibold text-gray-500 py-1.5" htmlFor="password">
                  Password
                </label>
                <Link to="/forgot-password" className="text-sm text-blue-600 hover:underline focus:text-blue-800">
                  Forgot Password?
                </Link>
              </div>
              <input
                type="password"
                required
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200 mb-4"
              />
            </div>
            {errorOnLoginMessage && (
              <div id="errorLoginMessage" className="text-sm font-semibold text-red-700 py-1.5 mb-4">
                {errorOnLoginMessage}
              </div>
            )}
            <button
              type="submit"
              disabled={loading}
              className="w-full px-4 my-2 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-700 rounded-md shadow hover:bg-blue-400 focus:outline-none focus:ring-blue-200 focus:ring-4"
            >
              Log In
            </button>
          </form>

          <span className="flex items-center justify-center space-x-2 my-2 ">
            <span className="h-px bg-gray-400 w-14"></span>
            <span className="font-normal text-gray-500">or login with</span>
            <span className="h-px bg-gray-400 w-14"></span>
          </span>
          <button
            onClick={() => {
              logInWithGoogle(redirectTo);
            }}
            className="flex items-center justify-center w-full px-4 py-2 my-4 space-x-2 transition-colors duration-300 border border-blue-800 rounded-md group hover:bg-blue-600 focus:outline-none  focus:border-blue-400"
          >
            <FcGoogle className="w-5 h-5 text-gray-800 fill-current group-hover:text-white" />
            <span className="text-sm font-medium text-gray-800 group-hover:text-white">Google</span>
          </button>

          <div id="needAccount" className="font-semibold text-gray-500 w-100 text-right mt-5">
            New to Oloren.AI?{" "}
            <Link to="/signup" className="text-base text-blue-600 hover:underline focus:text-blue-800">
              Sign Up
            </Link>
          </div>
        </article>
      </section>
    </>
  );
}
