import { VisualizeContext } from "components/pages/Visualize/VisualizeContext";
import React, { useContext, useEffect } from "react";
import { BsChevronRight } from "react-icons/bs";

export default function TriggerSelector({ attribute }: { attribute: any }) {
  const { selectorAttributes, setSelectorAttributes, setSelecting } = useContext(VisualizeContext);

  useEffect(() => {
    // Add the attribute to the selectorAttributes if it is not already there
    if (setSelectorAttributes && !selectorAttributes) setSelectorAttributes({ [attribute[0]]: null });
    else if (setSelectorAttributes && !(attribute[0] in selectorAttributes))
      setSelectorAttributes({ ...selectorAttributes, [attribute[0]]: null });
  }, [selectorAttributes, setSelectorAttributes, attribute]);

  return (
    <button
      className="w-full btn btn-sm normal-case text-md font-body font-normal bg-base-100 flex justify-between"
      onClick={() => {
        if (setSelecting) setSelecting(attribute);
      }}
      type="button"
    >
      {selectorAttributes && attribute[0] in selectorAttributes && selectorAttributes[attribute[0]]?.content?.data?.name
        ? selectorAttributes[attribute[0]].content.data.name
        : "Click to select"}
      <label className="pl-2 text-bold">
        <BsChevronRight />
      </label>
    </button>
  );
}
