import { createContext } from "react";

interface VisualizeContextData {
  selectorAttributes?: any;
  setSelectorAttributes?: (attributes: any) => void;
  selecting?: any;
  setSelecting?: (selecting: any) => void;
}

export const VisualizeContext = createContext<VisualizeContextData>({});
