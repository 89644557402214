import Papa from "papaparse";
import { validateSMILES } from "util/Molecules";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export interface Table {
  columns: string[];
  rows: string[][];
}

const parsePromise = function (file: Blob | string, config: any) {
  return new Promise(function (complete, error) {
    Papa.parse(file, { ...config, complete, error });
  });
};

export async function getStructureFromTable(table: Table): Promise<any> {
  const ROW_LENGTH = table.rows.length;
  const VALIDATION_LIMIT = Math.min(5, table.rows.length - 1);
  const SUCCESS_LENGTH = 3;

  for (let row = 0; row < ROW_LENGTH; row++) {
    let valid_smiles = [];
    for (let col = 1; col <= VALIDATION_LIMIT; col++) {
      const random_col_idx = getRandomInt(table.rows.length);
      const current_value = table.rows[random_col_idx][row];
      const valid: boolean = (await validateSMILES(current_value)) as any as boolean;

      if (valid) {
        valid_smiles.push(current_value);
      }
    }
    if (valid_smiles.length >= SUCCESS_LENGTH) {
      console.log(row, "<==== SMILES INDEX");
      return row;
    }
  }
  return "No structure column found.";
}

export async function csvToTable(csvFile: Blob | string, cap: number | undefined = 50): Promise<Table | null> {
  // @ts-ignore
  const rawTable: string[][] = (await parsePromise(csvFile, { preview: cap, download: typeof csvFile === "string" }))[
    "data"
  ];

  let headerValidSmiles = await Promise.all(rawTable[0].map(async (x) => await validateSMILES(x)));

  if (headerValidSmiles.some((x) => x)) {
    console.log(rawTable[0]);
    console.log(rawTable[0].map(async (x) => await validateSMILES(x)));
    alert(
      "Your uploaded CSV file contains a SMILES string in the header row. This is likely because the uploaded file " +
        "does not include a header row. If so, please add a header row to your CSV file and try again."
    );
    return null;
  }

  return { columns: rawTable[0], rows: rawTable.slice(1) };
}

export function transposeRows(rows: string[][]): string[][] {
  return rows[0].map((col, i) => rows.map((row) => row[i]));
}

export function concatAlongColumns(tables: Table[]): Table {
  const transposedRows = tables.map((table) => transposeRows(table.rows));
  return {
    rows: transposeRows(transposedRows.reduce((accumulator, value) => accumulator.concat(value), [])),
    columns: tables.map((table) => table.columns).reduce((accumulator, value) => accumulator.concat(value), []),
  };
}
