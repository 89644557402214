export default function StaticTextboxLabel({
  name,
  content,
  className = "flex flex-col gap-0 w-full",
}: {
  name: string;
  content: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <label htmlFor="model-name" className="label">
        <span className="label-text">{name}</span>
      </label>
      <div id="model-name" data-testid="model-description" className="bg-white rounded-lg p-2 ">
        {content}
      </div>
    </div>
  );
}
