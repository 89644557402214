import Button from "components/atoms/Button/Button";
import ErrorIcon from "components/atoms/ErrorIcon/ErrorIcon";
import HelpIcon from "components/atoms/HelpIcon/HelpIcon";
import Panel from "components/atoms/Panel/Panel";
import React, { useState } from "react";
import { BiCheckCircle, BiTrash } from "react-icons/bi";
import { MdRestore } from "react-icons/md";
import { validateSMILES } from "util/Molecules";

export default function InvalidMoleculesCorrector({
  invalidMolecules,
  next,
  nextButtonText = "Next",
  id,
}: {
  invalidMolecules: string[];
  next: Function;
  nextButtonText?: string;
  id?: string;
}) {
  const [deletedMolecules, setDeletedMolecules] = useState<string[]>([]);
  const [updatedMolecules, setUpdatedMolecules] = useState<string[]>(invalidMolecules);
  const [validatedMolecules, setValidatedMolecules] = useState<string[]>([]);

  return (
    <Panel id={id}>
      <div className="flex justify-between -mt-3 items-center">
        <h1 className="text-xl font-display font-bold">Remove or Fix Invalid Molecules</h1>
        <Button
          fullWidth={false}
          onClick={() => {
            setDeletedMolecules(invalidMolecules);
          }}
        >
          {" "}
          Delete All{" "}
        </Button>
      </div>
      {updatedMolecules.map((molecule, m_idx) => (
        <div className="my-2 flex items-center" key={m_idx}>
          <input
            type="text"
            value={molecule}
            onChange={(event) => {
              setUpdatedMolecules(updatedMolecules.map((m, i) => (i === m_idx ? event.target.value : m)));
              validateSMILES(event.target.value).then((valid) => {
                if (valid) setValidatedMolecules([...validatedMolecules, event.target.value]);
              });
            }}
            className="input flex-grow input-sm w-2/3"
            disabled={deletedMolecules.includes(molecule)}
          />
          {validatedMolecules.includes(molecule) ? (
            <BiCheckCircle className="ml-1 text-success" size={30} />
          ) : (
            <ErrorIcon
              className={"m-0 ml-1 "}
              disabled={deletedMolecules.includes(molecule)}
              message={"We will provide better messages in the future, but for now check the valences."}
            />
          )}
          <Button
            fullWidth={false}
            onClick={() => {
              if (deletedMolecules.includes(invalidMolecules[m_idx]))
                setDeletedMolecules(deletedMolecules.filter((m) => m !== invalidMolecules[m_idx]));
              else setDeletedMolecules([...deletedMolecules, invalidMolecules[m_idx]]);
            }}
          >
            {deletedMolecules.includes(molecule) ? <MdRestore /> : <BiTrash />}
          </Button>
        </div>
      ))}
      <Button
        onClick={() => {
          const good = updatedMolecules.every(
            (mol, idx) => deletedMolecules.includes(invalidMolecules[idx]) || validatedMolecules.includes(mol)
          );

          if (!good) {
            alert("You have more work to do!");
          } else {
            var updateMap: { [key: string]: string } = {};
            updatedMolecules.forEach((updatedMol, i) => {
              if (!deletedMolecules.includes(invalidMolecules[i])) {
                updateMap[invalidMolecules[i]] = updatedMol;
              }
            });
            next(deletedMolecules, updateMap);
          }
        }}
      >
        {nextButtonText}
      </Button>
    </Panel>
  );
}
