import { changeListener, deleteFromBucket, deletePath, getAllMatchingChild, removeListener } from "api/Firebase";
import axios from "axios";
import { useEffect, useState } from "react";

export async function validateDataset(userId, datasetId, infer = false) {
  let res;
  try {
    console.log("Sending request!");
    console.log(`https://api.oloren.ai/validate_dataset/?user_id=${userId}&dataset_id=${datasetId}&infer=${infer}`);
    res = await axios(
      `https://api.oloren.ai/validate_dataset/?user_id=${userId}&dataset_id=${datasetId}&infer=${infer}`
    );
    console.log("Request finished");
  } catch (e) {
    console.log("Request failed");
    console.log(e);
    return e.toString();
  }
  console.log(res);
  if (res.status === 200 && res.data["status"] === "success") {
    return "success";
  } else {
    return res.data["message"];
  }
}

export function useModels(userId, datasetId) {
  const [modelSelected, setModelSelected] = useState(false);
  const [models, setModels] = useState({});
  const [selectedModel, setSelectedModel] = useState(-1);
  const modelId = selectedModel !== -1 ? Object.keys(models)[selectedModel] : null;

  const modelTable = Object.values(models).map((model) => [
    model.name ? model.name : "Error: Unknown Model Name",
    model.status ? model.status : "Loading...",
  ]);

  useEffect(() => {
    let [child, childVal] = ["dataset_id", datasetId];
    if (!datasetId) {
      [child, childVal] = ["user_id", userId];
    }
    getAllMatchingChild("models", child, childVal).then((result) => {
      if (result) {
        setModels(result);
        Object.keys(result).forEach((modelId) => {
          changeListener(`models/${modelId}`, (modelData) => {
            setModels((m) => ({ ...m, [modelId]: modelData }));
          });
        });
      }
    });
  }, [datasetId, userId]);

  const selectModel = (idx) => {
    if (selectedModel === idx) {
      setSelectedModel(-1);
      setModelSelected(false);
    } else {
      setSelectedModel(idx);
      setModelSelected(true);
    }
  };

  const deleteModel = async (idx) => {
    const modelId = Object.keys(models)[idx];
    deleteFromBucket(Object.values(models)[idx]["save_path"]);
    removeListener(`models/${modelId}`);
    deletePath(`models/${modelId}`);
    setModels(
      Object.keys(models)
        .filter((m) => m !== modelId) // Select remaining model ids
        .reduce((o, key) => ({ ...o, [key]: models[key] }), {}) // Get those models to construct the object
    );
  };

  // TODO: Figure out how to remove listeners only when the entire page is being unmounted

  return {
    models,
    setModels,
    modelSelected,
    modelTable,
    selectModel,
    selectedModel,
    modelId,
    deleteModel,
  };
}

export async function getCompatibleVisualizations() {
  let res;
  try {
    res = await axios("https://api.oloren.ai/get_visualizations");
    return res.data["data"];
  } catch (e) {
    console.log(e);
    return e.toString();
  }
}

export async function getAttributes(visualName) {
  let res;
  try {
    res = await axios(`https://api.oloren.ai/get_attributes?visualization_name=${visualName}`);
    return res.data["data"];
  } catch (e) {
    console.log(e);
    return e.toString();
  }
}
