import Button from "components/atoms/Button/Button";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { HiClipboardCopy } from "react-icons/hi";

export default function CopyTextButton({ label, text }: { label: string; text: string }) {
  return (
    <div className="flex flex-row w-full px-4 mb-4">
      <div className="flex items-center whitespace-nowrap">{label}</div>
      <input type="text" value={text} className="input w-full grow" readOnly />
      <CopyToClipboard text={text}>
        <Button fullWidth={false} fullHeight={true} id="copy-link">
          <HiClipboardCopy className="text-4xl h-full py-1" />
        </Button>
      </CopyToClipboard>
    </div>
  );
}
