import database from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import Collapser from "components/atoms/Collapser/Collapser";
import Selector from "components/atoms/Selector/Selector";
import Textbox from "components/atoms/Textbox/Textbox";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import FileUpload from "components/molecules/FileUpload/FileUpload";
import MoleculeDataTable from "components/organisms/MoleculeDataTable/MoleculeDataTable";
import { usePredictUpload } from "./usePredictUpload";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import InvalidMoleculesCorrector from "components/organisms/InvalidMoleculesCorrector/InvalidMoleculesCorrector";
import MoleculePredictDash from "components/organisms/MoleculePredictDash/MoleculePredictDash";

export default function PredictUpload() {
  let [searchParams] = useSearchParams();
  const mid = searchParams.get("mid");

  const navigate = useNavigate();

  useEffect(() => {
    if (!mid) {
      navigate(`/predict/select`);
    } else {
      navigate(`/endpoint/?mid=${mid}`);
    }

    
  }, [mid])
  

  /*
  const {
    invalidMolecules,
    smilesInputLoading,
    datasets,
    table,
    datasetFields,
    setDatasetFields,
    handleUpload,
    handleRemove,
    clickColumn,
    next,
    selectDataset,
    selectedDataset,
    nextButtonText,
    model,
    loading,
    userInput,
    setUserInput,
    userInputPrediction,
    isValidSmilesStr,
    errorHist,
    confidenceInterval,
    awaitInference,
    handlePaste,
    setNextButtonText
  } = usePredictUpload(mid);

  const getColColor = (idx: number) => {
    const colName = table?.columns[idx] || "column doesn't exist";
    if (colName === datasetFields.data.structure_col) return "bg-primary/20";
    if (colName === datasetFields.data.property_col) return "bg-secondary/20";
    else if (datasetFields.data.feature_cols?.includes(colName)) return "bg-accent/20";
    return "";
  };

  const loadingTable = () => {
    return (
      <div className="flex flex-col max-h-full">
        <article className="w-full grow border-2 border-neutral rounded-xl overflow-auto">
          <div>
            <table className="table-auto h-5/6 max-h-5/6">
              <thead className="text-lg w-full bg-neutral rounded p-6 font-bold font-display text-black bg-neutral h-16">
                <tr>
                  <th className="p-4 borderColorTable border animate-pulse rounded" style={{ width: "300px" }}>
                    <div className="h-4 bg-gray-300 rounded col-span-2"></div>
                  </th>
                  {[...Array(4)].map((header, h_idx) => {
                    return (
                      <th
                        key={h_idx}
                        className="p-4 borderColorTable animate-pulse border rounded"
                        style={{ width: "100px" }}
                      >
                        <div className="h-4 bg-gray-300 rounded col-span-2"></div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-base divide-y divide-gray-100">
                {[...Array(5)].map((row, row_idx) => {
                  return (
                    <tr key={row_idx} className="bg-base-100 p-2">
                      <td className="p-4 borderColorTable border h-fit animate-pulse" style={{ width: "250px" }}>
                        <div className="h-4 py-20 bg-gray-300 rounded-box"></div>
                      </td>
                      {[...Array(4)].map((col, col_idx) => {
                        return (
                          <td
                            key={col_idx}
                            className="p-4 borderColorTable border animate-pulse h-fit "
                            style={{ width: "150px" }}
                          >
                            <div className="h-4 py-20 bg-gray-300 rounded-box"></div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </article>
      </div>
    );
  };

  const fileUpload = () => {
    return <FileUpload fullHeight={true} handleUpload={handleUpload} />;
  };

  // console.log(datasetFields.data.structure_col, "<-- structure");
  */
  // return (
  //   <>
  //     <SidebarLayout
  //       sidebarPanel={
  //         <>
  //           {/** Add the selected model properties at the top here */}
  //           <Collapser headerName="Model Metadata">
  //             <div className="form-control space-y-2">
  //               {/* <Metrics metrics={model?.content.data.metrics} /> */}
  //               <div className="flex flex-col space-y-2">
  //                 <div>
  //                   <label htmlFor="model-name" className="label">
  //                     <span className="label-text">Model Name</span>
  //                   </label>
  //                   <div id="model-name" className="bg-white rounded-lg p-2 ">
  //                     {model?.content.data.name}
  //                   </div>
  //                 </div>
  //                 <div>
  //                   <label htmlFor="model-desc" className="label">
  //                     <span className="label-text">Model Description</span>
  //                   </label>
  //                   <div id="model-desc" className="bg-white rounded-lg p-2" data-testid="model-description">
  //                     {model?.content.data.description}
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </Collapser>

  //           <Collapser headerName="Predict with a quick input">
  //             <div>
  //               <label className="label">
  //                 <span className="label-text">Enter your own SMILES string:</span>
  //               </label>
  //             </div>
  //             <div id="upload-2">
  //               <Textbox
  //                 text={userInput}
  //                 setText={(input: string) => {
  //                   setUserInput(input);
  //                 }}
  //                 noText={"Enter your own SMILES string here"}
  //                 ref={null}
  //                 label={null}
  //                 altLabel={null}
  //               />
  //             </div>
  //           </Collapser>

  //           <Collapser headerName="Predict with a data file">
  //             <Collapser
  //               headerName={
  //                 <h1>
  //                   Upload CSV or Select Data <i className="text-red-600">*</i>
  //                 </h1>
  //               }
  //               helpMessage="Upload a CSV (table file) containing the data you want to use to train a model! Remember to include a header row in your CSV file. One column of the CSV must be of SMILES strings."
  //               color="bg-neutral-focus/40"
  //             >
  //               <div id="upload-0">
  //                 <FileUpload
  //                   allowedFileTypes={["csv"]}
  //                   fileName={table ? datasetFields.data.name : undefined}
  //                   handleUpload={handleUpload}
  //                   handleRemove={handleRemove}
  //                 />
  //               </div>
  //               <div className="h-3" /> {/* Spacer */}
  //               <div id="upload-1">
  //                 <Selector
  //                   options={datasets.map((dataset) => dataset.content.data?.name).filter((x) => x)}
  //                   selected={[selectedDataset ? datasets.indexOf(selectedDataset, 0) : -1]}
  //                   selectFn={selectDataset}
  //                   nullText="No datasets uploaded."
  //                   selectedClass="bg-black/20"
  //                   deleteFn={(idx: number) => {
  //                     if (selectedDataset != null && idx === datasets.indexOf(selectedDataset)) handleRemove();
  //                     database.deleteDataset(datasets[idx]);
  //                   }}
  //                 />
  //               </div>
  //             </Collapser>

  //             <div id="select-2">
  //               <Collapser
  //                 headerName={
  //                   <h1>
  //                     Select Structure Column <i className="text-red-600">*</i>
  //                   </h1>
  //                 }
  //                 helpMessage="This should be the column of your file that contains molecules in SMILES format!"
  //                 color="bg-neutral-focus/40"
  //               >
  //                 <Selector
  //                   options={table?.columns}
  //                   selectFn={(idx: number) => clickColumn(idx, "structure_col")}
  //                   selected={[table?.columns.indexOf(datasetFields.data.structure_col || "")]}
  //                   // selectFn={(idx) => clickColumn(idx, "structure")}
  //                   nullText="You must upload or select a dataset to select a structure column."
  //                   selectedClass={"bg-primary/20"}
  //                 />
  //               </Collapser>
  //             </div>

  //             {/* <Collapser
  //               headerName="(Optional) Select Property Column"
  //               helpMessage="If you want to see model metrics based on ground truth values, select the ground truth column here."
  //             >
  //               <Selector
  //                 options={table?.columns}
  //                 selected={datasetFields.data.feature_cols?.map((col) => table?.columns.indexOf(col))}
  //                 selectFn={(idx: number) => clickColumn(idx, "feature_cols")}
  //                 nullText="You must upload or select a dataset to select feature columns."
  //                 selectedClass={"bg-accent/20"}
  //               />
  //             </Collapser> */}
  //             <Collapser
  //               headerName="Name the Dataset"
  //               helpMessage="Make the name descriptive so you can find this dataset later!"
  //               color="bg-neutral-focus/40"
  //             >
  //               {table ? (
  //                 <Textbox
  //                   setText={(name: string) =>
  //                     setDatasetFields({
  //                       ...datasetFields,
  //                       data: { ...datasetFields.data, name: name },
  //                     })
  //                   }
  //                   text={datasetFields.data?.name || ""}
  //                   label={null}
  //                   ref={null}
  //                   altLabel={null}
  //                 />
  //               ) : (
  //                 <Selector nullText={"You must upload or select a dataset to name it."} />
  //               )}
  //             </Collapser>
  //           </Collapser>
  //         </>
  //       }
  //       sidebarEnd={
  //         <Button
  //           fullWidth={true}
  //           disabled={
  //             smilesInputLoading 
  //               ? false 
  //               : datasetFields.data.structure_col && nextButtonText === "Next" && !(invalidMolecules.length > 0)
  //               ? false
  //               : true
  //           }
  //           onClick={() => {
  //             next();
  //           }}
  //         >
  //           {userInput ? "Predict" : invalidMolecules.length > 0 ? "Next" : nextButtonText}
  //         </Button>
  //       }
  //       mainPanel={
  //         loading ? (
  //           loadingTable()
  //         ) :
          
  //         userInput ? (
  //           <MoleculePredictDash
  //             id="end-build"
  //             userInput={userInput}
  //             confidenceInterval={confidenceInterval}
  //             errorHist={errorHist}
  //             userInputPrediction={userInputPrediction}
  //             loading={smilesInputLoading}
  //             next={()=>{}}
  //             nextButtonText={"Disabled for dev purposes"}
  //             awaitInference={false}
  //           />
            
  //         ) : invalidMolecules.length > 0 ? (
  //           <InvalidMoleculesCorrector
  //             invalidMolecules={invalidMolecules}
  //             next={next}
  //             nextButtonText={nextButtonText}
  //           />
  //         ) : table ? (
  //           <MoleculeDataTable
  //             table={table}
  //             onSelectCol={clickColumn}
  //             getColColor={getColColor}
  //             smilesCol={table.columns.indexOf(datasetFields.data.structure_col || "")}
  //           />
  //         ) : (
  //           fileUpload()
  //         )
  //       }
  //     />
  //   </>
  // );
  //
  return (
    null
  );
}
