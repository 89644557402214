



export const initRDKit = (() => {
  let rdkitLoadingPromise: any;
  console.log('outside RDKit return statement');
  
  return () => {
    /**
     * Utility function ensuring there's only one call made to load RDKit
     * It returns a promise with the resolved RDKit API as value on success,
     * and a rejected promise with the error on failure.
     *
     * The RDKit API is also attached to the global object on successful load.
     */
    if (!rdkitLoadingPromise) {
      console.log('inside of RDKit return statement');
      
      rdkitLoadingPromise = new Promise((resolve, reject) => {
        window
          .initRDKitModule()
          .then((RDKit: any) => {
            window.RDKit = RDKit;
            resolve(RDKit);
          })
          .catch(() => {
            reject();
          });
      });
    }

    return rdkitLoadingPromise;
  };
})();

export async function validateSMILES(smiles: string) {
  await initRDKit();
  const mol = window.RDKit.get_mol(smiles);
  const validity = mol.is_valid();
  mol.delete();
  return validity;
}