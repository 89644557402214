import { useState, useEffect } from 'react';
import Spinner from 'components/pages/Spinner/Spinner';

export default function SpinnerSingle({state}:{state: any}) {
    /** Fake Loading Logic */

  const [predictStartTime, setPredictStartTime] = useState<number>(new Date().getTime() / 1000.0);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (state.matches("LoadingSpinner-Single")) {
      setPredictStartTime(new Date().getTime() / 1000.0); // when you start predicting, set the start time
    }
  }, [state]);

  useEffect(() => {
    const interval = setInterval(() => {
      const curTime = new Date().getTime() / 1000.0 - predictStartTime;
      setProgress(1 - Math.exp(-curTime / 2));
    }, 100);

    if (!state.matches("LoadingSpinner-Single")) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [state, predictStartTime]);
    return(
        <Spinner statusStr={`Predicting on Single SMILES (${(progress * 100).toFixed(0)}%)...`} background="bg-neutral" />
    );
}