import React, { useContext, useState } from 'react';
import ErrorModal from 'components/atoms/ErrorModal/ErrorModal';


const ErrorContext = React.createContext();
const ErrorUpdateContext = React.createContext();

export const useError = () => {
    return useContext(ErrorContext);
}

export const useErrorUpdate = () => {
    return useContext(ErrorUpdateContext);
}

export function ErrorProvider({ children }) {

    const [error, setError] = useState(null);

    return (
        <ErrorContext.Provider value={error}>
            <ErrorUpdateContext.Provider value={setError}>
                {children}
            </ErrorUpdateContext.Provider>
        </ErrorContext.Provider>
    );
}