import { ModelDB, VisualizationDB } from "api/BaseDatabase";
import { ChonkyActions, ChonkyIconName, FileAction, FileData, FileActionData } from "chonky";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import database from "api/FirestoreDatabase";


export default function useObjectBrowser(objects: any, model?: ModelDB) {

    const navigate = useNavigate();
    const [currentPath, setCurrentPath] = useState(model?.content?.data?.path || "/");
    const [tempFolders, setTempFolders] = useState<any[]>([]);

    const renameFolder: FileAction = {
        id: 'rename_folder',
        requiresSelection: true,
        button: {
          name: 'Rename',
          contextMenu: true,
          group: 'Actions',
          icon: ChonkyIconName.terminal,
        }
    
      }

      const SortFilesByType: FileAction = {
        id: 'sort_files_by_type',
        sortKeySelector: (file: any) => (file?.icon || undefined),
        button: {
            name: 'Sort by File Type',
            toolbar: true,
            group: 'Options',
        }
      }

      const SortFilesByID: FileAction = {
        id: 'sort_files_by_id',
        sortKeySelector: (file: any) => (file ? file.id : undefined),
        button: {
            name: 'Sort by ID',
            toolbar: true,
            group: 'Options',
        }
     }

      const fileActions = useMemo(() => [ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles, renameFolder, SortFilesByID, SortFilesByType], []);

      const updateVisualizationPath = async (visID: string, newInfo: string) => {
        // const vis = await database.getVisualization(visID);
        // await database.updateDocument(vis, {"data.path" : newInfo});
        const vis = await database.getVisualization(visID);
        await database.updateDocument(vis, {"data.path" : newInfo});
      }

      const updateVisualizationName = async (visID: string, newInfo: string) => {
        const vis = await database.getVisualization(visID);
        await database.updateDocument(vis, {"data.name" : newInfo});
      }

      const updateModelPath = async (modelID: string, newInfo: string) => {
        const model = await database.getModel(modelID);
        await database.updateDocument(model, {  "data.path": newInfo });
      }

      const updateModelName = async (modelID: string, newInfo: string) => {
        const model = await database.getModel(modelID);
        await database.updateDocument(model, {  "data.name": newInfo });
      }
      const splitCurrentPathFromFolderID = (folderID: string) => {
        return folderID.split("/").filter((folder: any) => folder !== "__models__");
      }

      const joinSplitPathFromFolderID = (splitID: string[]) => {
        return splitID.join("/");
      }

      const extractCurrentPathFromFolderID = (folderID: string) => {
        return joinSplitPathFromFolderID(splitCurrentPathFromFolderID(folderID)) + "/";
      }

      const getAllObjectsInPath = (objects: any, currentPath: string, name: string) => {
        return [...objects].filter((object: any) => object?.content?.data?.path?.includes(currentPath + name + "/") && (object?.content?.data?.path));
      }
    
      const filterFoldersByFileID = (folders: any[], file: any) => {
        if (folders.indexOf(file) > -1) {
            const filteredTempFolders = [...folders].filter((tempFolder) => !tempFolder.id.includes(file.id));
            const deletedTempFolder = [...folders].filter((tempFolder) => tempFolder.id.includes(file.id));
            return {deleted: deletedTempFolder, filtered: filteredTempFolders}
        }
        return {deleted: [], filtered: []}
        
      }
    
      const currentObjects = [...objects].filter(
        (object: any) => (object?.content?.data?.path || "/") === currentPath && (object?.content?.data?.status === "Runnable" || object?.content?.data_url)
      );
      
      // SHOULD WE MAKE IT S.T. NO FOLDER CAN BE NAMED AFTER ROOT FOLDER
      const updateFolderChain = (folder: any) => {
        
        let p = (
          folderChain
            .slice(0, folderChain.indexOf(folder) + 1)
            .map((f) => f.name)
            .join("/") + "/"
        ).replace("Models", "");
        
        if (p.startsWith("//")) p = p.slice(1);
          
        return p;
      }
    
      // Leave as is
      const updateObjects = (data: any, path: any) => {
        data?.payload?.selectedFiles?.forEach((file: any) => {
            if (file?.icon === "image") {
                database.updateObj(`visualizations/${file.id}`, { "data.path": path });
            }
            else {
                database.updateObj(`models/${file.id}`, { "data.path": path });
            }
        });
      }

    
      let currentFolders = [
        ...new Set(
          [...objects]
            .filter(
              // first get all models in folders in the current path
              (object: any) =>
                (object?.content?.data?.path || "/") !== currentPath &&
                (object?.content?.data?.path || "/").startsWith(currentPath)
            )
            .map(
              // then map these to the top level folders
              (object: any) => (object?.content?.data?.path || "/").slice(currentPath.length).split("/")[0]
            )
        ),
      ];


    
      const files: FileData[] = currentObjects.map((object: any) => (!object?.content?.data_url ? {
        id: object.id,
        name: object?.content?.data?.name || "Untitled Model",
        icon: "model",
        
      } : 
      {
        id: object.id,
        name:  object?.content?.data?.name || "Untitled Visualization",
        icon: "image",
        color: "blue",
        style: {border: "black 2px"}
      }));
    
    
      let folderChain: any[] = [{ id: "__models__", name: "Models", isDir: true }]
      for (const pathSegment of currentPath.split("/")){
        if (pathSegment !== '') {
          folderChain.push({id: folderChain.slice(-1)[0].id + "/" + pathSegment , name: pathSegment, isDir: true})
        }
      }
    
      const handleFileOpen = (data: FileActionData<FileAction>) => {
        if (data?.payload?.targetFile?.isDir && 
          (currentFolders.includes(data?.payload?.targetFile?.name) || tempFolders.includes(data?.payload?.targetFile))) {
          setCurrentPath(currentPath + data?.payload?.targetFile?.name + "/");
        } else if (data?.payload?.targetFile?.isDir) {
          
          folderChain.forEach((f) => {
            if (f.id === data?.payload?.targetFile?.id) {
              const p = updateFolderChain(f);
              console.log(`setting current path to ${p}`);
              setCurrentPath(p);
            }
          });
        } else if (data?.payload?.targetFile?.icon === "image") {
            navigate(`/vis/?vid=${data.payload.targetFile.id}`)
        }
        else if (data?.payload?.targetFile?.icon === "model") {
            navigate(`/endpoint/?mid=${data.payload.targetFile.id}`);
        }
          
      }


      const handleFileMove = (data: FileActionData<FileAction>) => {
        const destination = data?.payload?.destination?.id;
        const destinationObj = data?.payload?.destination
        const finalLocation = extractCurrentPathFromFolderID(destination);

        data?.payload?.selectedFiles?.forEach((file: any) => {
        if (destinationObj?.isDir && file?.isDir) {

            const tempObjects = getAllObjectsInPath(objects, currentPath, file.name);
            
            if (tempObjects.length === 0) {
                const filteredFolders = filterFoldersByFileID(tempFolders, file);
                filteredFolders.deleted.forEach((f: any) => {
                    const replaced = f.id.replace(`__models__/${currentPath}`, `__models__/${finalLocation}`)
                    filteredFolders.filtered.push({id: `${replaced}`, name: f.name, isDir: true})
                })
                setTempFolders(filteredFolders.filtered)
            
            } else {
                tempObjects.forEach(async (object: any) => {
                    
                    if (!object?.content?.data_url) {
                        const modelPath = object?.content?.data?.path;
                        await updateModelPath(object.id, finalLocation + modelPath?.replace(currentPath, ""));
                    } else {
                        const visPath = object?.content?.data?.path;
                        await updateVisualizationPath(object.id, finalLocation + visPath?.replace(currentPath, ""))
                    }

                    

                })
                const filteredFolders = filterFoldersByFileID(tempFolders, file);
                setTempFolders(filteredFolders.filtered);
            }
            
    
            
        } else if (destination && (tempFolders.includes(destinationObj) || currentFolders.includes(destinationObj.name))) {
            // we want the name here
            updateObjects(data, currentPath + data?.payload?.destination?.name + "/");
    
        } else {
            if (destination === "__models__")
    
            updateObjects(data, "/");
    
            folderChain.forEach((f) => {
            if (f.id === destination) {
                const p = updateFolderChain(f);
                console.log(`moving files to ${p}`);
                updateObjects(data, p);
            }
            });
        }
        })
      }


    
      const handleDelete = (data: FileActionData<FileAction>) => {
        const confirmation = prompt(`Delete folder(s)"? This action can not be undone. (Type YES to confirm)`);
        if (data?.state?.selectedFiles) {
          data?.state?.selectedFiles.map(async (file: FileData) => {
            if (file?.isDir) {
                if (confirmation) {
                  if (confirmation?.toLowerCase() === 'yes') {
      
                    const tempObjects = getAllObjectsInPath(objects, currentPath, file.name);
                    if (tempObjects.length !== 0) {
                        tempObjects.forEach(async (object) => {
                      if (!object?.content?.data_url) {
                          const m = await database.getModel(object.id);
                          await database.deleteModel(m);
                      } else {
                          const v = await database.getVisualization(object.id);
                          await database.deleteVisualization(v);
                      }
                        
                        
                      });
                    } else {
                      console.log(tempObjects, "<===== tempModels");
                      const filteredFolders: any = filterFoldersByFileID(tempFolders, file).filtered;
                      console.log(filteredFolders, "<===== after delete current TempFolders");
                      setTempFolders(filteredFolders);
                    }
                  }
                }
              } else {
                if (confirmation) {
                  if (file?.icon === "image") {
                    const vis = await database.getVisualization(file.id);
                    await database.deleteVisualization(vis);
                  } else if (file?.icon === "model") {
                    const model = await database.getModel(file.id);
                    await database.deleteModel(model);
                  } 
                }
              }
          })
        } 
      }
    


      const handleCreate = (data: FileActionData<FileAction>) => {
        const folderName = prompt("Provide the name for your new folder (must contain text):");
    
          const newFolder = {id: `__models__/` + currentPath + folderName, name: folderName, isDir: true};
          console.log(newFolder, "<===== new folder");
          if (folderName) {
            setTempFolders([...tempFolders, newFolder]);
            const p = updateFolderChain(newFolder);
            updateObjects(data, p);
          };
      }



      const handleRename = async (data: FileActionData<FileAction>) => {
        const selected = data?.state?.selectedFiles[0];
          const newName = prompt(`Renaming "${selected.name}"`);
          if (newName) {
            if (selected?.icon === "image") {
                updateVisualizationName(selected.id, newName);
              
      
            } 
            else if (selected?.icon === "model") {
                updateModelName(selected.id, newName);
            }
            else {
    
              console.log(selected.id);
              const filtered = splitCurrentPathFromFolderID(selected.id);
              const originalFilePath = joinSplitPathFromFolderID(filtered);
              filtered[filtered.length - 1] = newName;
              const newFilePath = joinSplitPathFromFolderID(filtered);
              const tempObjects = getAllObjectsInPath(objects, currentPath, selected.name);
    
              if (tempObjects.length !== 0) {
                tempObjects.forEach(async (object: any) => {
                  const objectPath = object?.content?.data?.path;
                  const correctedPath = objectPath?.replace(originalFilePath, newFilePath);
                  if (object?.content?.data_url) {
                    updateVisualizationPath(object.id, correctedPath);
                  } else {
                    updateModelPath(object.id, correctedPath);
                  }
                  
                });
              } else {
                  const filteredFolders = filterFoldersByFileID(tempFolders, selected);
    
                  filteredFolders.deleted.forEach((folder) => {
                    console.log(folder.id.replace(selected.id, `__models__/${newFilePath}`));
                    const folderName = folder.id === selected.id ? newName: folder.name;
                    filteredFolders.filtered.push({id: folder.id.replace(selected.id, `__models__/${newFilePath}`), name: folderName, isDir: true});
                  })
                  setTempFolders(filteredFolders.filtered);
              }
    
            }
          
        }
      }

      return {
        handleCreate,
        handleDelete,
        handleFileMove,
        handleFileOpen,
        handleRename,
        folderChain,
        renameFolder,
        fileActions,
        currentFolders,
        currentObjects,
        currentPath,
        tempFolders,
        files,
      }
      
}