import React, { useEffect } from 'react';
import { Log, LogDB } from 'api/BaseDatabase';
import database from 'api/FirestoreDatabase';
import { useError, useErrorUpdate } from 'contexts/ErrorContext';

export default function ErrorModal() {
    const Error = useError();
    const setError = useErrorUpdate();
    console.log(Error);

    const onClose = () => { setError(null); }

    return (
        Error ? (
            <>
            <div id="my-modal-3" className="modal modal-open">
            <div className="modal-box ">
                <label htmlFor="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</label>
                <h3 className="text-lg font-bold">An unexpected error occurred</h3>
                <div className=" flex flex-col w-fit content-center ">  
                <p id="error-id" className="py-3 mr-10" >
                {" "}
                We’ve run into an unexpected error and logged it.  
                If you need expedited support, please send an email to{" "}
                <a href="mailto:support@oloren.ai" className="font-bold">
                    support@oloren.ai
                </a>{" "}
                with the following error id. We'll make fixing it our top priority:
                </p>
                <div className="px-3 mr-14">
                    <div className="flex mt-2 bg-neutral w-full p-2 rounded-box whitespace-pre-wrap	">
                    
                        {`Error ID: ${Error.id}`}
                    
                    </div>{" "}
                </div>
            </div>
            </div>
        </div>
        </>
        ) : 
        null
    
    );
}