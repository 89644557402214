import { api } from "api/FirestoreDatabase";
import Button from "components/atoms/Button/Button";
import Navbar from "components/organisms/Navbar/Navbar";
import React, { useEffect } from "react";
import { HiClipboardCopy } from "react-icons/hi";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function OamAuth() {
  const [token, setToken] = React.useState("Loading...");
  useEffect(() => {
    api
      .getAuthToken()
      .then((token) => {
        console.log(token);
        if (token.status === "success") setToken(token["data"] || "Invalid Token");
        else setToken("Invalid Token");
      })
      .catch((err) => {
        setToken("Error authenticating user...");
      });
  });

  return (
    <>
      <div className="w-screen h-screen flex flex-col">
        <Navbar />
        <div className="grow grid place-items-center">
          <div className="w-2/3 space-y-2">
            <label className="text-2xl font-display font-bold"> Copy and Paste this Token into your Oloren ChemEngine Terminal: </label>
            <div className="flex flex-row">
              <input type="text" className="grow input input-lg input-bordered" readOnly={true} value={token} />
              <CopyToClipboard text={token}>
                <Button fullWidth={false} fullHeight={true}>
                  <HiClipboardCopy className="text-4xl my-3" />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
