import React, { useState } from "react";

export default function Toggle({ defaultState, labelText, userHandleChange }) {
  const [isChecked, setIsChecked] = useState(defaultState);

  const handleChange = () => {
    setIsChecked(!isChecked);
    userHandleChange();
  };

  return (
    <div className="w-fit">
      <label className="label cursor-pointer">
        <span className="label-text pr-2">{labelText}</span>
        <input type="checkbox" className="toggle toggle-primary" checked={isChecked} onChange={handleChange} />
      </label>
    </div>
  );
}

export function ManualToggle({ isChecked, handleChange, labelText }) {
  return (
    <div className="w-fit">
      <label className="label cursor-pointer">
        <span className="label-text pr-2">{labelText}</span>
        <input type="checkbox" className="toggle toggle-primary" checked={isChecked} onChange={handleChange} />
      </label>
    </div>
  );
}
