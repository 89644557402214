import { ModelDB } from "api/BaseDatabase";
import { api } from "api/FirestoreDatabase";
import Metrics from "components/atoms/Metrics/Metrics";
import Plot from "components/atoms/Plot/Plot";
import Tabs from "components/layouts/Tabs/Tabs";
import { useEffect, useState } from "react";
import "./ModelDisplay.css";

export default function ModelDisplay({ model, id }: { model: ModelDB; id?: string }) {
  return (
    <div className="h-full flex flex-col space-y-4">
      <Metrics metrics={model.content.data.metrics} />
      <Tabs
        tabs={["Predicted vs. True", "AUC Curve"]}
        tabContent={[
          <Plot
            visualizationType="VisualizeModelSim"
            attributes={{ dataset: model.content.did, model: model.id }}
            visualizationName={""}
          />,
          <Plot
            visualizationType="ModelROC"
            attributes={{ dataset: model.content.did, model: model.id }}
            visualizationName={""}
          />,
        ]}
      />
    </div>
  );
}
