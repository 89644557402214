import React from "react";
import { BiErrorCircle } from "react-icons/bi";

/**
 * A simple react component which displays a useful helf message on hover.
 * @param message - Help message to display
 */
export default function ErrorIcon({
  message,
  className = "",
  disabled = false,
}: {
  message: string;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <div className="dropdown dropdown-hover dropdown-bottom dropdown-end">
      <BiErrorCircle className={"m-1 " + (disabled ? "text-info " : "text-error ") + className} size={30} />
      <div className="dropdown-content w-[500%] shadow-md p-3 h-fit bg-white font-normal font-rubik text-sm">
        {message}
      </div>
    </div>
  );
}
