import Button from "components/atoms/Button/Button";
import MoleculeDataTable from "components/organisms/MoleculeDataTable/MoleculeDataTable";
import Navbar from "components/organisms/Navbar/Navbar";
import { useSearchParams } from "react-router-dom";
import usePredictInfer from "./usePredictInfer";
import Metrics from "components/atoms/Metrics/Metrics";
import ClipLoader from "react-spinners/ClipLoader";
import Traceback from "components/atoms/Traceback/Traceback";

export default function PredictInfer() {
  const [searchParams] = useSearchParams();
  const mid = searchParams.get("mid");
  const did = searchParams.get("did");

  const { table, metrics, downloadDataset, downloadPredictions, inferStatus, traceback } = usePredictInfer(mid, did);

  return (
    <>
      <Navbar />
      <div className="ml-12 mt-4 h-full">
        {table ? (
          <>
            <Button id="infer-0" fullWidth={false} onClick={downloadPredictions}>
              Download Predictions
            </Button>
          </>
        ) : null}
        <div className="m-2 w-full h-full">
          {table ? (
            <>
              {metrics ? <Metrics metrics={metrics} /> : null}
              <MoleculeDataTable table={table} onSelectCol={(idx) => null} getColColor={(idx) => ""} />
            </>
          ) : inferStatus === "Error" ? (
            <Traceback traceback={traceback} />
          ) : (
            <div className="w-full h-full bg-white grid place-items-center">
              <div class="-mt-24">
                <ClipLoader color={"blue"} loading={true} size={200} />
                <p className="text-xl text-center">{inferStatus} </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
