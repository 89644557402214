import { DatasetDB, ModelDB, VisualizationDB } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";
import { FullFileBrowser } from "chonky";
import ObjectBrowser from "components/organisms/ModelBrowser/ObjectBrowser";
import SkeletonBrowser from "components/organisms/ModelBrowser/SkeletonBrowser";
import Navbar from "components/organisms/Navbar/Navbar";
import { useEffect, useState, useMemo, useRef } from "react";


export default function Home() {
  
  const [models, setModels] = useState<ModelDB[]>([]);
  console.log(models);
  useEffect(() => {
    return database.getLive(database.queryUserModels(), setModels);
  }, []);

  const [visualizations, setVisualizations] = useState<VisualizationDB[]>([]);
  useEffect(() => {
    return database.getLive(database.queryUserVisualizations(), setVisualizations)
  }, [])

  const validVisualizations = visualizations.filter((vis) => vis?.content?.data_url !== null);
  const DatabaseObjects = useMemo(() => [...models, ...validVisualizations], [models, visualizations]);
  
  return (
    <>
    <Navbar />
    { 
      <div className="h-[90%] p-4">
        <ObjectBrowser DatabaseObjects={DatabaseObjects}  />
      </div> 
    }

    </>
  );
}
