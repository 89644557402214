import { ModelDB } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";
import { useGlobalContext } from "contexts/GlobalContext";
import { setDefaultResultOrder } from "dns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isDefaultModelName } from "util/General";

export default function usePredictSelect() {
  const navigate = useNavigate();
  const { setError } = useGlobalContext();

  const next = async () => {
    if (selectedModel) {
      navigate(`/endpoint/?mid=${selectedModel.id}`);
    } else {
      setError("No model selected!");
    }
  };

  const [models, setModels] = useState<ModelDB[]>([]);
  useEffect(() => {
    function compareDatasets(x: ModelDB, y: ModelDB) {
      if (!x?.content?.data?.name) return 1;
      const a = isDefaultModelName(x.content.data.name, x?.content?.data?.parameters || "");
      const b = isDefaultModelName(y?.content?.data?.name || "", y?.content?.data?.parameters || "");
      if (a) return 1;
      if (b) return -1;
      return 0;
    }

    const filterSetModels = (ms: ModelDB[]) => {
      console.log("Hello");
      console.log(ms);
      setModels(ms.filter((m) => m?.content?.data?.status === "Runnable").sort(compareDatasets));
    };

    return database.getLive(database.queryUserModels(), filterSetModels);
  }, []);

  console.log(models);

  const [selectedModel, setSelectedModel] = useState<ModelDB | null>(null);

  // Listen for changes on all models
  return {
    selectedModel,
    setSelectedModel,
    models,
    next,
  };
}
