import { useField } from "formik";
import Select from "react-select";

const FormSelect = ({ name, options }: { name: string; options: any }) => {
  const [field, , helpers] = useField(name);
  return (
    <>
      <Select
        name={name}
        value={field.value}
        onChange={(value: any) => helpers.setValue(value)}
        options={options}
        onBlur={() => helpers.setTouched(true)}
      />
    </>
  );
};

export default FormSelect;
