import { DatabaseObject } from "api/BaseDatabase";
import database, { api } from "api/FirestoreDatabase";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { csvToTable, Table } from "util/Tables";

// function clean_table(table) {
//   return table[0].includes(undefined) ? table.map((row) => row.slice(1)) : table;
// }
// function transpose(matrix) {
//   const clean = clean_table(matrix);
//   return clean[0].map((col, i) => clean.map((row) => row[i]));
// }

export default function usePredictInfer(mid: string, did: string) {
  let navigate = useNavigate();

  if (!mid) navigate("/predict/select");
  if (!did) navigate("/predict/upload");

  // Queue inference
  useEffect(() => {
    api.inferModel(mid, did).then((out) => {
      console.log(out);
    });
  }, [mid, did]);

  // Get inference updates live
  const [inference, setInference] = useState<DatabaseObject | null>(null);
  useEffect(() => {
    database.getDocumentLive(`datasets/${did}/inferences/${mid}`, setInference);
  }, [mid, did]);

  const inferStatus = inference?.content?.status || "Submitting inference request";
  const traceback = inference?.content?.traceback || "";

  // Get predicted values once inference is finished
  const [metrics, setMetrics] = useState<any>(null);
  const [table, setTable] = useState<Table | null>(null);

  useEffect(() => {
    if (inference?.content?.predictions) {
      if (inference.content.metrics) setMetrics(inference.content.metrics);
      database.downloadFromStorage(inference.content.predictions || "").then((file) => {
        csvToTable(file).then((table) => {
          setTable(table);
        });
      });
    }
  }, [inference]);

  const downloadPredictions = async () => {
    if (inference?.content?.predictions) {
      saveAs(await database.downloadLinkFromStorage(inference.content.predictions), `predictions-${did}-${mid}.csv`);
    }
  };

  return { table, metrics, downloadPredictions, inferStatus, traceback };
}
