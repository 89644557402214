import React from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import Button from "components/atoms/Button/Button";

export default function JsonInputButton({ name, children, submit, id}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [json, setJson] = React.useState({});

  return (
    <>
      <Button
      id={id}
        onClick={() => {
          setIsOpen(true);
        }}
        fullWidth={false}
      >
        {children}
      </Button>
      <div className={"modal" + (isOpen ? " modal-open" : "")}>
        <div className="modal-box">
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </button>
          <h3 className="font-bold text-lg">Enter JSON</h3>
          <div className="space-y-2">
            <JSONInput
              id={name + "-json-input"}
              locale={locale}
              onChange={(update) => {
                setJson(update);
              }}
              height="300px"
              width="100%"
            />
            <Button
              onClick={() => {
                setIsOpen(false);
                submit(json.jsObject);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
