import { createMachine, assign } from "xstate";

export const EndpointMachine = createMachine(
    {
        predictableActionArguments: true,
        "id": "Molecule Prediction Dashboard",
        "initial": "Initial",
        "states": {
            "LoadingSpinner-Dataset": {
            "on": {
                "VALID_DATASET": {
                    target: "LoadingSpinner-Dataset"
                },
                "INVALID_DATASET": {
                    target: "InvalidMoleculesCorrector"
                },
                "INFER_DATASET_COMPLETED": {
                    "target": "MoleculeDataTable"
                }
            }
            },
            "MoleculeDataTable": {},
            "MoleculePreview-Button": {
            "on": {
                "INFER_SINGLE": {
                "target": "LoadingSpinner-Single"
                }
            }
            },
            "LoadingSpinner-Single": {
            "on": {
                "INFER_COMPLETED": {
                    target: "PredictionDisplay"
                }
            }
            },
            "PredictionDisplay": {},
            "InvalidMoleculesCorrector": {
            "on": {
                "VALID_DATASET": {
                "target": "LoadingSpinner-Dataset"
                }
            }
            },
            "Initial": {}
        },
        "on": {
            "DATASET_UPLOAD": {
                target: ".LoadingSpinner-Dataset"
            },
            "TYPED_INPUT": {
                target: ".MoleculePreview-Button"
            },
            "PASTED_INPUT": {
                target: ".LoadingSpinner-Single"
            },
            "INITIAL": {
                target: ".Initial"
            }
        }
    },
      
);

export default EndpointMachine;