import { useState } from "react";

export default function TabbedWindow({ tabNames = [], tabContents = [], sidebarActiveTab, setSidebarActiveTab }) {
  return (
    <>
      <div className="mb-3 w-full">
        {tabNames.map((tab, idx) => {
          return (
            <button
              className={
                "md:text-base xl:text-2xl font-bold  ml-3 border-transparent border-b-2 hover:border-b-2 hover:border-black font-display" +
                (sidebarActiveTab === idx ? " border-b-2 border-black" : " text-neutral")
              }
              key={idx}
              onClick={() => {
                setSidebarActiveTab(idx);
              }}
            >
              {tab}
            </button>
          );
        })}
      </div>
      <div className="w-full">{tabContents[sidebarActiveTab]}</div>
    </>
  );
}
