import { FaTrash } from "react-icons/fa";

export default function Selector({
  selectFn = (idx) => {},
  nullText,
  options = [],
  selected = [],
  multi = false,
  deleteFn = (idx) => {},
  selectedClass = "bg-primary/20",
}) {
  return (
    <div className="bg-white rounded max-h-24 mx-auto overflow-x-hidden overflow-y-auto costumeScroll py-1 ">
      {options.length > 0 ? (
        options.map((option, idx) => (
          <h5
            className={
              "text-xs font-medium rubik px-4 cursor-pointer " + (selected.includes(idx) ? " " + selectedClass : "")
            }
            onClick={(e) => {
              selectFn(idx);
            }}
            key={idx}
          >
            {option}
            {deleteFn ? (
              <FaTrash
                className="float-right hover:text-red-500"
                onClick={(e) => {
                  deleteFn(idx);
                  e.stopPropagation();
                }}
              />
            ) : null}
            <br />
          </h5>
        ))
      ) : (
        <h5 className="text-xs font-medium rubik px-4">{nullText}</h5>
      )}
    </div>
  );
}
