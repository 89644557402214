import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  onValue,
  remove,
  push,
  update,
  child,
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref,
  off,
  set,
} from "firebase/database";
import * as fb_st from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCSaFE5-qxy5-HAr-oqUd5del4bv5dqJ0Q",
  authDomain: "oloren-ai.firebaseapp.com",
  databaseURL: "https://oloren-ai-default-rtdb.firebaseio.com",
  projectId: "oloren-ai",
  storageBucket: "oloren-ai.appspot.com",
  messagingSenderId: "602366687071",
  appId: "1:602366687071:web:f35531f7142084b86a28ea",
  measurementId: "G-MVNK4DPQ60",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = fb_st.getStorage();

export async function getUserDatasets(userId) {
  const dataset_query = get(query(ref(db, "datasets"), orderByChild("user_id"), equalTo(userId)));
  let datasets = (await dataset_query).val();

  if (!datasets) {
    return {};
  }

  Object.keys(datasets).forEach((key) => {
    if (!("name" in datasets[key])) {
      datasets[key].name = key;
    }
  });

  return datasets;
}

export async function firebaseUrlToBytes(url) {
  const dataset_path = fb_st.ref(storage, url);
  return await fb_st.getBytes(dataset_path);
}

export async function createObject(path, obj) {
  const datasetKey = push(child(ref(db), path)).key;
  set(child(child(ref(db), path), datasetKey), obj);
  return datasetKey;
}

export async function setPath(path, data) {
  set(child(ref(db), path), data);
}

export async function updatePath(path, data) {
  update(child(ref(db), path), data);
}

export async function getPath(path) {
  return (await get(child(ref(db), path))).val();
}

export async function getAllMatchingChild(path, childName, childValue) {
  return (await get(query(ref(db, path), orderByChild(childName), equalTo(childValue)))).val();
}

export async function matchingChildExists(path, childName, childValue) {
  return (await get(query(ref(db, path), orderByChild(childName), equalTo(childValue)))).exists();
}

export async function deletePath(path) {
  return remove(child(ref(db), path));
}

export async function removeListener(path) {
  return off(child(ref(db), path));
}

export async function changeListener(path, callback) {
  onValue(child(ref(db), path), (snapshot) => {
    callback(snapshot.val());
  });
}

export async function uploadToBucket(path, bytes) {
  const storageRef = fb_st.ref(storage, path);
  await fb_st.uploadBytes(storageRef, bytes);
}

export async function deleteFromBucket(path) {
  try {
    if (path) {
      const storageRef = fb_st.ref(storage, path);
      await fb_st.deleteObject(storageRef);
    }
  } catch (e) {}
}
