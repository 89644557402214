import Button from "components/atoms/Button/Button";
import Collapser from "components/atoms/Collapser/Collapser";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import JsonInputPopup from "components/organisms/JsonInputPopup/JsonInputPopup";
import DisplayTable from "components/organisms/DisplayTable/DisplayTable";
import React from "react";
import useBuildCreate from "./useBuildCreate";
import ModelDisplay from "components/organisms/ModelDisplay/ModelDisplay";
import Panel from "components/atoms/Panel/Panel";
import { useAuth } from "contexts/AuthContext";
import { useSearchParams } from "react-router-dom";
import database, { api } from "api/FirestoreDatabase";
import { Model } from "api/BaseDatabase";
import { useRef } from "react";

// eslint-disable-next-line no-empty-pattern
export default function BuildCreate({}) {
  let [searchParams] = useSearchParams();
  const datasetId = searchParams.get("did");
  const did = datasetId;
  const { models, selectedModel, setSelectedModel, next } = useBuildCreate(datasetId);

  const introJsRef = useRef(null);

  return (
    <>
      <SidebarLayout
        sidebarPanel={
          <>
            <Collapser
              headerName="Queue Models"
              helpMessage='Hit "+ Auto ML" a few times to get started! Contact Oloren support for more info on custom models.'
            >
              <Button fullWidth={false} onClick={() => api.queueAutoML(did)} id="train-1">
                + AutoML
              </Button>

              <JsonInputPopup
                name="customModel"
                submit={(params) => api.queueCustom(did, JSON.stringify(params))}
                id="train-2"
              >
                + Custom Model
              </JsonInputPopup>
            </Collapser>
            <Collapser
              id="train-3"
              headerName="Model Status"
              helpMessage="Once you queue models, they'll start appearing here. Click one to see its status and metrics when it's finished."
            >
              <DisplayTable
                id="train-4"
                rows={models.map((model) => [model.content.data.name, model.content.data.status])}
                header={["Model Name", "Status"]}
                onClick={(idx) =>
                  models[idx] === selectedModel ? setSelectedModel(null) : setSelectedModel(models[idx])
                }
                selected={selectedModel ? models.map((model) => model.id).indexOf(selectedModel.id) : -1}
                deleteFn={(idx) => database.deleteModel(models[idx])}
              />
            </Collapser>
          </>
        }
        sidebarEnd={
          <Button id="train-5" fullWidth={true} disabled={false} onClick={next}>
            Next
          </Button>
        }
        mainPanel={
          <span id="model-metrics">
            {selectedModel ? (
              <Panel>
                <ModelDisplay model={selectedModel} />{" "}
              </Panel>
            ) : null}
          </span>
        }
      />
    </>
  );
}
