import React from "react";

import { BiHelpCircle } from "react-icons/bi";

/**
 * A simple react component which displays a useful helf message on hover.
 * @param message - Help message to display
 */
export default function HelpIcon({ message, className = "" }: { message: string; className?: string }) {
  return (
    <div className="dropdown dropdown-hover dropdown-bottom dropdown-end">
      <BiHelpCircle className={"m-1 " + className} size={30} />
      <div className="dropdown-content w-[500%] shadow-md p-3 h-fit bg-white font-normal font-rubik text-sm">
        {message}
      </div>
    </div>
  );
}
