import React from "react";

export default function Traceback({ traceback }: { traceback: string }) {
  return (
    <div id="traceback" className="w-fit">
      {" "}
      Sorry about this! Send us an email at{" "}
      <a href="mailto:support@oloren.ai" className="font-bold">
        support@oloren.ai
      </a>{" "}
      with the following traceback. We'll make fixing it our top priority.
      <div className="mt-2 bg-neutral w-full p-2 whitespace-pre-wrap	">{traceback}</div>{" "}
    </div>
  );
}
