import Button from "components/atoms/Button/Button";

interface YesNoModalProps {
  title: string;
  description: string;
  visible: boolean;
  response: Function;
  yesText?: string;
  noText?: string;
}

export default function YesNoModal({
  title,
  description,
  visible,
  response,
  yesText = "Go Ahead",
  noText = "No Thanks",
}: YesNoModalProps) {
  return (
    <>
      <input type="checkbox" id="yes-no-modal" className="modal-toggle" checked={visible} />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">{title}</h3>
          <p className="py-4">{description}</p>
          <div className="modal-action flex flex-row justify-between">
            <Button className=" btn-info " fullWidth={false} onClick={() => response(false)}>
              {noText}
            </Button>
            <Button fullWidth={false} onClick={() => response(true)}>
              {yesText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
