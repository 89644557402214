import NameCard from "components/atoms/NameCard/NameCard";
import NoVisSelected from "components/atoms/NoVisSelected/NoVisSelected";
import Plot from "components/atoms/Plot/Plot";
import TabbedWindow from "components/atoms/TabbedWindow/TabbedWindow";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import VisualizationTabs from "components/layouts/VisualizationTabs/VisualizationTabs";
import ObjectChooser from "components/molecules/ObjectChooser/ObjectChooser";
import AttributeTab from "components/organisms/AttributeTab/AttributeTab";
import { useEffect, useState } from "react";
import { useVisualize } from "./useVisualize";
import { VisualizeContext } from "./VisualizeContext";

interface TabbedVisualization {
  selectorAttributes: any;
}

export default function Visualize({ uid }: { uid: string }) {
  const [visualizationType, setVisualizationType] = useState<string | undefined>(undefined);
  const { validVisualizations, requestedAttributes } = useVisualize(visualizationType);

  const [requiredAttributes, setRequiredAttributes] = useState<any>(null);
  useEffect(() => {
    setRequiredAttributes(requestedAttributes);
  }, [requestedAttributes]);

  // attributes which must be set by an external selector (dataset, model, etc.) not via Formik
  const [selectorAttributes, setSelectorAttributes] = useState<any>(null);
  const [selecting, setSelecting] = useState<any>(null); // attribute being selected, if null (none)
  const [attributes, setAttributes] = useState<any>(null);
  const [sidebarActiveTab, setSidebarActiveTab] = useState<number>(0);

  const [visTabs, setVisTabs] = useState<string[]>(["New Visualization"]);
  const [tabVisualizations, setTabVisualizations] = useState<any[]>([{ plot: <NoVisSelected /> }]);

  const [visActiveTab, setVisActiveTab] = useState<number>(0);

  // const [typeAttributeHash, setTypeAttributeHash] = useState<string>("");
  // const typeAndAttributes = visualizationType + ":" + JSON.stringify(attributes);
  // const hash = sha256(typeAndAttributes).toString();
  // setTypeAttributeHash(hash);
  // console.log(typeAttributeHash);

  useEffect(() => {
    if (visualizationType && visTabs[visActiveTab] === "New Visualization") {
      setVisTabs((prev) => {
        const newTabs = [...prev];
        newTabs[visActiveTab] = visualizationType;
        return newTabs;
      });
    }
    if (visualizationType && attributes) {
      const visualizationName = visTabs[visActiveTab];
      const visualTab = {
        plot: (
          <Plot visualizationType={visualizationType} attributes={attributes} visualizationName={visualizationName} />
        ),
        selectorAttributes: selectorAttributes,
        attributes: attributes,
        requiredAttributes: requiredAttributes,
        visualizationType: visualizationType,
      };
      setTabVisualizations((prev) => {
        const newTabs = [...prev];
        newTabs[visActiveTab] = visualTab;
        return newTabs;
      });
    }
  }, [visualizationType, attributes]);

  // adjust sidebar tabs to match currently selected visual when visActiveTab changes
  useEffect(() => {
    if (Object.hasOwn(tabVisualizations[visActiveTab], "visualizationType")) {
      setSelectorAttributes(tabVisualizations[visActiveTab].selectorAttributes);
      setAttributes(tabVisualizations[visActiveTab].attributes);
      setRequiredAttributes(tabVisualizations[visActiveTab].requiredAttributes);
      setVisualizationType(tabVisualizations[visActiveTab].visualizationType);
      setSidebarActiveTab(1);
    } else {
      setSidebarActiveTab(0);
    }
  }, [visActiveTab]);

  return (
    <VisualizeContext.Provider value={{ selectorAttributes, setSelectorAttributes, selecting, setSelecting }}>
      <SidebarLayout
        sidebarPanel={
          <TabbedWindow
            sidebarActiveTab={sidebarActiveTab}
            setSidebarActiveTab={setSidebarActiveTab}
            tabNames={["Plots", "Attributes"]}
            tabContents={[
              <>
                {validVisualizations && validVisualizations.length > 0 ? (
                  <div className="flex flex-wrap bg-neutral p-4 rounded-md justify-center">
                    {validVisualizations.map((visName: any, idx: number) => (
                      <button
                        className="m-2"
                        onClick={() => {
                          if (visName !== visualizationType) {
                            setRequiredAttributes(null);
                            setVisualizationType(visName);
                          }
                          setSelectorAttributes(null);
                          setSelecting(null);
                          setAttributes(null);
                          setSidebarActiveTab(1);
                        }}
                      >
                        <NameCard key={idx} name={visName} />
                      </button>
                    ))}
                  </div>
                ) : (
                  <div className="w-full animate-pulse bg-neutral flex flex-wrap p-4 rounded-md justify-center ">
                    {[...Array(6)].map((value: undefined, index: number) => (
                      <div key={index} className="w-48 m-2 bg-gray-300 rounded col-span-2 h-32 "></div>
                    ))}
                  </div>
                )}
              </>,
              <AttributeTab
                attributes={requiredAttributes || {}}
                setAttributes={setAttributes}
                tabNames={visTabs}
                setTabNames={setVisTabs}
                activeTab={visActiveTab}
              />,
              "Todo",
            ]}
          />
        }
        mainPanel={
          selecting ? (
            <ObjectChooser />
          ) : (
            <VisualizationTabs
              activeTab={visActiveTab}
              tabHeaders={visTabs}
              // tabContents={[
              //   !attributes || !visualizationType ? (
              //     <NoVisSelected />
              //   ) : (
              //     <Plot visualizationType={visualizationType} attributes={attributes} />
              //   ),
              // ]}
              tabContents={tabVisualizations}
              addTab={() => {
                setVisTabs([...visTabs, "New Visualization"]);
                setTabVisualizations([...tabVisualizations, { plot: <NoVisSelected /> }]);
                setSidebarActiveTab(0); // hardcoded to 0 to go to plots tab
              }}
              removeTab={(idx: number) => {
                if (tabVisualizations.length === 1) {
                  console.log("last tab");
                  setVisTabs(["New Visualization"]);
                  setTabVisualizations([{ plot: <NoVisSelected /> }]);
                  setSidebarActiveTab(0);
                } else {
                  if (idx === visActiveTab) setVisActiveTab(0);
                  if (idx < visActiveTab) setVisActiveTab(visActiveTab - 1);
                  setVisTabs(visTabs.filter((_, i) => i !== idx));
                  setTabVisualizations(tabVisualizations.filter((_, i) => i !== idx));
                }
              }}
              selectTab={(idx: number) => {
                setVisActiveTab(idx);
              }}
            />
          )
        }
      />
    </VisualizeContext.Provider>
  );
}
