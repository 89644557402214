import FormSelect from "components/formik/Selector/Selector";
import Range from "components/formik/Range/Range";
import TriggerSelector from "components/formik/TriggerSelector/TriggerSelector";
import { ReactNode } from "react";

interface AttributeFieldProps {
  attribute: any;
}

function getAttribute(attribute: any) {
  let selector: ReactNode = <div>Not implemented {JSON.stringify(attribute)}</div>;

  switch (attribute[1]) {
    case "modelSelector":
    case "datasetSelector":
      selector = <TriggerSelector attribute={attribute} />;
      break;
    case "inputNumberRange":
      selector = <Range name={attribute[0]} min={attribute[2][0]} max={attribute[2][1]} />;
      break;
    case "selector":
      selector = (
        <FormSelect
          name={attribute[0]}
          options={attribute[2].map((option: string) => {
            return { value: option, label: option };
          })}
        />
      );
      break;
    default:
      selector = <div>Not implemented {JSON.stringify(attribute)}</div>;
  }

  return (
    <div className="flex flex-row items-center">
      <label className="flex-none capitalize mr-2">{attribute[0]}:</label>
      <div className="grow"> {selector} </div>
    </div>
  );
}

export default function AttributeField({ attribute }: AttributeFieldProps) {
  return <div>{getAttribute(attribute)}</div>;
}
