import { memo, useEffect, useState } from 'react';
import Drawer from 'components/atoms/Drawer/Drawer';
import Button from "components/atoms/Button/Button";
import { validateSMILES } from "util/Molecules";
import PreviewCanvas from '../PreviewCanvas/PreviewCanvas';

interface MoleculePreview {
    userInput: string,
    nextButtonText: string,
    next: Function,
}

export default function MoleculePreview({userInput, nextButtonText, next}: MoleculePreview) {
    const [interactionsDisabled, setInteractionsDisabled] = useState(false);
    const [smilesRender, setSmilesRender] = useState<any>(<div></div>);

    const PureIFrame = memo((props: any) => <iframe title="plot" {...props} />);

  
    const smilesString = (userInput: any) => {
      if (userInput.length >= 17) {
        let adjusted = userInput.substring(0, 17) + "...";
        return adjusted;
      }
      return userInput;
    };
  
    useEffect(() => {
      if (userInput !== "") {
        const fetchSmilesValidity = async () => {
          let valid = (await validateSMILES(userInput)) as any as boolean;
          if (valid) {
            setSmilesRender(<Drawer smilesStr={userInput} className="h-full w-full" />);
            setInteractionsDisabled(false);
          } else {
            setSmilesRender(<div>Invalid SMILES, please try again.</div>);
            setInteractionsDisabled(true);
          }
        };
        fetchSmilesValidity();
      }
    }, [userInput]);

    return (
        <>
        <div className="bg-neutral p-3 w-full h-full max-h-full">
          <div className="flex flex-col h-full items-center gap-4">
            <PreviewCanvas smilesRender={smilesRender} />
            <div className="max-h-1/7 h-1/7 w-fit">
              <h3 className="text-md font-display font-bold">
                Molecule SMILES:
                <span className="text-md text-normal rounded-xl bg-base-100 font-normal ml-1 p-2">
                  {smilesString(userInput)}
                </span>
              </h3>
            </div>
            <div className="max-h-1/8 h-1/8">
              <Button
                fullWidth={false}
                onClick={() => {
                  next();
                }}
                disabled={interactionsDisabled}
                noAnimation={true}
              >
                {nextButtonText}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
}