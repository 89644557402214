import { DatabaseObject, ModelDB, VisualizationDB } from "api/BaseDatabase";
import database from "api/FirestoreDatabase";
import Collapser from "components/atoms/Collapser/Collapser";
import StaticTextboxLabel from "components/atoms/StaticTextboxLabel/StaticTextboxLabel";
import Textbox from "components/atoms/Textbox/Textbox";
import { ManualToggle } from "components/atoms/Toggle/Toggle";
import SidebarLayout from "components/layouts/SidebarLayout/SidebarLayout";
import FileUpload from "components/molecules/FileUpload/FileUpload";
import DatasetPredictDash from "components/organisms/DatasetPredictDash/DatasetPredictDash";
import ObjectBrowser from "components/organisms/ModelBrowser/ObjectBrowser";
import SkeletonBrowser from "components/organisms/ModelBrowser/SkeletonBrowser";
import ModelDocumentEditor, {
  OpenModelEditorButton,
} from "components/organisms/ModelDocumentEditor/ModelDocumentEditor";
import MoleculePredictDash from "components/organisms/MoleculePredictDash/MoleculePredictDash";
import Navbar from "components/organisms/Navbar/Navbar";
import {
  VisualizeParametersButton,
  VisualizeParametersModal,
} from "components/organisms/VisualizeParameters/VisualizeParameters";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Creatable from "react-select/creatable";
import { Table } from "util/Tables";
import { send } from "xstate/lib/actions";
import { useEndpointDashboard } from "./useEndpointDashboard";

export default function EndpointDashboard() {
  let [searchParams] = useSearchParams();
  const mid = searchParams.get("mid") as any as string;

  const [model, setModel] = useState<DatabaseObject | null>(null);
  useEffect(() => {
    send({ type: "INITIAL" });
    if (userInput !== "") {
      setUserInput("");
    }
    if (fileInfo) {
      handleRemove();
    }
    return database.getDocumentLive(`/models/${mid}`, setModel);
  }, [mid]);

  const [models, setModels] = useState<ModelDB[]>([]);
  useEffect(() => {
    return database.getLive(database.queryUserModels(), setModels);
  }, []);

  const [visualizations, setVisualizations] = useState<VisualizationDB[]>([]);
  useEffect(() => {
    return database.getLive(database.queryUserVisualizations(), setVisualizations);
  }, []);

  const validVisualizations = visualizations.filter((vis) => vis?.content?.data_url !== null);
  const DatabaseObjects = useMemo(() => [...models, ...validVisualizations], [models, visualizations]);

  const [user, setUser] = useState<DatabaseObject | null>(null);
  useEffect(() => {
    return database.getDocumentLive(`/users/${database.user.uid}`, setUser);
  }, []);

  const {
    fileInfo,
    fileName,
    handleRemove,
    handlePaste,
    userInput,
    setUserInput,
    nextButtonText,
    next,
    invalidMolecules,
    uploadDataset,
    predictedValues,
    validateAfterDeletion,
    downloadPredictions,
    inferStatus,
    invalidMid,
    prediction,
    state,
    handleKeyPress,
  } = useEndpointDashboard(mid);

  const ToggleDashboard = () => {
    if (
      state.matches("MoleculePreview-Button") ||
      state.matches("LoadingSpinner-Single") ||
      state.matches("PredictionDisplay")
    ) {
      return (
        <MoleculePredictDash
          userInput={userInput}
          prediction={prediction}
          nextButtonText={nextButtonText}
          next={next}
          state={state}
        />
      );
    } else if (
      state.matches("LoadingSpinner-Dataset") ||
      state.matches("InvalidMoleculesCorrector") ||
      state.matches("MoleculeDataTable")
    ) {
      return (
        <DatasetPredictDash
          invalidMolecules={invalidMolecules}
          handleCorrection={validateAfterDeletion}
          predictedValues={predictedValues ? predictedValues : (null as any as Table)}
          inferStatus={inferStatus}
          downloadPredictions={downloadPredictions}
          state={state}
        />
      );
    } else if (state.matches("Initial")) {
      return (
        <div className="flex p-1 bg-neutral w-full h-full grid place-items-center text-sm lg:text-base ">
          Either upload a dataset or enter a SMILES string to begin predicting.
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <>
      {user && model ? (
        <>
          <ModelDocumentEditor user={user} model={model} />
          <VisualizeParametersModal model={model} />
        </>
      ) : null}

      {!invalidMid ? (
        <SidebarLayout
          sidebarPanel={
            <>
              <Collapser headerName="Model Information">
                <div className="flex flex-col space-y-2">
                  {model?.content?.uid === database.user.uid ? (
                    <Textbox
                      label="Model Name"
                      text={model?.content?.data?.name}
                      setText={(x: string) => {
                        if (model) database.updateDocument(model, { "data.name": x });
                      }}
                      ref={null}
                      altLabel={""}
                    />
                  ) : (
                    <StaticTextboxLabel name="Model Name" content={model?.content?.data?.name} />
                  )}
                  <OpenModelEditorButton />
                  <VisualizeParametersButton />
                  {model?.content?.data?.metrics ? (
                    <StaticTextboxLabel name="Metrics" content={JSON.stringify(model?.content?.data?.metrics)} />
                  ) : null}
                </div>
              </Collapser>
              {model?.content?.uid === database.user.uid ? (
                <Collapser headerName="Model Sharing">
                  <Creatable
                    formatCreateLabel={(x: string) => `Invite ${x}`}
                    placeholder={"Enter emails to share model with..."}
                    value={(model?.content?.sharedUsers || [])
                      .filter((x: string) => x !== database.user.email)
                      .map((x: string) => {
                        return { value: x, label: x };
                      })}
                    isMulti={true}
                    allowCreateWhileLoading={false}
                    onChange={(x: any) => {
                      if (model)
                        database.updateDocument(model, {
                          sharedUsers: x.map((a: any) => a.value).filter((x: string) => x !== database.user.email),
                        });
                    }}
                    isDisabled={model?.content?.isPublic || false}
                  />
                  <ManualToggle
                    labelText={"Publicly Accessible"}
                    isChecked={model?.content?.isPublic || false}
                    handleChange={() => {
                      if (model) database.updateDocument(model, { isPublic: !(model?.content?.isPublic || false) });
                    }}
                  />
                </Collapser>
              ) : null}
              {model ? (
                <div className={"grow "}>
                  <ObjectBrowser DatabaseObjects={DatabaseObjects} model={model} />
                </div>
              ) : (
                <div className={"grow "}>
                  <SkeletonBrowser numFakeFiles={10} />
                </div>
              )}
            </>
          }
          mainPanel={
            <div className="flex flex-col w-full h-full">
              <div className="flex flex-row w-full h-1/7 gap-2">
                <Collapser className="w-1/2" headerName="Predict on CSV or SDF file with SMILES" checked={true}>
                  <FileUpload
                    allowedFileTypes={["csv", "sdf"]}
                    fileName={fileInfo ? fileName : undefined}
                    handleUpload={uploadDataset}
                    handleRemove={handleRemove}
                  />
                </Collapser>
                <Collapser className="w-1/2" headerName="Predict on Single SMILES Input" checked={true}>
                  <Textbox
                    text={userInput}
                    setText={(input: string) => {
                      setUserInput(input);
                    }}
                    noText={"Enter your own SMILES string here"}
                    handlePaste={handlePaste}
                    handleKeyPress={handleKeyPress}
                    ref={null}
                    label={null}
                    altLabel={null}
                  />
                </Collapser>
              </div>
              <>
                <ToggleDashboard />
              </>
            </div>
          }
        />
      ) : (
        <>
          <Navbar />
          <div className="text-center grid place-items-center h-3/4">
            <p>The Model ID provided is either invalid or is inaccessible to you.</p>
          </div>
        </>
      )}
    </>
  );
}
